import React, { useState, useEffect, useMemo, useRef } from "react";
import { Col, Row, Card, Form, Button } from "@themesberg/react-bootstrap";
import "react-time-picker/dist/TimePicker.css";
import { Formik, FieldArray, getActiveElement } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faEdit,
  faTrash,
  faPlus,
  faTimes,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import { MDBDataTable } from "mdbreact";
import "rsuite/dist/rsuite.css";
import { MultiCascader } from "rsuite";
import Select from "react-select";
import { MaterialReactTable } from "material-react-table";
import JoditEditor from "jodit-react";
import { useHistory } from "react-router-dom";
import ReactLogo from "../assets/img/technologies/loading-loader-svgrepo-com.svg";
import { Image } from '@themesberg/react-bootstrap';
export const Subscription = () => {
  const [Editopen, setEditOpen] = useState(false);
  const onEditOpenModal = () => {
    setEditOpen(true);
  }
  const onEditCloseModal = () => {
    setEditOpen(false);
  };
  const [isError, setIsError] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isSavingData, setIsSavingData] = useState(false);
  const [editData, setEditData] = useState({});
  const [isnewError, setIsnewError] = useState("");
  const [categoryData, setMyCategoryData] = useState([]);
  const [examData, setMyExamData] = useState([]);
  const [videosData, setMyVideoData] = useState([]);
  const [notesData, setMyNotesData] = useState([]);
  const [subcategoryData, setMysubCategoryData] = useState([]);
  const [topicsData, setTopicsData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedExamOptions, setSelectedExamOptions] = useState([]);
  const [selectedVideosOptions, setSelectedVideosOptions] = useState([]);
  const [selectedNotesOptions, setSelectedNotesOptions] = useState([]);
  // const [finalselectedOptions, setFinalSelectedOptions] = useState([]);
  const [finalselectedExamOptions, setFinalSelectedExamOptions] = useState([]);
  const [finalselectedVideosOptions, setFinalSelectedVideosOptions] = useState(
    []
  );
  const [finalselectedNotesOptions, setFinalSelectedNotesOptions] = useState(
    []
  );
  const [contentchange, setcontentchange] = useState(false);
  const editor = useRef(null);
  const columns = useMemo(
    () => [
      {
        accessorKey: "plan_name",
        header: "PLAN NAME",
      },

      {
        accessorKey: "actions",
        header: "Actions",
      },
    ],
    []
    //end
  );
  const [preparingFor, setPreparingFor] = useState([]);


  const [myData, setMyData] = useState({
    plan_name: "",
    description: "",
    benifit: [],
    duration: [
      {
        price: "",
        day: "",
        discountedPrice: "",
      },
    ],
    tempUIDataTopic: selectedOptions,
    tempUIDataExam: selectedExamOptions,
    tempUIDataVideos: selectedVideosOptions,
    tempUIDataNotes: selectedNotesOptions,
    select_preparing: [],
  });
  const generateVideoCascadingOptions = () => {
    const cascadingOptions = categoryData.map((category) => ({
      label: category.category_name,
      value: category._id,
      children: subcategoryData
        .filter((subcategory) => subcategory.category_id === category._id)
        .map((subcategory) => ({
          label: subcategory.subcategory_name,
          value: subcategory._id,
          children: videosData
            .filter((video) => video.subcategory_id === subcategory._id)
            .map((video) => ({
              label: video.topic_name,
              value: video._id,
            })),
        })),
    }));

    return cascadingOptions;
  };
  const generateNotesCascadingOptions = () => {
    const cascadingOptions = categoryData.map((category) => ({
      label: category.category_name,
      value: category._id,
      children: subcategoryData
        .filter((subcategory) => subcategory.category_id === category._id)
        .map((subcategory) => ({
          label: subcategory.subcategory_name,
          value: subcategory._id,
          children: notesData
            .filter((note) => note.subcategory_id === subcategory._id)
            .map((note) => ({
              label: note.topic_name,
              value: note._id,
            })),
        })),
    }));

    return cascadingOptions;
  };
  const generateExamCascadingOptions = () => {
    const cascadingExamOptions = categoryData.map((category) => ({
      label: category.category_name,
      value: category._id,
      children: subcategoryData
        .filter((subcategory) => subcategory.category_id === category._id)
        .map((subcategory) => ({
          label: subcategory.subcategory_name,
          value: subcategory._id,
          children: examData
            .filter((topic) => topic.subcategory_id === subcategory._id)
            .map((topic) => ({
              label: topic.exam_name,
              value: topic._id,
            })),
        })),
    }));

    return cascadingExamOptions;
  };


  const getMyCategoryData = async () => {

    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Category/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      setMyCategoryData(res.data);
      console.log("categoryData", categoryData);
    } catch (error) {
      setIsError(error.response);
    }
  };

  const getMyExamData = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Exam/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      setMyExamData(res.data);
    } catch (error) {
      setIsError(error.response);
    }
  };

  const getMyVideoData = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Content/AllVideoWithoutImg`,
        {
          headers: { Authorization: token },
        }
      );
      setMyVideoData(res.data);
    } catch (error) {
      setIsError(error.response);
    }
  };

  const getMyNotesData = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Content/getAllByPdf`,
        {
          headers: { Authorization: token },
        }
      );
      setMyNotesData(res.data);
    } catch (error) {
      setIsError(error.response);
    }
  };

  const getMySubCategoryData = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Subcategory/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      setMysubCategoryData(res.data);
      console.log("subcategoryData :", subcategoryData);
    } catch (error) {
      setIsError(error.response);
    }
  };

  const getMyTopicsData = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Topic/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      setTopicsData(res.data);
    } catch (error) {
      setIsError(error.response);
    }
  };

  const getMyPostData = async (values) => {
    const token = localStorage.getItem("token");
    setIsSavingData(true);
    values = {
      ...values,
      duration: values.duration.map((i) => ({
        ...i,
        offer:
          i.discountedPrice || parseInt(i.discountedPrice) === 0
            ? (((i.price - i.discountedPrice) / i.price) * 100).toString() + "%"
            : "",
      })),
    };
    const createSubObject = {
      ...values,
      exam_id: finalselectedExamOptions,
      // tempUIDataTopic: selectedOptions,
      tempUIDataExam: selectedExamOptions,
      tempUIDataNotes: selectedNotesOptions,
      tempUIDataVideos: selectedVideosOptions,
      content_id: [...finalselectedVideosOptions, ...finalselectedNotesOptions],
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/Subscription/create`,
        createSubObject,
        {
          headers: { Authorization: `${token}` },
        }
      );
      setMyData(res.data);
      setIsSavingData(false);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your Subscription has been Added Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getMySubscriptionData();
      setMyData({
        plan_name: "",
        description: "",
        benifit: [],
        duration: [
          {
            price: "",
            day: "",
            discountedPrice: "",
          },
        ],
        tempUIDataTopic: selectedOptions,
        tempUIDataExam: selectedExamOptions,
        tempUIDataNotes: selectedNotesOptions,
        tempUIDataVideos: selectedVideosOptions,
        select_preparing: [],
      });
      setIsExpanded(false);
    } catch (error) {
      setIsError(error.response.data.err.message);
      setIsnewError(error.response.data.err.name);
    }
  };

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "No",
        field: "id",
        width: 270,
      },
      {
        label: "Plan Name",
        field: "name",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "Action",
        field: "actions",
        width: 100,
      },
    ],
    rows: [],
  });
  const [jsonData, setJsonData] = useState([]);
  const getMySubscriptionData = async () => {
    const token = localStorage.getItem("token");
    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Subscription/getAll`,
        {
          headers: { Authorization: token },
        }
      );

      // const data = res.data;
      var shorted_data = res?.data.sort(function (a, b) {
        return a.order - b.order;
      });
      const data = shorted_data?.map((item, index) => {
        return {
          id: index + 1,
          plan_name: item.plan_name,
          _id: item.sid,
          actions: (
            <div>
              <FontAwesomeIcon icon={faEye} onClick={() => handleEdit(item)} />

              <FontAwesomeIcon
                className="mx-3"
                icon={faTrash}
                onClick={() => handleDelete(item.sid)}
              />
              {/* <button onClick={() => navigateDetails(item._id)}><FontAwesomeIcon
                icon={faEye}
              /> View Topics</button> */}
            </div>
          ),
        };
      });
      // ?.map((item, index) => {
      //   item.duration = item.duration.map((i) => ({
      //     ...i,
      //     discountedPrice: i.price - i.price * (parseFloat(i.offer) / 100),
      //   }));
      //   return {
      //     id: index + 1,
      //     name: item.plan_name,

      //     actions: (
      //       <div>
      //         <FontAwesomeIcon
      //           icon={faEdit}
      //           className="mx-3"
      //           onClick={() => handleEdit(item)}
      //         />

      //         <FontAwesomeIcon
      //           icon={faTrash}
      //           onClick={() => handleDelete(item.sid)}
      //         />
      //       </div>
      //     ),
      //   };
      // });
      setJsonData(data);
      setDatatable((prevState) => ({
        ...prevState,
        rows: data,
      }));
      setIsLoadingData(false);
    } catch (error) {
      setIsError(error.response);
      setIsLoadingData(false);
    }
  };
  const handleExamSelect = (selectedValues) => {
    var final_select = [];
    selectedValues?.forEach((element) => {
      categoryData.filter((val) => {
        if (val._id === element) {
          subcategoryData
            .filter((subcategory) => subcategory.category_id === val._id)
            .map((subcategory) => {
              let tops = examData.filter(
                (topic) => topic.subcategory_id === subcategory._id
              );
              if (tops[0]) {
                tops?.forEach((element) => {
                  final_select.push(element["_id"]);
                });
              }
            });
        }
      });

      subcategoryData
        .filter((subcategory) => subcategory._id === element)
        .map((subcategory) => {
          let tops = examData.filter(
            (topic) => topic.subcategory_id === subcategory._id
          );
          if (tops[0]) {
            tops?.forEach((element) => {
              final_select.push(element["_id"]);
            });
          }
        });

      examData.filter((topic) => {
        if (topic._id === element) {
          final_select.push(topic._id);
        }
      });
    });
    setSelectedExamOptions(selectedValues);
    setFinalSelectedExamOptions(final_select);
    // console.log("Final  Exam Content Array :", final_select)
  };

  const handleNotesSelect = (selectedValues) => {

    var final_select = [];
    selectedValues?.forEach((element) => {
      categoryData.filter((val) => {
        if (val._id === element) {
          subcategoryData
            .filter((subcategory) => subcategory.category_id === val._id)
            .map((subcategory) => {
              let tops = notesData.filter(
                (topic) => topic.subcategory_id === subcategory._id
              );
              if (tops[0]) {
                tops?.forEach((element) => {
                  final_select.push(element["_id"]);
                });
              }
            });
        }
      });

      subcategoryData
        .filter((subcategory) => subcategory._id === element)
        .map((subcategory) => {
          let tops = notesData.filter(
            (topic) => topic.subcategory_id === subcategory._id
          );
          if (tops[0]) {
            tops?.forEach((element) => {
              final_select.push(element["_id"]);
            });
          }
        });

      notesData.filter((topic) => {
        if (topic._id === element) {
          final_select.push(topic._id);
        }
      });
    });
    setSelectedNotesOptions(selectedValues);
    setFinalSelectedNotesOptions(final_select);
    // console.log("Final  Notes Content Id Array :", final_select)
  };

  const handleVideoSelect = (selectedValues) => {
    setcontentchange(true);
    var final_select = [];
    selectedValues?.forEach((element) => {
      categoryData.filter((val) => {
        if (val._id === element) {
          subcategoryData
            .filter((subcategory) => subcategory.category_id === val._id)
            .map((subcategory) => {
              let tops = videosData.filter(
                (topic) => topic.subcategory_id === subcategory._id
              );
              if (tops[0]) {
                tops?.forEach((element) => {
                  final_select.push(element["_id"]);
                });
              }
            });
        }
      });

      subcategoryData
        .filter((subcategory) => subcategory._id === element)
        .map((subcategory) => {
          let tops = videosData.filter(
            (topic) => topic.subcategory_id === subcategory._id
          );
          if (tops[0]) {
            tops?.forEach((element) => {
              final_select.push(element["_id"]);
            });
          }
        });

      videosData.map((topic) => {
        if (topic._id === element) {
          final_select.push(topic._id);
        }
      });
    });
    setSelectedVideosOptions(selectedValues);
    setFinalSelectedVideosOptions(final_select);
    // console.log("Final Video Content Id Array :", final_select)
  };

  const handleEdit = (data) => {

    history.push(`/subscriptiondata?id=${data.sid}`);


    // history.push('/subscriptiondata?id=${userId});

    // data = {
    //   ...data,
    //   duration: data.duration.map((i) => ({
    //     ...i,
    //     discountedPrice:
    //       i.offer && typeof i.offer === "string"
    //         ? (
    //           (i.price *
    //             parseFloat(100 - parseFloat(i.offer.replace("%", "")))) /
    //           100
    //         ).toFixed(2)
    //         : null,
    //   })),
    // };

    // setEditData(data); // Set the data of the selected row
    // console.log("Edited Data :", data)
    // setSelectedExamOptions(data.tempUIDataExam);
    // setSelectedNotesOptions(data.tempUIDataNotes);
    // setSelectedVideosOptions(data.tempUIDataVideos);
    // // handleExamSelect(data.tempUIDataExam);
    // // handleNotesSelect(data.tempUIDataNotes);
    // // handleVideoSelect(data.tempUIDataVideos);
    // // setFinalSelectedExamOptions(data.tempUIDataExam);
    // // setFinalSelectedVideosOptions(data.tempUIDataVideos);
    // // setFinalSelectedNotesOptions(data.tempUIDataNotes)

    // if (Editopen) {
    //   setEditOpen(false);
    // } else {
    //   setEditOpen(true);
    //   setIsExpanded(false);
    //   handleExamSelect(data.tempUIDataExam);
    //   handleNotesSelect(data.tempUIDataNotes);
    //   handleVideoSelect(data.tempUIDataVideos);
    // }
  };
  const history = useHistory();
  // const navigateDetails = (item) => {
  //   history.push(`/subscriptiondata?id=${item.sid}`);
  // };

  useEffect(() => {
    getMySubscriptionData();
    getMyCategoryData();
    getMySubCategoryData();
    getMyTopicsData();
    getMyExamData();
    getPreparingFor();
    getMyVideoData();
    getMyNotesData();
  }, []);

  const getPreparingFor = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/preparing/getpreparing`,
        {
          headers: { Authorization: token },
        }
      );
      setPreparingFor(
        res.data.map((i) => ({
          value: i.preparing_for,
          label: i.preparing_for,
        }))
      );
    } catch (error) {
      setIsError(error.response);
    }
  };

  const validationSchema = Yup.object().shape({
    // plan_id: Yup.string().required("Plan ID is required"),
    plan_name: Yup.string().required("Plan name is required"),
    description: Yup.string().required("description is required"),

    // inclusions: Yup.string().required("Inclusions are required"),
    benifit: Yup.array().of(Yup.string().required("Benefit is required")),
    duration: Yup.array().of(
      Yup.object().shape({
        price: Yup.string().required("Price is required"),
        day: Yup.string().required("day is required"),
        // offer: Yup.string().required("Offer is required"),
      })
    ),
    select_preparing: Yup.array().test(
      "is-not-blank",
      "Group is required",
      (value) => {
        return value && value.length > 0;
      }
    ),
  });

  const EditSchema = Yup.object().shape({
    // plan_id: Yup.string().required("Plan ID is required"),
    plan_name: Yup.string().required("Plan name is required"),
    description: Yup.string().required("description is required"),
    benifit: Yup.array().of(Yup.string().required("Benefit is required")),
    duration: Yup.array().of(
      Yup.object().shape({
        price: Yup.string().required("Price is required"),
        day: Yup.string().required("day is required"),
        // offer: Yup.string().required("Offer is required"),
      })
    ),
    select_preparing: Yup.array().test(
      "is-not-blank",
      "Group is required",
      (value) => {
        return value && value.length > 0;
      }
    ),
  });
  const updateSubscription = async (id, updatedData) => {
    try {
      const token = localStorage.getItem("token");
      updatedData = {
        ...updatedData,
        duration: updatedData.duration.map((i) => ({
          ...i,
          offer:
            i.discountedPrice || parseInt(i.discountedPrice) === 0
              ? (((i.price - i.discountedPrice) / i.price) * 100).toString() +
              "%"
              : "",
        })),
        exam_id: finalselectedExamOptions,
        content_id: [
          ...finalselectedVideosOptions,
          ...finalselectedNotesOptions,
        ],
        tempUIDataExam: selectedExamOptions,
        tempUIDataNotes: selectedNotesOptions,
        tempUIDataVideos: selectedVideosOptions,
      };

      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/updateSubscription/${id}`,
        updatedData,
        {
          headers: { Authorization: token },
        }
      );

      // Optionally, you can refetch the subcategory data after successful update.
      getMySubscriptionData();
      onEditCloseModal(); // Close the modal after successful update.
      Swal.fire({
        position: "center",
        icon: "success",
        title: " Subscription Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getMySubscriptionData();
      window.location.reload();
    } catch (error) {
      console.error(error.response); // Handle error responses
    }
  };

  const handleDelete = (id) => {
    // Show the confirmation dialog using SweetAlert
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with the delete operation
        try {
          const token = localStorage.getItem("token");
          const res = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/deleteSubscription/${id}`,
            {
              headers: { Authorization: token },
            }
          );
          // Optionally, you can refresh the subcategory data after successful deletion.
          // getMySubscriptionData
          // Show a success message using SweetAlert
          Swal.fire(
            "Deleted!",
            "Your Subscription has been deleted.",
            "success"
          );
          getMySubscriptionData();
        } catch (error) {
          console.error(error.response); // Handle error responses
          // Show an error message using SweetAlert
          Swal.fire("Error", "Failed to delete the Subscription.", "error");
        }
      }
    });
  };

  const styles = {
    width: 224,
    display: "block",
    marginBottom: 10,
    zIndex: 100,
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isExpanded) {
      setEditOpen(false);
      setSelectedVideosOptions([]);
      setSelectedExamOptions([]);
      setSelectedNotesOptions([]);
      setMyData({
        plan_name: "",
        description: "",
        benifit: [],
        duration: [
          {
            price: "",
            day: "",
            discountedPrice: "",
          },
        ],
        tempUIDataTopic: selectedOptions,
        tempUIDataExam: selectedExamOptions,
        tempUIDataVideos: selectedVideosOptions,
        tempUIDataNotes: selectedNotesOptions,
        select_preparing: [],
      });
    }
  }, [isExpanded])

  return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <h5 className="mb-4">Subscription Plans</h5>
            <button
              className={isExpanded ? "btn btn-danger" : "btn btn-primary"}
              onClick={handleToggle}
            >
              {isExpanded ? "Close Subscription" : "Add Subscription"}
              <FontAwesomeIcon
                icon={isExpanded ? faTimes : faPlus}
                className="mx-2"
              />
            </button>
          </div>
          <div className={`expanding-content ${isExpanded ? "expanded" : ""}`}>
            <h2>Add Subscription</h2>
            <Formik
              initialValues={myData}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                getMyPostData(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
              }) => (
                <Form>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group id="firstName">
                        <Form.Label>Plan name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your Plan name"
                          name="plan_name"
                          value={values.plan_name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <div className="mb-3 text-danger">
                        {errors.plan_name &&
                          touched.plan_name &&
                          errors.plan_name}
                      </div>
                    </Col>
                    <Col md={6} className="mb-3 ">
                      <Form.Group id="firstName">
                        <Form.Label>Description</Form.Label>
                        {/* <Form.Control
                          type="text"
                          placeholder="Enter description"
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                        /> */}
                        {/* <textarea
                          class="form-control"
                          id="description"
                          rows="6"
                          placeholder="Enter Description"
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                        ></textarea> */}
                        <JoditEditor
                          ref={editor}
                          value={values.description}
                          onBlur={newContent => setFieldValue('description', newContent)}
                          onChange={newContent => setFieldValue('description', newContent)} // Update Formik state on change
                          tabIndex={1} // tabIndex of textarea    
                        // onChange={handleChange}                                 


                        />
                      </Form.Group>
                      <div className="mb-3 text-danger">
                        {errors.description &&
                          touched.description &&
                          errors.description}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <FieldArray name="benifit">
                      {({ push, remove }) => (
                        <>
                          {values.benifit.map((item, index) => (
                            <Col md={6} className="mb-3">
                              <Form.Group>
                                <Form.Label>Benefit {index + 1}</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Benefit"
                                  name={`benifit.${index}`}
                                  value={item}
                                  onChange={handleChange}
                                />
                                {errors.benifit &&
                                  touched.benifit &&
                                  touched.benifit[index] && (
                                    <div className="text-danger">
                                      {errors.benifit[index]}
                                    </div>
                                  )}
                              </Form.Group>
                            </Col>
                          ))}

                          {/* Show one "Benefit" field by default */}
                          {values.benifit.length === 0 && (
                            <Col md={6} className="mb-3">
                              <Form.Group>
                                <Form.Label>Benefit 1</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Benefit"
                                  name={`benifit.0`}
                                  value={values.benifit[0]}
                                  onChange={handleChange}
                                />
                                {errors.benifit &&
                                  touched.benifit &&
                                  touched.benifit[0] && (
                                    <div className="text-danger">
                                      {errors.benifit[0]}
                                    </div>
                                  )}
                              </Form.Group>
                            </Col>
                          )}

                          <Row>
                            <Col md={12} className="mb-3">
                              <Button
                                variant="primary"
                                onClick={() => push("")}
                              >
                                Add Benefit
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    </FieldArray>
                  </Row>
                  <FieldArray name="duration">
                    {({ push, remove }) => (
                      <>
                        {values.duration.map((item, index) => {
                          return (
                            <div key={index}>
                              <Row>
                                <Col md={4} className="mb-3">
                                  <Form.Group>
                                    <Form.Label>Price {index + 1}</Form.Label>
                                    <Form.Control
                                      type="number"
                                      placeholder="Enter Price"
                                      name={`duration[${index}].price`}
                                      onChange={handleChange}
                                    />
                                    {errors.duration &&
                                      errors.duration[index] &&
                                      errors.duration[index].price && (
                                        <div className="text-danger">
                                          {errors.duration[index].price}
                                        </div>
                                      )}
                                  </Form.Group>
                                </Col>
                                <Col md={4} className="mb-3">
                                  <Form.Group>
                                    <Form.Label>
                                      Discounted Price {index + 1}
                                    </Form.Label>
                                    <Form.Control
                                      type="number"
                                      placeholder="Enter Discounted Price"
                                      name={`duration[${index}].discountedPrice`}
                                      onChange={handleChange}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={4} className="mb-3">
                                  <Form.Group>
                                    <Form.Label>Day {index + 1}</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Number of days"
                                      name={`duration[${index}].day`}
                                      onChange={handleChange}
                                    />
                                    {errors.duration &&
                                      errors.duration[index] &&
                                      errors.duration[index].day && (
                                        <div className="text-danger">
                                          {errors.duration[index].day}
                                        </div>
                                      )}
                                  </Form.Group>
                                </Col>
                                {/* <Col md={4} className="mb-3">
                                <Form.Group>
                                  <Form.Label>Offer {index + 1}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Ex : 10% - Must include %"
                                    name={`duration[${index}].offer`}
                                    onChange={handleChange}
                                  />
                                  
                                </Form.Group>
                              </Col> */}
                              </Row>
                            </div>
                          );
                        })}

                        <Row>
                          <Col md={12} className="mb-3">
                            <Button
                              variant="primary"
                              onClick={() =>
                                push({
                                  price: "",
                                  day: "",
                                  discountedPrice: "",
                                })
                              }
                            >
                              Add Duration
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </FieldArray>
                  {/* <div id="checkbox-group">Have access to?</div>
                  <div role="group" aria-labelledby="checkbox-group">
                    <label>
                      <Field type="checkbox" name="is_videos" className="m-2" />
                      Videos
                    </label>
                    <label>
                      <Field type="checkbox" name="is_notes" className="m-2" />
                      Notes
                    </label>
                    <label>
                      <Field type="checkbox" name="is_exams" className="m-2" />
                      Exams
                    </label>
                  </div> */}
                  <Row>
                    <Col md={6} className="mb-3" style={{ zIndex: 100 }}>
                      <Form.Group id="select_preparing">
                        <Form.Label>Select Group</Form.Label>
                        <Select
                          name="select_preparing"
                          value={values.select_preparing.map((id) => ({
                            value: id,
                            label: id,
                          }))}
                          options={preparingFor}
                          isMulti
                          onChange={(selectedOptions) => {
                            setFieldValue(
                              "select_preparing",
                              selectedOptions.map((option) => option.value)
                            );
                          }}
                        />
                        {errors.select_preparing && (
                          <div className="text-danger">
                            {errors.select_preparing}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} className="mb-3 ">
                      <Form.Group id="firstName">
                        <Form.Label> Videos </Form.Label>
                        <MultiCascader
                          toggleAs={Button}
                          size="lg"
                          placeholder="Content Videos"
                          data={generateVideoCascadingOptions()}
                          value={selectedVideosOptions}
                          onChange={handleVideoSelect}
                          style={styles}
                        // parentSelectable={false}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4} className="mb-3 ">
                      <Form.Group id="firstName">
                        <Form.Label>Notes</Form.Label>
                        <MultiCascader
                          toggleAs={Button}
                          size="lg"
                          placeholder="Content Notes"
                          data={generateNotesCascadingOptions()}
                          value={selectedNotesOptions}
                          onChange={handleNotesSelect}
                          style={styles}
                        // parentSelectable={false}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4} className="mb-3 ">
                      <Form.Group id="firstName">
                        <Form.Label>Exams</Form.Label>
                        <MultiCascader
                          toggleAs={Button}
                          size="lg"
                          placeholder="Exam Topics"
                          data={generateExamCascadingOptions()}
                          value={selectedExamOptions}
                          onChange={handleExamSelect}
                          style={styles}
                        // parentSelectable={false}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="py-3">
                  <Col>
                      <Form.Check
                        type="checkbox"
                       id="viewall"
                        label="View All"
                        checked={values.viewall}
                        onChange={(e) => {
                          setFieldValue("liveClass", e.target.checked); 
                          setFieldValue("mockExam", e.target.checked);
                          setFieldValue("exam", e.target.checked);
                          setFieldValue("videos", e.target.checked);
                          setFieldValue("notes", e.target.checked);
                          setFieldValue("Neet_SS", e.target.checked);
                          setFieldValue("INISS_ET", e.target.checked);
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        id="liveClass"
                        label="Live Classes"
                        checked={values.liveClass}
                        onChange={(e) => {
                          setFieldValue("liveClass", e.target.checked);
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        id="mockExam"
                        label="Mock Exams"
                        checked={values.mockExam}
                        onChange={(e) => {
                          setFieldValue("mockExam", e.target.checked);
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        id="exam"
                        label="Only MCQ's"
                        checked={values.exam}
                        onChange={(e) => {
                          setFieldValue("exam", e.target.checked);
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        id="videos"
                        label="Only Videos"
                        checked={values.videos}
                        onChange={(e) => {
                          setFieldValue("videos", e.target.checked);
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        id="notes"
                        label="Only Notes"
                        checked={values.notes}
                        onChange={(e) => {
                          setFieldValue("notes", e.target.checked);
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        id="Neet_SS"
                        label="Neet_SS"
                        checked={values.Neet_SS}
                        onChange={(e) => {
                          setFieldValue("Neet_SS", e.target.checked);
                        }}
                      />
                    </Col>

                    <Col>
                      <Form.Check
                        type="checkbox"
                        id="INISS_ET"
                        label=" INISS_ET"
                        checked={values.INISS_ET}
                        onChange={(e) => {
                          setFieldValue("INISS_ET", e.target.checked);
                        }}
                      />
                    </Col>


                    
                  </Row>
                  <div className="mt-3">
                    {isSavingData ? (

                      <Button
                        variant="primary"
                        type="button"
                      // onClick={handleSubmit}
                      >
                        Save
                      </Button>) : (
                      <>
                        <Button
                          variant="primary"
                          type="button"
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>
                      </>)

                    }
                    {/* <Button
                      variant="primary"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button> */}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {Editopen ? (

            <>

              <button
                type="button"
                className="btn btn-danger"
                onClick={onEditCloseModal}
              >
                Cancel
              </button>
              <Formik
                initialValues={editData}
                validationSchema={EditSchema}
                onSubmit={(values) => {
                  updateSubscription(editData.sid, values); // Pass the ID and updated data to updateSubscription
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <Form>
                    <h3>Edit {values.plan_name}</h3>
                    <Row>
                      {/* <Col md={12} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>plan Id</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your first name"
                        name="plan_id"
                        value={values.plan_id}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.plan_id && touched.plan_id && errors.plan_id}
                    </div>
                    <div className="text-danger">
                      {isnewError === "MongoError"
                        ? "Plan Id is already in use please enter unique id "
                        : ""}
                    </div>
                  </Col> */}

                      <Col md={12} className="mb-3">
                        <Form.Group id="firstName">
                          <Form.Label>Plan name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your first name"
                            name="plan_name"
                            value={values.plan_name}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <div className="mb-3 text-danger">
                          {errors.plan_name &&
                            touched.plan_name &&
                            errors.plan_name}
                        </div>
                      </Col>

                      <Col md={12} className="mb-3 ">
                        <Form.Group id="firstName">
                          <Form.Label>Description</Form.Label>
                          {/* <Form.Control
                          type="text"
                          placeholder="Enter description"
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                        /> */}
                          {/* <textarea
                            class="form-control"
                            id="description"
                            rows="6"
                            placeholder="Enter Description"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                          ></textarea> */}
                          <JoditEditor
                            ref={editor}
                            value={values.description}
                            onBlur={newContent => setFieldValue('description', newContent)}
                            onChange={newContent => setFieldValue('description', newContent)} // Update Formik state on change
                            // onChange={handleChange}                                 
                            tabIndex={1} // tabIndex of textarea    

                          />
                        </Form.Group>
                        <div className="mb-3 text-danger">
                          {errors.description &&
                            touched.description &&
                            errors.description}
                        </div>
                      </Col>
                      <FieldArray name="benifit">
                        {({ push, remove }) => (
                          <>
                            {values.benifit.map((item, index) => (
                              <div key={index}>
                                <Row>
                                  <Col md={12} className="mb-3">
                                    <Form.Group>
                                      <Form.Label>
                                        Benefit {index + 1}
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter Benefit"
                                        name={`benifit.${index}`}
                                        value={item}
                                        onChange={handleChange}
                                      />
                                      {errors.benifit &&
                                        touched.benifit &&
                                        touched.benifit[index] && (
                                          <div className="text-danger">
                                            {errors.benifit[index]}
                                          </div>
                                        )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            ))}

                            {/* Show one "Benefit" field by default */}
                            {values.benifit.length === 0 && (
                              <div>
                                <Row>
                                  <Col md={12} className="mb-3">
                                    <Form.Group>
                                      <Form.Label>Benefit 1</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter Benefit"
                                        name={`benifit.0`}
                                        value={values.benifit[0]}
                                        onChange={handleChange}
                                      />
                                      {errors.benifit &&
                                        touched.benifit &&
                                        touched.benifit[0] && (
                                          <div className="text-danger">
                                            {errors.benifit[0]}
                                          </div>
                                        )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            )}

                            <Row>
                              <Col md={12} className="mb-3">
                                <Button
                                  variant="primary"
                                  onClick={() => push("")}
                                >
                                  Add Benefit
                                </Button>
                              </Col>
                            </Row>
                          </>
                        )}
                      </FieldArray>
                    </Row>

                    <FieldArray name="duration">
                      {({ push, remove }) => (
                        <>
                          {values.duration.map((item, index) => (
                            <div key={index}>
                              <Row>
                                <Col md={4} className="mb-3">
                                  <Form.Group>
                                    <Form.Label>Price {index + 1}</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Price"
                                      name={`duration[${index}].price`}
                                      value={item.price}
                                      onChange={handleChange}
                                    />
                                    {errors.duration &&
                                      errors.duration[index] &&
                                      errors.duration[index].price && (
                                        <div className="text-danger">
                                          {errors.duration[index].price}
                                        </div>
                                      )}
                                  </Form.Group>
                                </Col>
                                <Col md={4} className="mb-3">
                                  <Form.Group>
                                    <Form.Label>
                                      Discounted Price {index + 1}
                                    </Form.Label>
                                    <Form.Control
                                      type="number"
                                      placeholder="Enter Discounted Price"
                                      name={`duration[${index}].discountedPrice`}
                                      value={item.discountedPrice}
                                      onChange={handleChange}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={4} className="mb-3">
                                  <Form.Group>
                                    <Form.Label>day {index + 1}</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter day"
                                      name={`duration[${index}].day`}
                                      value={item.day}
                                      onChange={handleChange}
                                    />
                                    {errors.duration &&
                                      errors.duration[index] &&
                                      errors.duration[index].day && (
                                        <div className="text-danger">
                                          {errors.duration[index].day}
                                        </div>
                                      )}
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          ))}

                          <Row>
                            <Col md={12} className="mb-3">
                              <Button
                                variant="primary"
                                onClick={() =>
                                  push({
                                    price: "",
                                    day: "",
                                    discountedPrice: "",
                                  })
                                }
                              >
                                Add Duration
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    </FieldArray>
                    <Col md={6} className="mb-3" style={{ zIndex: 100 }}>
                      <Form.Group id="select_preparing">
                        <Form.Label>Select Group</Form.Label>
                        <Select
                          name="select_preparing"
                          value={values.select_preparing.map((id) => ({
                            value: id,
                            label: id,
                          }))}
                          options={preparingFor}
                          isMulti
                          onChange={(selectedOptions) => {
                            setFieldValue(
                              "select_preparing",
                              selectedOptions.map((option) => option.value)
                            );
                          }}
                        />
                        {errors.select_preparing && (
                          <div className="text-danger">
                            {errors.select_preparing}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Row>
                      <Col md={4} className="mb-3 ">
                        <Form.Group id="firstName">
                          <Form.Label> Videos </Form.Label>
                          <MultiCascader
                            toggleAs={Button}
                            size="lg"
                            placeholder="Content Videos"
                            data={generateVideoCascadingOptions()}
                            value={selectedVideosOptions}
                            onChange={handleVideoSelect}
                            style={styles}
                          // parentSelectable={false}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4} className="mb-3 ">
                        <Form.Group id="firstName">
                          <Form.Label>Notes</Form.Label>
                          <MultiCascader
                            toggleAs={Button}
                            size="lg"
                            placeholder="Content Notes"
                            data={generateNotesCascadingOptions()}
                            value={selectedNotesOptions}
                            onChange={handleNotesSelect}
                            style={styles}
                          // parentSelectable={false}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4} className="mb-3 ">
                        <Form.Group id="firstName">
                          <Form.Label>Exams</Form.Label>
                          <MultiCascader
                            toggleAs={Button}
                            size="lg"
                            placeholder="Exam Topics"
                            data={generateExamCascadingOptions()}
                            value={selectedExamOptions}
                            onChange={handleExamSelect}
                            style={styles}
                          // parentSelectable={false}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    

                    <div className="mt-3">
                      <Button
                        variant="primary"
                        type="button"
                        onClick={handleSubmit}
                      >
                        Update
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            // <MDBDataTable
            //   striped
            //   bordered
            //   small
            //   data={{
            //     ...datatable,
            //     rows: datatable.rows.map((item, index) => {
            //       item.duration = item.duration.map((i) => ({
            //         ...i,
            //         discountedPrice:
            //           i.price - i.price * (parseFloat(i.offer) / 100),
            //       }));
            //       return {
            //         id: index + 1,
            //         name: item.plan_name,

            //         actions: (
            //           <div>
            //             <FontAwesomeIcon
            //               icon={faEdit}
            //               className="mx-3"
            //               onClick={() => handleEdit(item)}
            //             />

            //             <FontAwesomeIcon
            //               icon={faTrash}
            //               onClick={() => handleDelete(item.sid)}
            //             />
            //           </div>
            //         ),
            //       };
            //     }),
            //   }}
            // />
            <div>
              {isLoadingData ? (
                <div className="loader-container">
                  <Image className="loader-element animate__animated animate__jackInTheBox" src={ReactLogo} height={40} />
                </div>
              ) : (
                <MaterialReactTable
                  autoResetPageIndex={false}
                  columns={columns}
                  data={jsonData}
                  enableRowOrdering
                  enableSorting={false}
                  muiTableBodyRowDragHandleProps={({ table }) => ({
                    onDragEnd: () => {
                      const { draggingRow, hoveredRow } = table.getState();
                      if (hoveredRow && draggingRow) {
                        jsonData.splice(
                          hoveredRow.index,
                          0,
                          jsonData.splice(draggingRow.index, 1)[0]
                        );
                        setJsonData([...jsonData]);
                        let position_data = {
                          subscription_id: [],
                          order: [],
                        };
                        jsonData?.forEach((element, index) => {
                          position_data.subscription_id.push(element._id);
                          position_data.order.push(index + 1);
                        });

                        var newtoken = localStorage.getItem("token");

                        let config = {
                          method: "put",
                          maxBodyLength: Infinity,
                          url: "https://api.sushrutalgs.in/api/updateSubscriptionPosition",
                          headers: {
                            Authorization: newtoken,
                            "Content-Type": "application/json",
                          },
                          data: position_data,
                        };

                        axios
                          .request(config)
                          .then((response) => {
                            console.log(JSON.stringify(response.data));
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }
                    },
                  })}
                />
              )}
            </div>

          )}

          {/* <div className="pagination">
          <button
            className={`page-button ${currentPage === 1 ? "disabled" : ""}`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FontAwesomeIcon icon={faBackward} />
          </button>

          {pagination.map((page) => (
            <button
              key={page}
              className={`page-button ${
                page === currentPage ? "current-page" : ""
              }`}
              onClick={() => __handleChangePage(page)}
            >
              {page}
            </button>
          ))}

          <button
            className={`page-button ${
              currentPage === pagination.length ? "disabled" : ""
            }`}
            onClick={handleNextPage}
            disabled={currentPage === pagination.length}
          >
            <FontAwesomeIcon icon={faForward} />
          </button>
        </div> */}
        </Card.Body>
      </Card>
    </>
  );
};
