import React, { useState, useEffect, useMemo, useRef } from "react";
import moment from "moment-timezone";
import { Formik, Form as FormikForm } from "formik";
import Datetime from "react-datetime";
import { Row, Col, Button, Form as BootstrapForm } from "react-bootstrap";
import {
  // Col,
  // Row,
  Card,
  Form,
  // Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import DownloadExcelButton from "./downloadExel";
import JoditEditor from "jodit-react";
import { MDBDataTable } from "mdbreact";
import { useLocation } from "react-router-dom";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
import ReactLogo from "../assets/img/technologies/loading-loader-svgrepo-com.svg";
import { Image } from "@themesberg/react-bootstrap";
import Select from "react-select";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export const Query = () => {
  const initialEditData = {
    question_text: "",
    question_number: "",
    question_image: [],
    explanation_image: [],
    options: [],
    correct_option: "",
    explanation: "",
    // ...editData, // Spread editData to override defaults if it has values  
  };
  
  const [items, setItems] = useState([]); // Initialize items state
  const editor = useRef(null);

  // querymodal
  const [Editopen, setEditOpen] = useState(false);
  const onEditOpenModal = () => setEditOpen(true);
  const onEditCloseModal = () => setEditOpen(false);

  // mockquery modal
  const [Editopenmock, setEditmockOpen] = useState(false);
  const onEditmockOpenModal = () => setEditmockOpen(true);
  const onEditmockCloseModal = () => setEditmockOpen(false);

  // QuizQuey modal
  const [Editopen1, setEditOpen1] = useState(false);
  const onEditOpenModal1 = () => setEditOpen1(true); // Change this line
  const onEditCloseModal1 = () => setEditOpen1(false);

  // customquerymodal
  // const [Editcustomopen, setEditcustomOpen] = useState(false);
  // const onEditcustomOpenModal = () => setEditcustomOpen(true);
  // const onEditcustomCloseModal = () => setEditcustomOpen(false);
  const [EditCustomOpen, setEditCustomOpen] = useState(false);
  const onEditOpenCustomModal1 = () => setEditCustomOpen(true); // Change this line
  const onEditCloseCustomModal1 = () => setEditCustomOpen(false);

  const [jsonData, setJsonData] = useState([]);

  const [editData1, setEditData1] = useState({
    bookName: "",
    description: "",
    bookType: "",
    volume: 0,
    price: 0,
    comboPrice: 0,
    created_at: "",
    updated_at: "",
    __v: 0,
    notesOverview: [],
  });
  const [isError, setIsError] = useState("");
  const [categoryData, setMyCategoryData] = useState([]);
  const [subcategoryData, setMysubCategoryData] = useState([]);

  const [editData, setEditData] = useState({});
  // const [editmockData, setEditmockData] = useState({});

  const [editQuizData, setEditQuizData] = useState({});
  const [editcustomData, setEditCustomData] = useState({});

  const [editDataMock, setEditDataMock] = useState({});

  // const [editData1, setEditData1] = useState({});
  const [isLoadingData, setIsLoadingData] = useState(true);
  const history = useHistory();
  const [value, setValue] = useState("1");

  const columns = useMemo(
    () => [
      // {
      //   accessorKey: "No",
      //   header: "no",
      // },
      {
        accessorKey: "user_fullname",
        header: "User Fullname",
      },
      {
        accessorKey: "exam_name",
        header: "Exam Name",
      },
      {
        accessorKey: "category_name",
        header: "Category Name",
      },
      {
        accessorKey: "subcategory_name",
        header: "Subcategory Name",
      },
      {
        accessorKey: "topic_name",
        header: "Topic Name",
      },
      {
        accessorKey: "query",
        header: "Query ",
      },
      {
        accessorKey: "isSolveQuery",
        header: "IsSolveQuery",
      },
      {
        accessorKey: "IncorrectQuestion",
        header: "IncorrectQuestion",
      },
      {
        accessorKey: "IncorrectAnswer",
        header: "IncorrectAnswer",
      },
      {
        accessorKey: "ExplanationIssue",
        header: "ExplanationIssue",
      },
      {
        accessorKey: "OtherIssue",
        header: "OtherIssue",
      },
      {
        accessorKey: "WrongImg",
        header: "WrongImg",
      },
      {
        accessorKey: "ImgNotClear",
        header: "ImgNotClear",
      },
      {
        accessorKey: "SpelingError",
        header: "SpelingError",
      },
      {
        accessorKey: "Expl_Qs_NotMatch",
        header: "Expl Qs NotMatch",
      },
      {
        accessorKey: "Expl_An_NotMatch",
        header: "Expl An NotMatch",
      }, {
        accessorKey: "Qs_An_Option_NotMatch",
        header: "Qs An Option NotMatch",
      },
      // {
      //   accessorKey: "question_text",
      //   header: "Question_text",
      // },
      // {
      //   accessorKey: "question_number",
      //   header: "Question_number",
      // },
      // {
      //   accessorKey: "question_image",
      //   header: "question_image",
      // },
      // {
      //   accessorKey: "correct_option",
      //   header: "Correct_option",
      // },
      // {
      //   accessorKey: "explanation",
      //   header: "Explanation",
      // },

      {
        accessorKey: "actions",
        header: "Actions",
      },
    ],
    []
    //end
  );
  const columns2 = useMemo(
    () => [
      {
        accessorKey: "user_fullname",
        header: "User Fullname",
      },

      {
        accessorKey: "query",
        header: "Query",
      },
      {
        accessorKey: "exam_name",
        header: "Exam Name",
      },
      {
        accessorKey: "category_name",
        header: "Category Name",
      },
      {
        accessorKey: "subcategory_name",
        header: "Subcategory Name",
      },
      {
        accessorKey: "topic_name",
        header: "Topic Name",
      },
      {
        accessorKey: "isSolveQuery",
        header: "IsSolveQuery",
      },
      {
        accessorKey: "IncorrectQuestion",
        header: "IncorrectQuestion",
      },
      {
        accessorKey: "IncorrectAnswer",
        header: "IncorrectAnswer",
      },
      {
        accessorKey: "ExplanationIssue",
        header: "ExplanationIssue",
      },
      {
        accessorKey: "OtherIssue",
        header: "OtherIssue",
      },
      {
        accessorKey: "question_text",
        header: "Question_text",
      },

      {
        accessorKey: "actions",
        header: "Actions",
      },
    ],
    []
    //end
  );
  const columns3 = useMemo(
    () => [
      {
        accessorKey: "user_fullname",
        header: "User Fullname",
      },
      {
        accessorKey: "quiz_name",
        header: "Quiz Mame",
      },

      {
        accessorKey: "query",
        header: "Query ",
      },
      {
        accessorKey: "isSolveQuery",
        header: "IsSolveQuery",
      },
      {
        accessorKey: "IncorrectQuestion",
        header: "IncorrectQuestion",
      },
      {
        accessorKey: "IncorrectAnswer",
        header: "IncorrectAnswer",
      },
      {
        accessorKey: "ExplanationIssue",
        header: "ExplanationIssue",
      },
      {
        accessorKey: "OtherIssue",
        header: "OtherIssue",
      },

      {
        accessorKey: "actions",
        header: "Actions",
      },
    ],
    []
    //end
  );
  const columns4 = useMemo(
    () => [
      {
        accessorKey: "user_fullname",
        header: "User Fullname",
      },
      {
        accessorKey: "query",
        header: "Query ",
      },
      {
        accessorKey: "quiz_name",
        header: "Quiz Name",
      },
      {
        accessorKey: "isSolveQuery",
        header: "IsSolveQuery",
      },
      {
        accessorKey: "IncorrectQuestion",
        header: "IncorrectQuestion",
      },
      {
        accessorKey: "IncorrectAnswer",
        header: "IncorrectAnswer",
      },
      {
        accessorKey: "ExplanationIssue",
        header: "ExplanationIssue",
      },
      {
        accessorKey: "OtherIssue",
        header: "OtherIssue",
      },
      {
        accessorKey: "question_text",
        header: "Question_text",
      },

      {
        accessorKey: "actions",
        header: "Actions",
      },
    ],
    []
    //end
  );

  const getMyQueryData = async () => {
    const token = localStorage.getItem("token");
    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Query/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res.data);

      const data = res.data?.map((item, index) => {
        return {
          No: index + 1,

          query: item.query,
          user_fullname: item.user_fullname,
          exam_name: item.exam_name,
          category_name: item.category_name,
          subcategory_name: item.subcategory_name,
          topic_name: item.topic_name,
          isSolveQuery: (
            <input
              type="checkbox"
              style={{ margin: "0 5px 0 0" }} // Adjust the margin as needed
              checked={item.isSolveQuery}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          isSolveQueryText: item.isSolveQuery ? "True" : "False",

          IncorrectQuestion: (
            <input
              type="checkbox"
              style={{ margin: "10px -10px 0 0" }}
              checked={item.IncorrectQuestion}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          IncorrectQuestionText: item.IncorrectQuestion ? "True" : "False",

          IncorrectAnswer: (
            <input
              type="checkbox"
              checked={item.IncorrectAnswer}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          IncorrectAnswerText: item.IncorrectAnswer ? "True" : "False",

          ExplanationIssue: (
            <input
              type="checkbox"
              checked={item.ExplanationIssue}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          ExplanationIssueText: item.ExplanationIssue ? "True" : "False",

          OtherIssue: (
            <input
              type="checkbox"
              checked={item.OtherIssue}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          OtherIssueText: item.OtherIssue ? "True" : "False",

          WrongImg: (
            <input
              type="checkbox"
              checked={item.WrongImg}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          WrongImgText: item.WrongImg ? "True" : "False",

          ImgNotClear: (
            <input
              type="checkbox"
              checked={item.ImgNotClear}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          ImgNotClearText: item.ImgNotClear ? "True" : "False",
          SpelingError: (
            <input
              type="checkbox"
              checked={item.SpelingError}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          SpelingErrorText: item.SpelingError ? "True" : "False",
          Expl_Qs_NotMatch: (
            <input
              type="checkbox"
              checked={item.Expl_Qs_NotMatch}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          Expl_Qs_NotMatchText: item.Expl_Qs_NotMatch ? "True" : "False",
          Expl_An_NotMatch: (
            <input
              type="checkbox"
              checked={item.Expl_An_NotMatch}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          Expl_An_NotMatchText: item.Expl_An_NotMatch ? "True" : "False",

          Qs_An_Option_NotMatch: (
            <input
              type="checkbox"
              checked={item.Qs_An_Option_NotMatch}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          Qs_An_Option_NotMatchText: item.Qs_An_Option_NotMatch ? "True" : "False",


          question_text: item.question_text,
          explanation_image: item.explanation_image,
          // question_image: item.question_image,
          correct_option: item.correct_option,
          explanation: item.explanation,
          _id: item._id,
          actions: (
            <div>
              <button onClick={() => handleDelete(item._id)}>
                <FontAwesomeIcon icon={faQuestionCircle} /> Solve Query
              </button>
              <FontAwesomeIcon
                icon={faEdit}
                onClick={() => handleEdit(item)}
                className="mx-2"
              />
            </div>
          ),
        };
      });
      setIsLoadingData(false);
      setJsonData(data);
      // setDatatable((prevState) => ({
      //   ...prevState,
      //   rows: data,
      // }));
    } catch (error) {
      setIsError(error.response);
      setIsLoadingData(false);
    }
  };

  const getQuizQueryData = async () => {
    const token = localStorage.getItem("token");
    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getAllQuizQuery`,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res.data, "getQuizQueryData");

      const data = res.data?.map((item, index) => {
        return {
          id: index + 1,
          isSolveQuery: (
            <input
              type="checkbox"
              checked={item.isSolveQuery}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          isSolveQueryText: item.isSolveQuery ? "True" : "False",

          IncorrectQuestion: (
            <input
              type="checkbox"
              checked={item.IncorrectQuestion}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          IncorrectQuestionText: item.IncorrectQuestion ? "True" : "False",

          IncorrectAnswer: (
            <input
              type="checkbox"
              checked={item.IncorrectAnswer}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          IncorrectAnswerText: item.IncorrectAnswer ? "True" : "False",

          ExplanationIssue: (
            <input
              type="checkbox"
              checked={item.ExplanationIssue}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          ExplanationIssueText: item.ExplanationIssue ? "True" : "False",

          OtherIssue: (
            <input
              type="checkbox"
              checked={item.OtherIssue}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          OtherIssueText: item.OtherIssue ? "True" : "False",
          
          query: item.query,
          user_fullname: item.user_fullname,
          exam_name: item.exam_name,
          category_name: item.category_name,
          subcategory_name: item.subcategory_name,
          topic_name: item.topic_name,
          question_text: item.question_text,
          explanation_image: item.explanation_image,
          correct_option: item.correct_option,
          explanation: item.explanation,
          _id: item._id,
          actions: (
            <div>
              <button onClick={() => handleDeleteQuiz(item._id)}>
                <FontAwesomeIcon icon={faQuestionCircle} /> Solve Query
              </button>
              <FontAwesomeIcon
                icon={faEdit}
                onClick={() => handleEdit10(item)}
                className="mx-2"
              />
            </div>
          ),
        };
      });
      setIsLoadingData(false);
      setJsonData(data);
    } catch (error) {
      setIsError(error.response);
      setIsLoadingData(false);
    }
  };
  const handleChange = (event, newValue) => {
    if (newValue === "1") {
      getMyQueryData();
    } else if (newValue === "2") {
      getMyMockQueryData();
    } else if (newValue === "3") {
      getQuizQueryData();
    } else {
      getMyCustomQueryData();
    }
    setValue(newValue);
  };

  // if (newValue === "2") {
  //   getMyOfferData(newValue);
  // } else if (newValue === "") {
  //   getMyMockQueryData(newValue);
  // } else if{
  //   greeting = "Good evening";
  // }

  // const handleChange = (event, newValue) => {
  //   if (newValue === "3") {
  //     // getMyOfferData(newValue);
  //   } else {
  //     getMyMockQueryData(newValue);
  //   }
  //   setValue(newValue);
  // };

  const getMyMockQueryData = async () => {
    const token = localStorage.getItem("token");
    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/MockQuery/getAll`,
        {
          headers: { Authorization: token },
        }
      );

      const data = res.data?.map((item, index) => {
        return {
          id: index + 1,

          query: item.query,
          user_fullname: item.user_fullname,
          exam_name: item.exam_name,
          category_name: item.category_name,
          subcategory_name: item.subcategory_name,
          topic_name: item.topic_name,
          isSolveQuery: (
            <input
              type="checkbox"
              checked={item.isSolveQuery}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          isSolveQueryText: item.isSolveQuery ? "True" : "False",

          IncorrectQuestion: (
            <input
              type="checkbox"
              checked={item.IncorrectQuestion}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          IncorrectQuestionText: item.IncorrectQuestion ? "True" : "False",

          IncorrectAnswer: (
            <input
              type="checkbox"
              checked={item.IncorrectAnswer}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          IncorrectAnswerText: item.IncorrectAnswer ? "True" : "False",

          ExplanationIssue: (
            <input
              type="checkbox"
              checked={item.ExplanationIssue}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          ExplanationIssueText: item.ExplanationIssue ? "True" : "False",

          OtherIssue: (
            <input
              type="checkbox"
              checked={item.OtherIssue}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          OtherIssueText: item.OtherIssue ? "True" : "False",
          question_text: item.question_text,
          explanation_image: item.explanation_image,
          // question_image: item.question_image,
          correct_option: item.correct_option,
          explanation: item.explanation,
          _id: item._id,
          actions: (
            <div>
              <button onClick={() => handleDeleteMock(item._id)}>
                <FontAwesomeIcon icon={faQuestionCircle} /> Solve Query
              </button>
              <FontAwesomeIcon
                icon={faEdit}
                onClick={() => handleEditmock(item)}
                className="mx-2"
              />
            </div>
          ),
        };
      });

      setJsonData(data);
      setIsLoadingData(false);
      // setDatatable((prevState) => ({
      //   ...prevState,
      //   rows: data,
      // }));
    } catch (error) {
      setIsError(error.response);
      setIsLoadingData(false);
    }
  };

  // const updateUser = async (id, updatedData) => {
  //   try {
  //     const token = localStorage.getItem("token");
  //     const res = await axios.put(
  //       `${process.env.REACT_APP_BASE_URL}/api/updateQuizQuery/${id}`,
  //       updatedData,
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );

  //     console.log(res.data, "******************");
  //     onEditCloseModal1(); // Close the modal after successful update.
  //     Swal.fire({
  //       position: "center",
  //       icon: "success",
  //       title: "Category Updated Successfully",
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //     getQuizQueryData();
  //   } catch (error) {
  //     console.error(error.response); // Handle error responses
  //   }
  // };

  const getMyCustomQueryData = async () => {
    const token = localStorage.getItem("token");
    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getAllCustomQuery`,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res.data, "custom&&&&&&&&&&&&&&&&&&&&");
      const data = res.data?.map((item, index) => {
        return {
          id: index + 1,
          isSolveQuery: (
            <input
              type="checkbox"
              checked={item.isSolveQuery}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          isSolveQueryText: item.isSolveQuery ? "True" : "False",

          IncorrectQuestion: (
            <input
              type="checkbox"
              checked={item.IncorrectQuestion}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          IncorrectQuestionText: item.IncorrectQuestion ? "True" : "False",

          IncorrectAnswer: (
            <input
              type="checkbox"
              checked={item.IncorrectAnswer}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          IncorrectAnswerText: item.IncorrectAnswer ? "True" : "False",

          ExplanationIssue: (
            <input
              type="checkbox"
              checked={item.ExplanationIssue}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          ExplanationIssueText: item.ExplanationIssue ? "True" : "False",

          OtherIssue: (
            <input
              type="checkbox"
              checked={item.OtherIssue}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          OtherIssueText: item.OtherIssue ? "True" : "False",
          query: item.query,
          user_fullname: item.user_fullname,
          exam_name: item.exam_name,
          category_name: item.category_name,
          subcategory_name: item.subcategory_name,
          topic_name: item.topic_name,
          question_number: item.question_number,
          question_text: item.question_text,
          explanation_image: item.explanation_image,
          // question_image: item.question_image,
          correct_option: item.correct_option,
          explanation: item.explanation,
          _id: item._id,
          actions: (
            <div>
              <button onClick={() => handleDeleteCustom(item._id)}>
                <FontAwesomeIcon icon={faQuestionCircle} /> Solve Query
              </button>
              <FontAwesomeIcon
                icon={faEdit}
                onClick={() => handleEditCustom(item)}
                className="mx-2"
              />
            </div>
          ),
        };
      });

      setJsonData(data);
      setIsLoadingData(false);
      // setDatatable((prevState) => ({
      //   ...prevState,
      //   rows: data,
      // }));
    } catch (error) {
      setIsError(error.response);
      setIsLoadingData(false);
    }
  };

  
  // const getMyCategoryData = async (values) => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/api/Category/getAll`,
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );
  //     setMyCategoryData(res.data);
  //     //   console.log(res.data, "sub category");
  //   } catch (error) {
  //     setIsError(error.response);
  //   }
  // };

  const [imgReload, setImgReload] = useState(false);
  const fileERef = useRef(null);

  const handleEdit = (QuestionData) => {
    setEditData({
      ...QuestionData,
      explanation_image: QuestionData.explanation_image,
      question_image: QuestionData.question_image,
    });
    setEditOpen(true);
  };

  const handleEditmock = (QuestionData) => {
    setEditDataMock({
      ...QuestionData,
      explanation_image: QuestionData.explanation_image || [], // Ensure it's an array
      question_image: QuestionData.question_image || [], // Ensure it's an array
    });
    setEditmockOpen(true);
  };

  const handleEdit10 = (QuestionData) => {
    setEditQuizData({
      ...QuestionData,
      question_image: QuestionData.question_image || [],
      explanation_image: QuestionData.explanation_image || [],
    });
    setEditOpen1(true);
  };

  const handleEditCustom = (QuestionData) => {
    setEditCustomData({
      ...QuestionData,
      question_image: QuestionData.question_image || [],
      explanation_image: QuestionData.explanation_image || [],
    });
    setEditCustomOpen(true);
  };

  // const handleEditCustom = ({ QuestionData }) => {
  //   setEditCustomData({
  //     ...QuestionData,
  //     explanation_image: QuestionData.explanation_image,
  //     question_image: QuestionData.question_image,
  //   }); // Set the data of the selected row
  //   setEditCustomOpen(true); // Open the modal
  // };

  const updateQuestion = async (id, updatedData) => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/updateQuestion/${id}`,
        {
          question_text: updatedData.question_text,
          question_number: updatedData.question_number,
          question_image: updatedData.question_image,
          explanation_image: updatedData.explanation_image,
          options: updatedData.options,
          correct_option: updatedData.correct_option,
          explanation: updatedData.explanation,
        },
        {
          headers: { Authorization: token },
        }
      );
      console.log(res.data, "&&&&&&&&&");
      // Log the response or handle it as required.
      // Optionally, you can refetch the subcategory data after successful update.
      // getMySubCategoryData();
      onEditCloseModal(); // Close the modal after successful update.
      Swal.fire({
        position: "center",
        icon: "success",
        title: " Question Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getMyQueryData();
    } catch (error) {
      console.error(error.response); // Handle error responses
    }
  };

  // const updateMasterQuestion = async (id, updatedData) => {
  //   try {
  //     const token = localStorage.getItem("token");
  //     const res = await axios.put(
  //       `${process.env.REACT_APP_BASE_URL}/api/updateMasterQuestion/${id}`,
  //       {
  //         question_text: updatedData.question_text,
  //         question_number: updatedData.question_number,
  //         question_image: updatedData.question_image,
  //         explanation_image: updatedData.explanation_image,
  //         options: updatedData.options,
  //         correct_option: updatedData.correct_option,
  //         explanation: updatedData.explanation,
  //       },
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );
  //     onEditCloseModal(); // Close the modal after successful update.
  //     Swal.fire({
  //       position: "center",
  //       icon: "success",
  //       title: " Question Updated Successfully",
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //     getMyQueryData();
  //   } catch (error) {
  //     console.error(error.response); // Handle error responses
  //   }
  // };
  const updatemock = async (id, updatedData) => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/updateMasterQuestion/${id}`,
        {
          question_text: updatedData.question_text,
          question_number: updatedData.question_number,
          question_image: updatedData.question_image,
          explanation_image: updatedData.explanation_image,
          options: updatedData.options,
          correct_option: updatedData.correct_option,
          explanation: updatedData.explanation,
        },
        {
          headers: { Authorization: token },
        }
      );
      console.log(res.data, "update mock");
      onEditCloseModal(); // Close the modal after successful update.
      Swal.fire({
        position: "center",
        icon: "success",
        title: " Question Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getMyMockQueryData();
    } catch (error) {
      console.error(error.response); // Handle error responses
    }
  };

  const updateQuestioncustom = async (id, updatedData) => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/updateQuestion/${id}`,
        {
          question_text: updatedData.question_text,
          question_number: updatedData.question_number,
          question_image: updatedData.question_image,
          explanation_image: updatedData.explanation_image,
          options: updatedData.options,
          correct_option: updatedData.correct_option,
          explanation: updatedData.explanation,
        },
        {
          headers: { Authorization: token },
        }
      );
      console.log(res.data, "&&&&&&&&&");
      // Log the response or handle it as required.
      // Optionally, you can refetch the subcategory data after successful update.
      // getMySubCategoryData();
      onEditCloseModal(); // Close the modal after successful update.
      Swal.fire({
        position: "center",
        icon: "success",
        title: " Query Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getMyCustomQueryData();
    } catch (error) {
      console.error(error.response); // Handle error responses
    }
  };
  const updateQuizQuery = async (id, updatedData) => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/updateQuizQuestion/${id}`,
        {
          question_text: updatedData.question_text,
          question_number: updatedData.question_number,
          question_image: updatedData.question_image,
          explanation_image: updatedData.explanation_image,
          options: updatedData.options,
          correct_option: updatedData.correct_option,
          explanation: updatedData.explanation,
        },
        {
          headers: { Authorization: token },
        }
      );
      console.log(res.data, "&&&&&&&&&");
      // Log the response or handle it as required.
      // Optionally, you can refetch the subcategory data after successful update.
      // getMySubCategoryData();
      onEditCloseModal(); // Close the modal after successful update.
      Swal.fire({
        position: "center",
        icon: "success",
        title: " Question Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getQuizQueryData();
    } catch (error) {
      console.error(error.response); // Handle error responses
    }
  };
// const updateQuizQuery = async (id, updatedData, onEditCloseModal, getQuizQueryData) => {
//   try {
//     const token = localStorage.getItem("token");
//     const res = await axios.put(
//       `${process.env.REACT_APP_BASE_URL}/api/updateQuizQuestion/${id}`,
//       {
//         question_text: updatedData.question_text,
//         question_number: updatedData.question_number,
//         question_image: updatedData.question_image,
//         explanation_image: updatedData.explanation_image,
//         options: updatedData.options,
//         correct_option: updatedData.correct_option,
//         explanation: updatedData.explanation,
//       },
//       {
//         headers: { Authorization: `Bearer ${token}` },
//       }
//     );
//     console.log(res.data, "updateQuizQuery777777777777777777");
//     onEditCloseModal(); // Close the modal after successful update.
//     Swal.fire({
//       position: "center",
//       icon: "success",
//       title: "Question Updated Successfully",
//       showConfirmButton: false,
//       timer: 1500,
//     });
//     getQuizQueryData();
//   } catch (error) {
//     console.error(error.response); // Handle error responses
//     Swal.fire({
//       position: "center",
//       icon: "error",
//       title: "Failed to Update Question",
//       text: error.response?.data?.message || "An error occurred.",
//       showConfirmButton: true,
//     });
//   }
// };


  useEffect(() => {
    // getMyCategoryData();
    getMyCustomQueryData();
    getMyMockQueryData();
    getQuizQueryData();
    getMyQueryData();
  }, []);
  const tabListStyles = {
    display: "flex",
    justifyContent: "center",
    background: "#f5f5f5",
    padding: "10px",
    borderRadius: "5px",
  };

  const tabStyles = {
    fontSize: "18px",
    fontWeight: "bold",
    textTransform: "uppercase",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "5px",
    cursor: "pointer",
    color: "#333",
    background: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "background 0.3s ease-in-out",
  };

  const activeTabStyles = {
    background: "#007bff", // Change this to the desired background color for the active tab
    color: "#fff", // Change this to the desired text color for the active tab
  };

  const handleDelete = (id) => {
    setIsLoadingData(true);
    // Show the confirmation dialog using SweetAlert
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Solve it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with the delete operation
        try {
          const token = localStorage.getItem("token");
          const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/updateQuery/${id}`,
            { isSolveQuery: true },
            {
              headers: { Authorization: token },
            }
          );

          Swal.fire("Solve!", "Query Solved.", "success");
          getMyQueryData();
        } catch (error) {
          console.error(error.response); // Handle error responses
          // Show an error message using SweetAlert
          Swal.fire("Error", "Failed to delete the file.", "error");
          setIsLoadingData(false);
        }
      }
    });
  };

  const handleDeleteMock = (id) => {
    setIsLoadingData(true);
    // Show the confirmation dialog using SweetAlert
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Solve it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with the delete operation
        try {
          const token = localStorage.getItem("token");
          const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/updateMockQuery/${id}`,
            { isSolveQuery: true },
            {
              headers: { Authorization: token },
            }
          );

          Swal.fire("Solve!", "MockQuery Solved.", "success");
          getMyQueryData();
        } catch (error) {
          console.error(error.response); // Handle error responses
          // Show an error message using SweetAlert
          Swal.fire("Error", "Failed to delete the file.", "error");
          setIsLoadingData(false);
        }
      }
    });
  };

  const handleDeleteQuiz = (id) => {
    setIsLoadingData(true);
    // Show the confirmation dialog using SweetAlert
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Solve it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with the delete operation
        try {
          const token = localStorage.getItem("token");
          const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/updateQuizQuery/${id}`,
            { isSolveQuery: true },
            {
              headers: { Authorization: token },
            }
          );

          Swal.fire("Solve!", "MockQuery Solved.", "success");
          getMyQueryData();
        } catch (error) {
          console.error(error.response); // Handle error responses
          // Show an error message using SweetAlert
          Swal.fire("Error", "Failed to delete the file.", "error");
          setIsLoadingData(false);
        }
      }
    });
  };

  const handleDeleteCustom = (id) => {
    setIsLoadingData(true);
    // Show the confirmation dialog using SweetAlert
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Solve it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with the delete operation
        try {
          const token = localStorage.getItem("token");
          const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/updateCustomQuery/${id}`,
            { isSolveQuery: true },
            {
              headers: { Authorization: token },
            }
          );

          Swal.fire("Solve!", "Query Solved.", "success");
          getMyQueryData();
        } catch (error) {
          console.error(error.response); // Handle error responses
          // Show an error message using SweetAlert
          Swal.fire("Error", "Failed to delete the file.", "error");
          setIsLoadingData(false);
        }
      }
    });
  };
  // const handleToggleActive = async (event, id) => {
  //   const token = localStorage.getItem("token");
  //   const newActiveStatus = event.target.checked;
  //   try {
  //     await axios.put(
  //       `${process.env.REACT_APP_BASE_URL}/api/updateQuery/${id}`,
  //       { isSolveQuery: true },
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );
  //     getMyQueryData();
  //   } catch (error) {
  //     console.error("Error updating active status: ", error);
  //   }
  // };

  const handleToggleActive = (event, item, field) => {
    const updatedItem = { ...item, [field]: event.target.checked };
    const updatedItems = items.map((i) => (i.id === item.id ? updatedItem : i));
    setItems(updatedItems); // Update the state with the modified items array
  };
  return (
    <Card border="light" className="bg-white shadow-sm mb-4"> 
      <Card.Body>
        <h5 className="mb-4">Queries</h5>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          {/* <button className="btn btn-primary" onClick={onOpenModal} >
            Add Subcategory
            <FontAwesomeIcon icon={faPlus} className="mx-2" />
          </button> */}
          <DownloadExcelButton jsonData={jsonData} fileName="query" />
        </div>

        {/* <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Subcategory Name</th>
              <th scope="col">Category Name</th>
              <th scope="col">description</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {subcategoryData
              .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
              .map((item, index) => (
                <tr>
                  <th scope="row">{item.recordNo}</th>
                  <td>{item.subcategory_name}</td>
                  <td>{item.category_name}</td>
                  <td>{item.description}</td>
                  <td>
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="mx-3"
                      onClick={() => handleEdit(item)}
                    />

                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => handleDelete(item._id)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table> */}

        {/* <MDBDataTable striped bordered small data={datatable} /> */}
      </Card.Body>

    
      <Modal open={Editopen} onClose={onEditCloseModal} center>
        <h2>Edit Query</h2>
        <Formik
          initialValues={editData}
          onSubmit={(values) => {
            console.log("values", values);
            updateQuestion(editData.question_id, values); // Pass the ID and updated data to updateQuestion
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form>
              <Row>
              
                <Col md={12} className="mb-3">
                  <Form.Group id="firstName">
                    <Form.Label>Question</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your Question"
                      name="question_text"
                      value={values.question_text}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                {values.question_image.length > 0 && (
                  <div className="row">
                    {values.question_image.map((img, imgIndex) => (
                      <div
                        className="image-area"
                        style={{ width: "90%" }}
                        key={imgIndex}
                      >
                        <img
                          src={`data:image/png;base64,${img}`}
                          alt={`Explanation ${imgIndex + 1}`}
                        />
                        <a
                          className="remove-image"
                          onClick={() => {
                            const updatedImages = [...values.question_image];
                            updatedImages.splice(imgIndex, 1);
                            setFieldValue("question_image", updatedImages);
                          }}
                          style={{ display: "inline" }}
                        >
                          &#215;
                        </a>
                      </div>
                    ))}
                  </div>
                )}

                <Col md={12} className="mb-3">
                  <Form.Group id="explanation_image">
                    <Form.Label>
                      Add {values.question_image.length > 0 ? "More" : ""}{" "}
                      Question Image
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="question_image"
                      multiple={true}
                      onChange={(event) => {
                        const files = event.currentTarget.files;
                        if (files) {
                          const reader = new FileReader();
                          reader.onload = (e) => {
                            const base64Data = e.target.result.split(",")[1];
                            setFieldValue(
                              "question_image",
                              values.question_image.concat(base64Data)
                            );
                          };
                          reader.readAsDataURL(files[0]);
                        }
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md={12} className="mb-3">
                  <Form.Group id="options">
                    <Form.Label>Options</Form.Label>
                    {values.options?.map((option, index) => (
                      <div key={index} className="d-flex mb-2">
                        <Form.Control
                          type="text"
                          placeholder={`Enter option ${option.value} title`}
                          name={`options[${index}].answer_title`}
                          value={option.answer_title}
                          onChange={handleChange}
                        />
                        {option.answer_image && (
                          <Form.Control
                            type="file"
                            name={`options[${index}].answer_image`}
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              if (file) {
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                  const base64Data =
                                    e.target.result.split(",")[1];
                                  setFieldValue(
                                    `options[${index}].answer_image`,
                                    base64Data
                                  );
                                };
                                reader.readAsDataURL(file);
                              }
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="correct_option">
                    <Form.Label>Correct Option</Form.Label>
                    <Form.Control
                      as="select"
                      name="correct_option"
                      value={values.correct_option}
                      onChange={handleChange}
                    >
                      {values.options?.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Col md={12} className="mb-3">
                <Form.Group id="explanation_image">
                  <Form.Label>
                    Add {values.explanation_image.length > 0 ? "More" : ""}{" "}
                    Explanation Image
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="explanation_image"
                    onChange={(event) => {
                      const files = event.currentTarget.files;
                      if (files) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          const base64Data = e.target.result.split(",")[1];
                          setFieldValue(
                            "explanation_image",
                            values.explanation_image.concat(base64Data)
                          );
                        };
                        reader.readAsDataURL(files[0]);
                      }
                    }}
                  />
                </Form.Group>
              </Col>

              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="firstName">
                    <Form.Label>Explanation</Form.Label>
                    <JoditEditor
                      ref={editor}
                      value={values.explanation}
                      onBlur={(newContent) =>
                        setFieldValue("explanation", newContent)
                      }
                      onChange={(newContent) =>
                        setFieldValue("explanation", newContent)
                      }
                      tabIndex={1}
                    />
                  </Form.Group>
                  <div className="mb-3 text-danger">
                    {errors.explanation &&
                      touched.explanation &&
                      errors.explanation}
                  </div>
                </Col>

                {values.explanation_image.length > 0 && (
                  <Col md={12} className="mb-3">
                    {values.explanation_image.map((img, imgIndex) => (
                      <div
                        className="image-area"
                        style={{ width: "90%" }}
                        key={imgIndex}
                      >
                        <img
                          src={`data:image/png;base64,${img}`}
                          alt={`Explanation ${imgIndex + 1}`}
                        />
                        <a
                          className="remove-image"
                          onClick={() => {
                            const updatedImages = [...values.explanation_image];
                            updatedImages.splice(imgIndex, 1);
                            setFieldValue("explanation_image", updatedImages);
                          }}
                          style={{ display: "inline" }}
                        >
                          &#215;
                        </a>
                      </div>
                    ))}
                  </Col>
                )}
              </Row>

              <div className="mt-3">
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  Update
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      <Modal open={Editopenmock} onClose={onEditmockCloseModal} center>
        <h2>Edit MockQuery</h2>
        <Formik
          initialValues={editDataMock}
          onSubmit={(values) => {
            console.log("values", values);
            updatemock(editDataMock.question_id, values); // Pass the ID and updated data to updateQuestion
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="firstName">
                    <Form.Label>Question</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your Question"
                      name="question_text"
                      value={values.question_text}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                {values.question_image.length > 0 && (
                  <div className="row">
                    {values.question_image.map((img, imgIndex) => (
                      <div
                        className="image-area"
                        style={{ width: "90%" }}
                        key={imgIndex}
                      >
                        <img
                          src={`data:image/png;base64,${img}`}
                          alt={`Explanation ${imgIndex + 1}`}
                        />
                        <a
                          className="remove-image"
                          onClick={() => {
                            const updatedImages = [...values.question_image];
                            updatedImages.splice(imgIndex, 1);
                            setFieldValue("question_image", updatedImages);
                          }}
                          style={{ display: "inline" }}
                        >
                          &#215;
                        </a>
                      </div>
                    ))}
                  </div>
                )}

                <Col md={12} className="mb-3">
                  <Form.Group id="explanation_image">
                    <Form.Label>
                      Add {values.question_image.length > 0 ? "More" : ""}{" "}
                      Question Image
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="question_image"
                      multiple={true}
                      onChange={(event) => {
                        const files = event.currentTarget.files;
                        if (files) {
                          const reader = new FileReader();
                          reader.onload = (e) => {
                            const base64Data = e.target.result.split(",")[1];
                            setFieldValue(
                              "question_image",
                              values.question_image.concat(base64Data)
                            );
                          };
                          reader.readAsDataURL(files[0]);
                        }
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md={12} className="mb-3">
                  <Form.Group id="options">
                    <Form.Label>Options</Form.Label>
                    {values.options?.map((option, index) => (
                      <div key={index} className="d-flex mb-2">
                        <Form.Control
                          type="text"
                          placeholder={`Enter option ${option.value} title`}
                          name={`options[${index}].answer_title`}
                          value={option.answer_title}
                          onChange={handleChange}
                        />
                        {option.answer_image && (
                          <Form.Control
                            type="file"
                            name={`options[${index}].answer_image`}
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              if (file) {
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                  const base64Data =
                                    e.target.result.split(",")[1];
                                  setFieldValue(
                                    `options[${index}].answer_image`,
                                    base64Data
                                  );
                                };
                                reader.readAsDataURL(file);
                              }
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="correct_option">
                    <Form.Label>Correct Option</Form.Label>
                    <Form.Control
                      as="select"
                      name="correct_option"
                      value={values.correct_option}
                      onChange={handleChange}
                    >
                      {values.options?.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Col md={12} className="mb-3">
                <Form.Group id="explanation_image">
                  <Form.Label>
                    Add {values.explanation_image.length > 0 ? "More" : ""}{" "}
                    Explanation Image
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="explanation_image"
                    onChange={(event) => {
                      const files = event.currentTarget.files;
                      if (files) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          const base64Data = e.target.result.split(",")[1];
                          setFieldValue(
                            "explanation_image",
                            values.explanation_image.concat(base64Data)
                          );
                        };
                        reader.readAsDataURL(files[0]);
                      }
                    }}
                  />
                </Form.Group>
              </Col>

              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="firstName">
                    <Form.Label>Explanation</Form.Label>
                    <JoditEditor
                      ref={editor}
                      value={values.explanation}
                      onBlur={(newContent) =>
                        setFieldValue("explanation", newContent)
                      }
                      onChange={(newContent) =>
                        setFieldValue("explanation", newContent)
                      }
                      tabIndex={1}
                    />
                  </Form.Group>
                  <div className="mb-3 text-danger">
                    {errors.explanation &&
                      touched.explanation &&
                      errors.explanation}
                  </div>
                </Col>

                {values.explanation_image.length > 0 && (
                  <Col md={12} className="mb-3">
                    {values.explanation_image.map((img, imgIndex) => (
                      <div
                        className="image-area"
                        style={{ width: "90%" }}
                        key={imgIndex}
                      >
                        <img
                          src={`data:image/png;base64,${img}`}
                          alt={`Explanation ${imgIndex + 1}`}
                        />
                        <a
                          className="remove-image"
                          onClick={() => {
                            const updatedImages = [...values.explanation_image];
                            updatedImages.splice(imgIndex, 1);
                            setFieldValue("explanation_image", updatedImages);
                          }}
                          style={{ display: "inline" }}
                        >
                          &#215;
                        </a>
                      </div>
                    ))}
                  </Col>
                )}
              </Row>

              <div className="mt-3">
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  Update
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      <Modal open={Editopen1} onClose={onEditCloseModal1} center>
      <h2>Edit QuizQuery</h2>
      <Formik
          initialValues={editQuizData}
          onSubmit={(values) => {
            console.log("values", values);
            updateQuizQuery(editQuizData.question_id, values); // Pass the ID and updated data to updateQuestion
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="firstName">
                    <Form.Label>Question</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your Question"
                      name="question_text"
                      value={values.question_text}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                {values.question_image.length > 0 && (
                  <div className="row">
                    {values.question_image.map((img, imgIndex) => (
                      <div
                        className="image-area"
                        style={{ width: "90%" }}
                        key={imgIndex}
                      >
                        <img
                          src={`data:image/png;base64,${img}`}
                          alt={`Explanation ${imgIndex + 1}`}
                        />
                        <a
                          className="remove-image"
                          onClick={() => {
                            const updatedImages = [...values.question_image];
                            updatedImages.splice(imgIndex, 1);
                            setFieldValue("question_image", updatedImages);
                          }}
                          style={{ display: "inline" }}
                        >
                          &#215;
                        </a>
                      </div>
                    ))}
                  </div>
                )}

                <Col md={12} className="mb-3">
                  <Form.Group id="explanation_image">
                    <Form.Label>
                      Add {values.question_image.length > 0 ? "More" : ""}{" "}
                      Question Image
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="question_image"
                      multiple={true}
                      onChange={(event) => {
                        const files = event.currentTarget.files;
                        if (files) {
                          const reader = new FileReader();
                          reader.onload = (e) => {
                            const base64Data = e.target.result.split(",")[1];
                            setFieldValue(
                              "question_image",
                              values.question_image.concat(base64Data)
                            );
                          };
                          reader.readAsDataURL(files[0]);
                        }
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md={12} className="mb-3">
                  <Form.Group id="options">
                    <Form.Label>Options</Form.Label>
                    {values.options?.map((option, index) => (
                      <div key={index} className="d-flex mb-2">
                        <Form.Control
                          type="text"
                          placeholder={`Enter option ${option.value} title`}
                          name={`options[${index}].answer_title`}
                          value={option.answer_title}
                          onChange={handleChange}
                        />
                        {option.answer_image && (
                          <Form.Control
                            type="file"
                            name={`options[${index}].answer_image`}
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              if (file) {
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                  const base64Data =
                                    e.target.result.split(",")[1];
                                  setFieldValue(
                                    `options[${index}].answer_image`,
                                    base64Data
                                  );
                                };
                                reader.readAsDataURL(file);
                              }
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="correct_option">
                    <Form.Label>Correct Option</Form.Label>
                    <Form.Control
                      as="select"
                      name="correct_option"
                      value={values.correct_option}
                      onChange={handleChange}
                    >
                      {values.options?.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Col md={12} className="mb-3">
                <Form.Group id="explanation_image">
                  <Form.Label>
                    Add {values.explanation_image.length > 0 ? "More" : ""}{" "}
                    Explanation Image
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="explanation_image"
                    onChange={(event) => {
                      const files = event.currentTarget.files;
                      if (files) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          const base64Data = e.target.result.split(",")[1];
                          setFieldValue(
                            "explanation_image",
                            values.explanation_image.concat(base64Data)
                          );
                        };
                        reader.readAsDataURL(files[0]);
                      }
                    }}
                  />
                </Form.Group>
              </Col>

              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="firstName">
                    <Form.Label>Explanation</Form.Label>
                    <JoditEditor
                      ref={editor}
                      value={values.explanation}
                      onBlur={(newContent) =>
                        setFieldValue("explanation", newContent)
                      }
                      onChange={(newContent) =>
                        setFieldValue("explanation", newContent)
                      }
                      tabIndex={1}
                    />
                  </Form.Group>
                  <div className="mb-3 text-danger">
                    {errors.explanation &&
                      touched.explanation &&
                      errors.explanation}
                  </div>
                </Col>

                {values.explanation_image.length > 0 && (
                  <Col md={12} className="mb-3">
                    {values.explanation_image.map((img, imgIndex) => (
                      <div
                        className="image-area"
                        style={{ width: "90%" }}
                        key={imgIndex}
                      >
                        <img
                          src={`data:image/png;base64,${img}`}
                          alt={`Explanation ${imgIndex + 1}`}
                        />
                        <a
                          className="remove-image"
                          onClick={() => {
                            const updatedImages = [...values.explanation_image];
                            updatedImages.splice(imgIndex, 1);
                            setFieldValue("explanation_image", updatedImages);
                          }}
                          style={{ display: "inline" }}
                        >
                          &#215;
                        </a>
                      </div>
                    ))}
                  </Col>
                )}
              </Row>

              <div className="mt-3">
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  Update
                </Button>
              </div>
            </Form>
          )}
        </Formik>
    </Modal>
      <Modal open={EditCustomOpen} onClose={onEditCloseCustomModal1} center>
        <h2>Edit Custom</h2>
        <Formik
          initialValues={editcustomData}  
          onSubmit={(values) => {
            console.log("values", values);
            updateQuestioncustom(editcustomData.question_id, values); // Pass the ID and updated data to updateQuestion
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="firstName">
                    <Form.Label>Question</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your Question"
                      name="question_text"
                      value={values.question_text}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                {values.question_image.length > 0 && (
                  <div className="row">
                    {values.question_image.map((img, imgIndex) => (
                      <div
                        className="image-area"
                        style={{ width: "90%" }}
                        key={imgIndex}
                      >
                        <img
                          src={`data:image/png;base64,${img}`}
                          alt={`Explanation ${imgIndex + 1}`}
                        />
                        <a
                          className="remove-image"
                          onClick={() => {
                            const updatedImages = [...values.question_image];
                            updatedImages.splice(imgIndex, 1);
                            setFieldValue("question_image", updatedImages);
                          }}
                          style={{ display: "inline" }}
                        >
                          &#215;
                        </a>
                      </div>
                    ))}
                  </div>
                )}

                <Col md={12} className="mb-3">
                  <Form.Group id="explanation_image">
                    <Form.Label>
                      Add {values.question_image.length > 0 ? "More" : ""}{" "}
                      Question Image
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="question_image"
                      multiple={true}
                      onChange={(event) => {
                        const files = event.currentTarget.files;
                        if (files) {
                          const reader = new FileReader();
                          reader.onload = (e) => {
                            const base64Data = e.target.result.split(",")[1];
                            setFieldValue(
                              "question_image",
                              values.question_image.concat(base64Data)
                            );
                          };
                          reader.readAsDataURL(files[0]);
                        }
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md={12} className="mb-3">
                  <Form.Group id="options">
                    <Form.Label>Options</Form.Label>
                    {values.options?.map((option, index) => (
                      <div key={index} className="d-flex mb-2">
                        <Form.Control
                          type="text"
                          placeholder={`Enter option ${option.value} title`}
                          name={`options[${index}].answer_title`}
                          value={option.answer_title}
                          onChange={handleChange}
                        />
                        {option.answer_image && (
                          <Form.Control
                            type="file"
                            name={`options[${index}].answer_image`}
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              if (file) {
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                  const base64Data =
                                    e.target.result.split(",")[1];
                                  setFieldValue(
                                    `options[${index}].answer_image`,
                                    base64Data
                                  );
                                };
                                reader.readAsDataURL(file);
                              }
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="correct_option">
                    <Form.Label>Correct Option</Form.Label>
                    <Form.Control
                      as="select"
                      name="correct_option"
                      value={values.correct_option}
                      onChange={handleChange}
                    >
                      {values.options?.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Col md={12} className="mb-3">
                <Form.Group id="explanation_image">
                  <Form.Label>
                    Add {values.explanation_image.length > 0 ? "More" : ""}{" "}
                    Explanation Image
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="explanation_image"
                    onChange={(event) => {
                      const files = event.currentTarget.files;
                      if (files) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          const base64Data = e.target.result.split(",")[1];
                          setFieldValue(
                            "explanation_image",
                            values.explanation_image.concat(base64Data)
                          );
                        };
                        reader.readAsDataURL(files[0]);
                      }
                    }}
                  />
                </Form.Group>
              </Col>

              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="firstName">
                    <Form.Label>Explanation</Form.Label>
                    <JoditEditor
                      ref={editor}
                      value={values.explanation}
                      onBlur={(newContent) =>
                        setFieldValue("explanation", newContent)
                      }
                      onChange={(newContent) =>
                        setFieldValue("explanation", newContent)
                      }
                      tabIndex={1}
                    />
                  </Form.Group>
                  <div className="mb-3 text-danger">
                    {errors.explanation &&
                      touched.explanation &&
                      errors.explanation}
                  </div>
                </Col>

                {values.explanation_image.length > 0 && (
                  <Col md={12} className="mb-3">
                    {values.explanation_image.map((img, imgIndex) => (
                      <div
                        className="image-area"
                        style={{ width: "90%" }}
                        key={imgIndex}
                      >
                        <img
                          src={`data:image/png;base64,${img}`}
                          alt={`Explanation ${imgIndex + 1}`}
                        />
                        <a
                          className="remove-image"
                          onClick={() => {
                            const updatedImages = [...values.explanation_image];
                            updatedImages.splice(imgIndex, 1);
                            setFieldValue("explanation_image", updatedImages);
                          }}
                          style={{ display: "inline" }}
                        >
                          &#215;
                        </a>
                      </div>
                    ))}
                  </Col>
                )}
              </Row>

              <div className="mt-3">
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  Update
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>

      <TabContext value={value}>
        <TabList style={tabListStyles} onChange={handleChange}>
          <Tab
            style={
              value === "1" ? { ...tabStyles, ...activeTabStyles } : tabStyles
            }
            label="Query"
            value="1"
          />
          <Tab
            style={
              value === "2" ? { ...tabStyles, ...activeTabStyles } : tabStyles
            }
            label="Mock Query"
            value="2"
          />
          <Tab
            style={
              value === "3" ? { ...tabStyles, ...activeTabStyles } : tabStyles
            }
            label="Quiz Query"
            value="3"
          />
          <Tab
            style={
              value === "4" ? { ...tabStyles, ...activeTabStyles } : tabStyles
            }
            label="Custom Query"
            value="4"
          />
        </TabList>
        <TabPanel value="1">
          <div>
            {isLoadingData ? (
              <div className="loader-container">
                <Image
                  className="loader-element animate__animated animate__jackInTheBox"
                  src={ReactLogo}
                  height={40}
                />
              </div>
            ) : (
              <MaterialReactTable
                autoResetPageIndex={false}
                columns={columns}
                data={jsonData}
                enableRowOrdering
                enableSorting={false}
                muiTableBodyRowDragHandleProps={({ table }) => ({
                  onDragEnd: () => {
                    const { draggingRow, hoveredRow } = table.getState();
                    if (hoveredRow && draggingRow) {
                      jsonData.splice(
                        hoveredRow.index,
                        0,
                        jsonData.splice(draggingRow.index, 1)[0]
                      );
                      setJsonData([...jsonData]);
                      console.log("new jsonData", jsonData);
                      let position_data = {
                        Coupon_id: [],
                        position: [],
                      };
                      jsonData.forEach((element, index) => {
                        position_data.Coupon_id.push(element._id);
                        position_data.position.push(index + 1);
                      });
                    }
                  },
                })}
              />
            )}
          </div>
        </TabPanel>
        <TabPanel value="2">
          <div>
            {isLoadingData ? (
              <div className="loader-container">
                <Image
                  className="loader-element animate__animated animate__jackInTheBox"
                  src={ReactLogo}
                  height={40}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <MaterialReactTable
            autoResetPageIndex={false}
            columns={columns2}
            data={jsonData}
            enableRowOrdering
            enableSorting={false}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  jsonData.splice(
                    hoveredRow.index,
                    0,
                    jsonData.splice(draggingRow.index, 1)[0]
                  );
                  setJsonData([...jsonData]);
                  console.log("new jsonData", jsonData);
                  let position_data = {
                    Offer_id: [],
                    position: [],
                  };
                  jsonData.forEach((element, index) => {
                    position_data.Offer_id.push(element._id);
                    position_data.position.push(index + 1);
                  });
                }
              },
            })}
          />
        </TabPanel>
        <TabPanel value="3">
          <div>
            {isLoadingData ? (
              <div className="loader-container">
                <Image
                  className="loader-element animate__animated animate__jackInTheBox"
                  src={ReactLogo}
                  height={40}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <MaterialReactTable
            autoResetPageIndex={false}
            columns={columns3}
            data={jsonData}
            enableRowOrdering
            enableSorting={false}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  jsonData.splice(
                    hoveredRow.index,
                    0,
                    jsonData.splice(draggingRow.index, 1)[0]
                  );
                  setJsonData([...jsonData]);
                  console.log("new jsonData", jsonData);
                  let position_data = {
                    Offer_id: [],
                    position: [],
                  };
                  jsonData.forEach((element, index) => {
                    position_data.Offer_id.push(element._id);
                    position_data.position.push(index + 1);
                  });
                }
              },
            })}
          />
        </TabPanel>
        <TabPanel value="4">
          <div>
            {isLoadingData ? (
              <div className="loader-container">
                <Image
                  className="loader-element animate__animated animate__jackInTheBox"
                  src={ReactLogo}
                  height={40}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <MaterialReactTable
            autoResetPageIndex={false}
            columns={columns4}
            data={jsonData}
            enableRowOrdering
            enableSorting={false}
            muiTableBodyRowDragHandleProps={({ table }) => ({
              onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                  jsonData.splice(
                    hoveredRow.index,
                    0,
                    jsonData.splice(draggingRow.index, 1)[0]
                  );
                  setJsonData([...jsonData]);
                  console.log("new jsonData", jsonData);
                  let position_data = {
                    Offer_id: [],
                    position: [],
                  };
                  jsonData.forEach((element, index) => {
                    position_data.Offer_id.push(element._id);
                    position_data.position.push(index + 1);
                  });
                }
              },
            })}
          />
        </TabPanel>
      </TabContext>
    </Card>
  );
};
