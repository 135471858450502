import React, { useState, useEffect, useMemo, useRef } from "react";
import { Col, Row, Button, Form as BootstrapForm } from "react-bootstrap";
import {
  //   Col,
  //   Row,
  Card,
  Form,
  //   Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { Formik, FieldArray, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import JoditEditor from "jodit-react";
import { Modal } from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import DownloadExcelButton from "./downloadExel";
import { MDBDataTable } from "mdbreact";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactLogo from "../assets/img/technologies/loading-loader-svgrepo-com.svg";
import { Image } from "@themesberg/react-bootstrap";
export const MockExam = ({ examId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSection, setIsSection] = useState(false);
  // console.log(isSection,"isSection%%%%%%%%%%%%%%%%%%%%%");
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [Editopen, setEditOpen] = useState(false);
  const onEditOpenModal = () => setEditOpen(true);
  const onEditCloseModal = () => setEditOpen(false);

  const history = useHistory();

  const navigateDetails = (userId) => {
    history.push(`/mockQuestionDetails?id=${userId}`);
  };
  const initialValues = {
    isSection: "false",
    sectionWise: [
      { section: "", time_duration: "00:00:00", NumberOfQuestions: "" },
    ],
  };
  const [myData, setMyData] = useState({
    exam_name: "",
    category_id: "",
    marks_awarded: "",
    marks_deducted: "",
    instruction: "",
    declarationTime: "",
    negative_marking: false,
    is_practice_mode: false,
    isDeclaration: false,
    isfeatured: false,
    time_duration: "00:00:00",
    attempt: "",
    fromtime: "",
    isSection: false,
    totime: "",
    examType: "Always Live",
    sectionWise: [
      {
        section: "",
        time_duration: "00:00:00",
        NumberOfQuestions: "",
      },
    ],
  });
  const [isError, setIsError] = useState("");
  const [categoryData, setMyCategoryData] = useState([]);
  const [mockEditSection, setMockEditSection] = useState([]);
  console.log(mockEditSection, "mockEditSectionmockEditSectionmockEditSection");
  const [jsonData, setJsonData] = useState([]);
  const [subcategoryData, setMysubCategoryData] = useState([]);
  const [topicsData, setTopicsData] = useState([]);
  const [examData, setExamData] = useState([]);
  const [editData, setEditData] = useState({});
  const [showMarksDeductedField, setShowMarksDeductedField] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");
  const [SubscriptionData, setMySubscriptionData] = useState([]);

  const editor = useRef(null);

  const [isLoadingData, setIsLoadingData] = useState(true);

  const handleNegativeMarkingChange = (e) => {
    const selectedValue = e.target.value;
    setShowMarksDeductedField(selectedValue === "true");
  };

  const createExam = async (values) => {
    const token = localStorage.getItem("token");
    const formattedTime = values.time_duration
      ? `${values.time_duration}`
      : "00:00:00";
    const fromtime = values.fromtime
      ? moment(values.fromtime).format("YYYY-MM-DD HH:mm:ss")
      : "";
    const totime = values.totime
      ? moment(values.totime).format("YYYY-MM-DD HH:mm:ss")
      : "";

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/MasterExam/create`,
        { ...values, time_duration: formattedTime, fromtime, totime },
        {
          headers: { Authorization: `${token}` },
        }
      );
      console.log(res.data, "******************");

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Exam has been Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getMyExamData();
      onCloseModal();
      setMyData({
        exam_name: "",
        category_id: "",
        marks_awarded: "",
        marks_deducted: "",
        instruction: "",
        negative_marking: false,
        is_practice_mode: false,
        time_duration: "00:00:00",
        isDeclaration: false,
        attempt: "",
        fromtime: "",
        totime: "",
        examType: "Always Live",
        isSection: "true" ? true : false,
        // sectionWise: [{ section: "", time_duration: "", NumberOfQuestions: "" }],
        sectionWise: [
          {
            section: "",
            time_duration: "00:00:00",
            NumberOfQuestions: "",
          },
        ],
      });
    } catch (error) {
      setIsError(error.response.data.err.message);
    }
  };

  const getMyCategoryData = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/MasterCategory/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      setMyCategoryData(res.data);
    } catch (error) {
      setIsError(error.response);
    }
  };

  // const getMyIsSectionData = async (id) => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/api/getSectionByExamId/${id}`,
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );
  //     console.log(res.data.sectionWise,"res.data.data.sectionWiseissection$$$$$$$$$$$$$");
  //     setMockEditSection(res.data.sectionWise);
  //   } catch (error) {
  //     setIsError(error.response);
  //   }
  // };
  // const getMyIsSectionData = async (id) => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/api/getSectionByExamId/${id}`,
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );
  //     console.log(res.data.sectionWise, "res.data.data.sectionWiseissection$$$$$$$$$$$$$");
  //     setMockEditSection(res.data.sectionWise);
  //   } catch (error) {
  //     console.error(error.response);
  //   }
  // };
  const getMyIsSectionData = async (id) => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getSectionByExamId/${id}`,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res.data, "resdata------");
      setEditData(prevData => ({
        ...prevData, // Copy all previous properties
        sectionWise: res.data.sectionWise // Update sectionWise with new data
      }));
      setMockEditSection(res.data.sectionWise);
      setIsLoading(false);
      if(res.status === 200){
        console.log("**-*-*-*-*-*-*-*-*-*-*-*--*-*-*-");
        setEditOpen(true);

      }
    } catch (error) {
      // console.error(error.response);
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (editData.isSection) {
  //     getMyIsSectionData(examId);
  //   }
  // }, [editData.isSection, examId]);
  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "No",
        field: "id",
        width: 270,
      },
      {
        label: "Exam Name",
        field: "name",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "category name",
        field: "category_name",
        width: 100,
      },
      {
        label: "marks awarded",
        field: "marks_awarded",
        width: 100,
      },
      {
        label: "marks deducted",
        field: "marks_deducted",
        width: 100,
      },
      {
        label: "time duration",
        field: "time_duration",
        width: 100,
      },
      {
        label: "Question Count",
        field: "QuestionCount",
        width: 100,
      },
      {
        label: "Action",
        field: "actions",
        width: 100,
      },
    ],
  });

  const getMyExamData = async () => {
    const token = localStorage.getItem("token");
    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/MasterExam/getAll`,
        {
          headers: { Authorization: token },
        }
      );

      const data = res.data?.map((item, index) => {
        return {
          id: index + 1,
          name: item.exam_name,
          category_name: item.category_name,
          subcategory_name: item.subcategory_name,
          topic_name: item.topic_name,
          marks_awarded: item.marks_awarded,
          marks_deducted: item.marks_deducted,
          time_duration: item.time_duration,
          QuestionCount: item.QuestionCount,
          actions: (
            <div>
              <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(item)} />

              <FontAwesomeIcon
                className="mx-3"
                icon={faTrash}
                onClick={() => handleDelete(item._id)}
              />

              <FontAwesomeIcon
                icon={faEye}
                onClick={() => navigateDetails(item._id)}
              />
            </div>
          ),
        };
      });
      setJsonData(res.data);
      setDatatable((prevState) => ({
        ...prevState,
        rows: data,
      }));
      setIsLoadingData(false);
    } catch (error) {
      setIsError(error.response);
      setIsLoadingData(false);
    }
  };

  const getMySubCategoryData = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Subcategory/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      setMysubCategoryData(res.data);
    } catch (error) {
      setIsError(error.response);
    }
  };

  const handleEdit = async (data) => {
    const fromtime = data.fromtime ? new Date(data.fromtime) : "";
    const totime = data.totime ? new Date(data.totime) : "";
    setEditData({
      ...data,
      fromtime,
      totime,
      examType: fromtime && totime ? "Time Period" : "Always Live",
      // isSection: "true" ? true : false,

      sectionWise: []
    });

    if (data.isSection) {
     await getMyIsSectionData(data._id);
    } else {
      setMockEditSection([]);
      setEditOpen(true);
    }
    
  };
  //  const handleEdit = (data) => {
  //   const fromtime = data.fromtime ? new Date(data.fromtime) : "";
  //   const totime = data.totime ? new Date(data.totime) : "";
  //   setEditData({
  //     ...data,
  //     fromtime,
  //     totime,
  //     examType: fromtime && totime ? "Time Period" : "Always Live",
  //   });
  //   console.log(data, "datadatadata***********");
  //   if (data.isSection) {
  //     getMyIsSectionData(data._id);
  //     setIsSection(true);
  //   } else {
  //     setMockEditSection([]);
  //     setIsSection(false);
  //   }
  //   setEditOpen(true);
  // };
  useEffect(() => {
    getMyCategoryData();
    // getMySubCategoryData();
    // getMyTopicsData();
    getMyExamData();
    // getMySubscriptionData();
  }, []);

  const SigninSchema = Yup.object().shape({
    exam_name: Yup.string().required("Exam name is Required"),
    marks_awarded: Yup.string().required("Marks Awarded is Required"),
    category_id: Yup.string().required("categoty is Required"),
    instruction: Yup.string().required("instruction is Required"),
    attempt: Yup.string().required("Attempt is Required"),
  });
  // const SigninSchema = Yup.object().shape({
  //   examType: Yup.string(),
  //   // fromtime: Yup.date().when('examType', {
  //   //   is: 'Time Period',
  //   //   then: Yup.date().required('From Datetime is required'),
  //   //   otherwise: Yup.date()
  //   // }),
  //   // totime: Yup.date().when('examType', {
  //   //   is: 'Time Period',
  //   //   then: Yup.date().required('To Datetime is required'),
  //   //   otherwise: Yup.date()
  //   // }),
  //   exam_name: Yup.string().required("Exam name is required"),
  //   category_id: Yup.string().required("Category is required"),

  //   attempt: Yup.number().required("Attempt is required").min(1),
  //   marks_awarded: Yup.number().required("Marks awarded is required").min(1),
  //   marks_deducted: Yup.number().when("negative_marking", {
  //     is: "true",
  //     then: Yup.number().required("Marks deducted is required").min(0),
  //   }),
  //   time_duration: Yup.string().required("Time duration is required"),
  //   instruction: Yup.string().required("instruction is required"),
  // });

  const EditSchema = Yup.object().shape({
    exam_name: Yup.string().required("Exam name is Required"),
    category_id: Yup.string().required("Please select Category"),
    instruction: Yup.string().required("instruction is Required"),
    attempt: Yup.string().required("Attempt is Required"),
  });

  // const SectionSchema = Yup.object().shape({
  //   sectionWise: Yup.array().of(
  //     Yup.object().shape({
  //       section: Yup.string().required("Required"),
  //       time_duration: Yup.string()
  //         .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, "Invalid time format")
  //         .test(
  //           "max-time",
  //           "Time duration cannot exceed 1 hour",
  //           function (value) {
  //             if (!value) return true;
  //             const [hours, minutes] = value.split(":").map(Number);
  //             return hours < 1 || (hours === 1 && minutes === 0);
  //           }
  //         ),
  //       NumberOfQuestions: Yup.number()
  //         .required("Required")
  //         .positive()
  //         .integer(),
  //     })
  //   ),
  // });
  const SectionSchema = Yup.object().shape({
    isSection: Yup.boolean(),
    sectionWise: Yup.array().of(
      Yup.object().shape({
        section: Yup.string().required("Section is required"),
        time_duration: Yup.string().required("Time duration is required"),
        NumberOfQuestions: Yup.string().required(
          "Number of questions is required"
        ),
      })
    ),
  });
  const updateExam = async (id, updatedData) => {
    try {
      const token = localStorage.getItem("token");
      let suffix =
        updatedData.time_duration.split(":").length === 3 ? "" : ":00";

      const formattedTime = updatedData.time_duration
        ? `${updatedData.time_duration + suffix}`
        : "00:00" + suffix;
      const fromtime = updatedData.fromtime
        ? moment(updatedData.fromtime).format("YYYY-MM-DD HH:mm:ss")
        : "";
      const totime = updatedData.totime
        ? moment(updatedData.totime).format("YYYY-MM-DD HH:mm:ss")
        : "";
      console.log(
        updatedData.isSection,
        "updatedData.isSectionupdatedData.isSection"
      );
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/updateMasterExam/${id}`,
        { ...updatedData, time_duration: formattedTime, fromtime, totime },
        {
          headers: { Authorization: token },
        }
      );
      console.log(res.data, "update!!!!!!!!!!!!!!!!!!!"); // Log the response or handle it as required.
      // Optionally, you can refetch the subcategory data after successful update.
      // getMySubCategoryData();
      onEditCloseModal(); // Close the modal after successful update.
      Swal.fire({
        position: "center",
        icon: "success",
        title: " Exam Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getMyExamData();
    } catch (error) {
      console.error(error.response); // Handle error responses
    }
  };

  const handleDelete = (id) => {
    // Show the confirmation dialog using SweetAlert
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with the delete operation
        try {
          const token = localStorage.getItem("token");
          const res = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/deleteMasterExam/${id}`,
            {
              headers: { Authorization: token },
            }
          );
          // Optionally, you can refresh the subcategory data after successful deletion.
          // getMySubCategoryData();
          // Show a success message using SweetAlert
          Swal.fire("Deleted!", "Exam has been deleted.", "success");
          getMyExamData();
        } catch (error) {
          console.error(error.response); // Handle error responses
          // Show an error message using SweetAlert
          Swal.fire("Error", "Failed to delete the Exam", "error");
        }
      }
    });
  };

  // Handle category selection change
  const handleCategoryChange = (categoryId) => {
    setSelectedCategoryId(categoryId);
  };

  // Filter subcategory options based on selected category
  const filteredSubcategories = subcategoryData.filter(
    (subcategory) => subcategory.category_id === selectedCategoryId
  );

  const handleSubcategoryChange = (Id) => {
    setSelectedSubCategoryId(Id);
  };

  // Assuming you have the topics data available as topicsData and the subcategories data as filteredSubcategories.

  // Function to filter the topics based on the selected subcategory.

  const filteredTopics = topicsData.filter(
    (topic) => topic.subcategory_id === selectedSubCategoryId
  );

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Mock Exam</h5>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <button className="btn btn-primary" onClick={onOpenModal}>
            Add Mock Exam
            <FontAwesomeIcon icon={faPlus} className="mx-2" />
          </button>
          <DownloadExcelButton jsonData={jsonData} fileName="exam" />
        </div>
        <Modal open={open} onClose={onCloseModal} center>
          <h2>Add Mock Exam</h2>
          <Formik
            // initialValues={{ examType: "false", myData }}
            initialValues={myData}
            validationSchema={SigninSchema}
            onSubmit={(values) => {
              createExam(values);
            }}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Form>
                {console.log("errors ---- ", errors)}
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>Exam Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Exam name"
                        name="exam_name"
                        value={values.exam_name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.exam_name &&
                        touched.exam_name &&
                        errors.exam_name}
                    </div>
                  </Col>

                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Category</Form.Label>
                      <Form.Select
                        name="category_id"
                        value={values.category_id}
                        onChange={(e) => {
                          handleCategoryChange(e.target.value);
                          handleChange(e);
                        }}
                      >
                        <option>Select Category</option>
                        {categoryData.map((item, index) => (
                          <option value={item._id}>{item.category_name}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.category_id &&
                        touched.category_id &&
                        errors.category_id}
                    </div>
                  </Col>
                </Row>
                <Row>
                  {/* <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Is Declarations</Form.Label>
                      <Form.Select
                        name="examType"
                        onChange={(e) => {
                          var selectedOption = e.target.value;
                          handleChange(e);
                          if (selectedOption === "true") {
                            setFieldValue("fromtime", null);
                            // setFieldValue("totime", null);
                          }
                        }}
                        onBlur={handleBlur}
                        value={values.examType}
                      >
                        <option value="false">False</option>
                        <option value="true">true</option>
                      </Form.Select>
                    </Form.Group>
                  </Col> */}
                  {/* <Col md={12} className="mb-3">
  <Form.Group id="gender">
    <Form.Label>Is Declarations</Form.Label>
    <Form.Select
      name="examType"
      onChange={(e) => {
        var selectedOption = e.target.value;
        handleChange(e);
        if (selectedOption === "true") {
          setFieldValue("fromtime", null);
          // setFieldValue("totime", null);
          setFieldValue("isDeclaration", true);  // Set isDeclaration to true
        } else {
          setFieldValue("isDeclaration", false); // Set isDeclaration to false
        }
      }}
      onBlur={handleBlur}
      value={values.examType}
    >
      <option value="false">False</option>
      <option value="true">True</option>
    </Form.Select>
  </Form.Group>
</Col>


                  {values.examType === "true" && (
                    <Row>
                    
                      <Col md={12} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>Declarations Datetime</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Enter Declarations Datetime"
                        name="fromDate"
                        value={values.DeclarationsDatetime}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.DeclarationsDatetime &&
                        touched.DeclarationsDatetime &&
                        errors.DeclarationsDatetime}
                    </div>
                  </Col>
                    </Row>
                  )} */}
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Is Declarations</Form.Label>
                      <Form.Select
                        name="examType"
                        onChange={(e) => {
                          const selectedOption = e.target.value === "true";
                          handleChange(e);
                          setFieldValue("isDeclaration", selectedOption); // Set isDeclaration based on selectedOption
                        }}
                        onBlur={handleBlur}
                        value={values.isDeclaration ? "true" : "false"} // Ensure correct initial value
                      >
                        <option value="false">False</option>
                        <option value="true">True</option>
                      </Form.Select>
                    </Form.Group>

                    {values.isDeclaration && (
                      <Row>
                        <Col md={12} className="mb-3">
                          <Form.Group id="declarationTime">
                            <Form.Label>Declarations Datetime</Form.Label>
                            <Form.Control
                              type="datetime-local"
                              placeholder="Enter Declarations Datetime"
                              name="declarationTime"
                              value={values.declarationTime}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Form.Group>
                          <div className="mb-3 text-danger">
                            {errors.declarationTime &&
                              touched.declarationTime &&
                              errors.declarationTime}
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Col>

                  <Col md={12} className="mb-3">
                    <Form.Group id="phone">
                      <Form.Label>Attempt</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="Attempt"
                        name="attempt"
                        value={values.attempt}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.attempt && touched.attempt && errors.attempt}
                    </div>
                  </Col>
                </Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="gender">
                    <Form.Label>Exam Type</Form.Label>
                    <Form.Select
                      name="examType"
                      onChange={(e) => {
                        var selectedOption = e.target.value;
                        handleChange(e);
                        if (selectedOption === "Always Live") {
                          setFieldValue("fromtime", null);
                          setFieldValue("totime", null);
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.examType}
                    >
                      <option value="Always Live">Always Live</option>
                      <option value="Time Period">Time Period</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                {values.examType === "Time Period" && (
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group id="fromdate" className="d-flex flex-column">
                        <Form.Label>From Datetime</Form.Label>
                        <DatePicker
                          onChange={(date) => {
                            setFieldValue("fromtime", date);
                          }}
                          showTimeSelect
                          selected={values.fromtime}
                          dateFormat="MM/dd/yyyy HH:mm"
                        />
                      </Form.Group>
                      <div className="mb-3 text-danger">
                        {errors.fromtime && touched.fromtime && errors.fromtime}
                      </div>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="todate" className="d-flex flex-column">
                        <Form.Label>To Datetime</Form.Label>
                        <DatePicker
                          onChange={(date) => {
                            setFieldValue("totime", date);
                          }}
                          showTimeSelect
                          selected={values.totime}
                          dateFormat="MM/dd/yyyy HH:mm"
                        />
                      </Form.Group>
                      <div className="mb-3 text-danger">
                        {errors.totime && touched.totime && errors.totime}
                      </div>
                    </Col>
                  </Row>
                )}
                {/* <Col md={12} className="mb-3">
                                    <Form.Group id="gender">
                                        <Form.Label>is declarations</Form.Label>
                                        <Form.Select
                                            name="declarations"
                                            onChange={(e) => {
                                                var selectedOption = e.target.value;
                                                handleChange(e);
                                                if (selectedOption === "false") {
                                                    setFieldValue("false", null);
                                                    setFieldValue("true", null);
                                                }
                                            }}
                                            onBlur={handleBlur}
                                            value={values.declarations}
                                        >
                                            <option value="Always Live">false</option>
                                            <option value="Time Period">true</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col> */}

                {/*                                
                                {values.declarations === "true" && (
                                    <Row>
                                        <Col md={6} className="mb-3">
                                            <Form.Group id="fromdate" className="d-flex flex-column">
                                                <Form.Label>>From Datetime</Form.Label>
                                                <DatePicker
                                                    onChange={(date) => {
                                                        setFieldValue("fromtime", date);
                                                    }}
                                                    showTimeSelect
                                                    selected={values.fromtime}
                                                    dateFormat="MM/dd/yyyy HH:mm"
                                                />
                                            </Form.Group>
                                            <div className="mb-3 text-danger">
                                                {errors.fromtime && touched.fromtime && errors.fromtime}
                                            </div>
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <Form.Group id="todate" className="d-flex flex-column">
                                                <Form.Label>To Datetime</Form.Label>
                                                <DatePicker
                                                    onChange={(date) => {
                                                        setFieldValue("totime", date);
                                                    }}
                                                    showTimeSelect
                                                    selected={values.totime}
                                                    dateFormat="MM/dd/yyyy HH:mm"
                                                />
                                            </Form.Group>
                                            <div className="mb-3 text-danger">
                                                {errors.totime && touched.totime && errors.totime}
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                )} */}
                {/* <Col md={12} className="mb-3">
                  <Form.Group id="gender">
                    <Form.Label>is declaration</Form.Label>
                    <Form.Select
                      name="examType"
                      onChange={(e) => {
                        var selectedOption = e.target.value;
                        handleChange(e);
                        if (selectedOption === "true") {
                          setFieldValue("fromtime", null);
                        //   setFieldValue("totime", null);
                        }
                      }}    
                      onBlur={handleBlur}
                      value={values.examType}
                    >
                      <option value="false">false</option>
                      <option value="ture">True</option>
                      
                    </Form.Select>
                  </Form.Group>
                </Col>
                 */}
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="phone">
                      <Form.Label>Marks Awarded per Question</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Marks Awarded Per Question"
                        name="marks_awarded"
                        value={values.marks_awarded}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.marks_awarded &&
                        touched.marks_awarded &&
                        errors.marks_awarded}
                    </div>
                  </Col>
                  {/* 
                                    <Col md={12} className="mb-3">
                                        <Form.Label>Instruction</Form.Label>
                                        <textarea
                                            class="form-control"
                                            id="instruction"
                                            rows="6"
                                            placeholder="Enter your instruction"
                                            name="instruction"
                                            value={values.instruction}
                                            onChange={handleChange}
                                        ></textarea>
                                        <div className="mb-3 text-danger">
                                            {errors.instruction &&
                                                touched.instruction &&
                                                errors.instruction}
                                        </div>
                                    </Col>
                                     */}
                  <div class="form-group">
                    <label for="instruction">Instruction</label>
                    <textarea
                      class="form-control"
                      id="instruction"
                      rows="12"
                      placeholder="Enter your instruction"
                      name="instruction"
                      value={values.instruction}
                      onChange={handleChange}
                    ></textarea>
                    {/* <JoditEditor
                        ref={editor}
                        value={values.instruction}
                        onChange={handleChange}
                        tabIndex={1} // tabIndex of textarea 
                        
                      /> */}
                  </div>
                </Row>
                <Row className="align-items-center">
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Negative Marking</Form.Label>
                      <Form.Select
                        defaultValue="false"
                        name="negative_marking"
                        value={values.negative_marking}
                        onChange={(e) => {
                          handleChange(e);
                          handleNegativeMarkingChange(e);
                        }}
                      >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {showMarksDeductedField && (
                    <Col md={12} className="mb-3">
                      <Form.Group id="emal">
                        <Form.Label>Marks Deducted</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Marks Deducted Per Question"
                          name="marks_deducted"
                          value={values.marks_deducted}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  )}
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Has Practice Mode?</Form.Label>
                      <Form.Select
                        defaultValue="false"
                        name="is_practice_mode"
                        value={values.is_practice_mode}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="timePicker">
                      <Form.Label>Time Duration</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faClock} />
                        </InputGroup.Text>
                        <TimePicker
                          disableClock={true}
                          clearIcon={null}
                          value={values.time_duration}
                          onChange={(newTime) => {
                            setFieldValue("time_duration", newTime);
                          }}
                          format="HH:mm:ss"
                          maxDetail="second"
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col>
                    <row></row>
                    <div class="form-check mb-3">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={values.isPublic}
                        id="flexCheckPublic"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("isPublic", e.target.checked);
                        }}
                      />
                      <label class="form-check-label" for="flexCheckPublic">
                        isPublic
                      </label>
                    </div>
                  </Col>
                  <Col>
                    <Row></Row>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={values.isSection}
                        id="flexCheckPublic"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("isSection", e.target.checked);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckPublic"
                      >
                        isSection
                      </label>
                    </div>
                  </Col>
                  {values.isSection && (
                    <FieldArray name="sectionWise">
                      {({ push, remove }) => (
                        <>
                          {values.sectionWise.map((item, index) => (
                            <div key={index}>
                              <Row>
                                <Col md={3} className="mb-3">
                                  <BootstrapForm.Group>
                                    <BootstrapForm.Label>
                                      Section {String.fromCharCode(65 + index)}
                                    </BootstrapForm.Label>
                                    <BootstrapForm.Control
                                      type="text"
                                      placeholder="Enter section"
                                      name={`sectionWise[${index}].section`}
                                      value={item.section}
                                      onChange={handleChange}
                                      isInvalid={
                                        touched.sectionWise?.[index]?.section &&
                                        !!errors.sectionWise?.[index]?.section
                                      }
                                    />
                                    <BootstrapForm.Control.Feedback type="invalid">
                                      {errors.sectionWise?.[index]?.section}
                                    </BootstrapForm.Control.Feedback>
                                  </BootstrapForm.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                  <BootstrapForm.Group id="timePicker">
                                    <BootstrapForm.Label>
                                      Time Duration
                                    </BootstrapForm.Label>
                                    <InputGroup>
                                      <InputGroup.Text>
                                        <FontAwesomeIcon icon={faClock} />
                                      </InputGroup.Text>
                                      <TimePicker
                                        disableClock={true}
                                        clearIcon={null}
                                        value={item.time_duration}
                                        onChange={(newTime) => {
                                          setFieldValue(
                                            `sectionWise[${index}].time_duration`,
                                            newTime
                                          );
                                        }}
                                        format="HH:mm:ss"
                                        maxDetail="second"
                                      />
                                    </InputGroup>
                                    {touched.sectionWise?.[index]
                                      ?.time_duration &&
                                      errors.sectionWise?.[index]
                                        ?.time_duration && (
                                        <div className="invalid-feedback d-block">
                                          {
                                            errors.sectionWise?.[index]
                                              ?.time_duration
                                          }
                                        </div>
                                      )}
                                  </BootstrapForm.Group>
                                </Col>
                                <Col md={4} className="mb-3">
                                  <BootstrapForm.Group>
                                    <BootstrapForm.Label>
                                      Number Of Questions {index + 1}
                                    </BootstrapForm.Label>
                                    <BootstrapForm.Control
                                      type="text"
                                      placeholder="Enter Number Of Questions"
                                      name={`sectionWise[${index}].NumberOfQuestions`}
                                      value={item.NumberOfQuestions}
                                      onChange={handleChange}
                                      isInvalid={
                                        touched.sectionWise?.[index]
                                          ?.NumberOfQuestions &&
                                        !!errors.sectionWise?.[index]
                                          ?.NumberOfQuestions
                                      }
                                    />
                                    <BootstrapForm.Control.Feedback type="invalid">
                                      {
                                        errors.sectionWise?.[index]
                                          ?.NumberOfQuestions
                                      }
                                    </BootstrapForm.Control.Feedback>
                                  </BootstrapForm.Group>
                                </Col>
                                <Col md={2} className="mb-3 align-self-end">
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          ))}
                          <Row>
                            <Col md={12} className="mb-3">
                              <Button
                                variant="primary"
                                onClick={() =>
                                  push({
                                    section: "",
                                    time_duration: "00:00:00",
                                    NumberOfQuestions: "",
                                  })
                                }
                              >
                                Add Section
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    </FieldArray>
                  )}
                </Row>

                <Formik
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    console.log(values);
                  }}
                >
                  {/* <Col>
                      <Form.Check
                        type="checkbox"
                       id="viewall"
                        label="View All"
                        checked={values.viewall}
                        onChange={(e) => {
                          setFieldValue("Neet_SS", e.target.checked);
                          setFieldValue("INISS_ET", e.target.checked);
                        }}
                      />
                    </Col>

<Col>
                      <Form.Check
                        type="checkbox"
                        id="Neet_SS"
                        label="Neet_SS"
                        checked={values.Neet_SS}
                        onChange={(e) => {
                          setFieldValue("Neet_SS", e.target.checked);
                        }}
                      />
                    </Col> */}

                  {/* <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="Neet_SS"
                                                    label="Neet_SS"
                                                    checked={values.Neet_SS}
                                                    onChange={(e) => {
                                                        setFieldValue("Neet_SS", e.target.checked);
                                                        handleVideoSelect(editData.tempUIDataVideos);
                                                        handleNotesSelect(editData.tempUIDataNotes);
                                                        handleExamSelect(editData.tempUIDataExam);
                                                    }}
                                                    disabled={isDisabled}
                                                />
                                            </Col> */}

                  {/* {({ values, handleChange, handleBlur, setFieldValue }) => (
                    <Form>
                      <Col md={12} className="mb-3">
                        <BootstrapForm.Group id="gender">
                          <BootstrapForm.Label>isSection</BootstrapForm.Label>
                          <BootstrapForm.Select
                            name="examType"
                            onChange={(e) => {
                              const selectedOption = e.target.value;
                              handleChange(e);
                              if (selectedOption === "true") {
                                setFieldValue("fromtime", null);
                                // setFieldValue('totime', null);
                                setIsSection(true);
                              } else {
                                setIsSection(false);
                              }
                            }}
                            onBlur={handleBlur}
                            value={values.examType}
                          >
                            <option value="false">False</option>
                            <option value="true">True</option>
                          </BootstrapForm.Select>
                        </BootstrapForm.Group>
                      </Col>

                      {isSection && (
                        <FieldArray name="sectionWise">
                          {({ push, remove }) => (
                            <>
                              {values.sectionWise.map((item, index) => (
                                <div key={index}>
                                  <Row>
                                    <Col md={4} className="mb-3">
                                      <BootstrapForm.Group>
                                        <BootstrapForm.Label>
                                          Section {index + 1}
                                        </BootstrapForm.Label>
                                        <BootstrapForm.Control
                                          type="text"
                                          placeholder="Enter section"
                                          name={`sectionWise[${index}].section`}
                                          value={item.section}
                                          onChange={handleChange}
                                        />
                                      </BootstrapForm.Group>
                                    </Col>
                                   
                                    <Col md={4} className="mb-3">
                                      <Form.Group id="timePicker">
                                        <Form.Label>Time Duration</Form.Label>
                                        <InputGroup>
                                          <InputGroup.Text>
                                            <FontAwesomeIcon icon={faClock} />
                                          </InputGroup.Text>
                                          <TimePicker
                                            disableClock={true}
                                            clearIcon={null}
                                            value={values.time_duration}
                                            onChange={(newTime) => {
                                              setFieldValue(
                                                "time_duration",
                                                newTime
                                              );
                                            }}
                                            format="HH:mm"
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </Col>
                                    <Col md={3} className="mb-3">
                                      <BootstrapForm.Group>
                                        <BootstrapForm.Label>
                                          Number Of Questions {index + 1}
                                        </BootstrapForm.Label>
                                        <BootstrapForm.Control
                                          type="text"
                                          placeholder="Enter NumberOfQuestions"
                                          name={`sectionWise[${index}].NumberOfQuestions`}
                                          value={item.NumberOfQuestions}
                                          onChange={handleChange}
                                        />
                                      </BootstrapForm.Group>
                                    </Col>
                                    <Col md={1} className="mb-3 align-self-end">
                                      <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={() => remove(index)}
                                      >
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              ))}

                              <Row>
                                <Col md={12} className="mb-3">
                                  <Button
                                    variant="primary"
                                    onClick={() =>
                                      push({
                                        section: String.fromCharCode(
                                          65 + values.sectionWise.length
                                        ),
                                        time_duration: "",
                                        NumberOfQuestions: "",
                                      })
                                    }
                                  >
                                    Add sectionWise
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          )}
                        </FieldArray>
                      )}
                    </Form>
                  )} */}

                  {/* <Formik
      initialValues={{
        examType: 'false',
        // sectionWise: [{ section: 'A', time_duration: '', NumberOfQuestions: '' }],
        sectionWise: [
          {
              section: "",
              time_duration: "",
              NumberOfQuestions: "",
          },
        ]
      }}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ values, handleChange, handleBlur, setFieldValue }) => (
        <Form>
          <Col md={12} className="mb-3">
            <BootstrapForm.Group id="gender">
              <BootstrapForm.Label>isSection</BootstrapForm.Label>
              <BootstrapForm.Select
                name="examType"
                onChange={(e) => {
                  const selectedOption = e.target.value;
                  handleChange(e);
                  if (selectedOption === 'true') {
                    setFieldValue('fromtime', null);
                    setIsSection(true);
                    if (values.sectionWise.length === 0) {
                      setFieldValue('sectionWise', [{ section: 'A', time_duration: '', NumberOfQuestions: '' }]);
                    }
                  } else {
                    setIsSection(false);
                    setFieldValue('sectionWise', []);
                  }
                }}
                onBlur={handleBlur}
                value={values.examType}
              >
                <option value="false">False</option>
                <option value="true">True</option>
              </BootstrapForm.Select>
            </BootstrapForm.Group>
          </Col>

          {isSection && (
            <FieldArray name="sectionWise">
              {({ push, remove }) => (
                <>
                  {values.sectionWise.map((item, index) => (
                    <div key={index}>
                      <Row>
                        <Col md={4} className="mb-3">
                          <BootstrapForm.Group>
                            <BootstrapForm.Label>Section {String.fromCharCode(65 + index)}</BootstrapForm.Label>
                            <BootstrapForm.Control
                              type="text"
                              placeholder="Enter section"
                              name={`sectionWise[${index}].section`}
                              value={item.section}
                              onChange={handleChange}
                            />
                          </BootstrapForm.Group>
                        </Col>
                        <Col md={4} className="mb-3">
                          <BootstrapForm.Group id="timePicker">
                            <BootstrapForm.Label>Time Duration</BootstrapForm.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faClock} />
                              </InputGroup.Text>
                              <TimePicker
                                disableClock={true}
                                clearIcon={null}
                                value={item.time_duration}
                                onChange={(newTime) => {
                                  setFieldValue(`sectionWise[${index}].time_duration`, newTime);
                                }}
                                format="HH:mm"
                              />
                            </InputGroup>
                          </BootstrapForm.Group>
                        </Col>
                        <Col md={3} className="mb-3">
                          <BootstrapForm.Group>
                            <BootstrapForm.Label>Number Of Questions {index + 1}</BootstrapForm.Label>
                            <BootstrapForm.Control
                              type="text"
                              placeholder="Enter Number Of Questions"
                              name={`sectionWise[${index}].NumberOfQuestions`}
                              value={item.NumberOfQuestions}
                              onChange={handleChange}
                            />
                          </BootstrapForm.Group>
                        </Col>
                        <Col md={1} className="mb-3 align-self-end">
                          <Button variant="danger" size="sm" onClick={() => remove(index)}>
                            Remove
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <Row>
                    <Col md={12} className="mb-3">
                      <Button
                        variant="primary"
                        onClick={() =>
                          push({
                            // section: String.fromCharCode(65 + values.sectionWise.length),
                            section:"",
                            time_duration: "",
                            NumberOfQuestions: "",
                          })
                        }
                      >
                        Add Section
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </FieldArray>
          )}

          
        </Form>
      )}
    </Formik> */}

                  {/* <Formik
                    initialValues={{
                      isSection: "false",
                      sectionWise: [
                        {
                          section: "",
                          time_duration: "",
                          NumberOfQuestions: "",
                        },
                      ],
                    }}
                    validationSchema={SectionSchema}
                    onSubmit={(values) => {
                      console.log(values);
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      errors,
                      touched,
                    }) => (
                      <BootstrapForm>
                        <Col md={12} className="mb-3">
                          <BootstrapForm.Group id="gender">
                            <BootstrapForm.Label>isSection</BootstrapForm.Label>
                            <BootstrapForm.Select
                              name="isSection"
                              onChange={(e) => {
                                const selectedOption = e.target.value;
                                handleChange(e);
                                if (selectedOption === "true") {
                                  setFieldValue("fromtime", null);
                                  setIsSection(true);
                                  if (values.sectionWise.length === 0) {
                                    setFieldValue("sectionWise", [
                                      {
                                        section: "A",
                                        time_duration: "",
                                        NumberOfQuestions: "",
                                      },
                                    ]);
                                  }
                                } else {
                                  setIsSection(false);
                                  setFieldValue("sectionWise", []);
                                }
                              }}
                              onBlur={handleBlur}
                              value={values.isSection}
                            >
                              <option value="false">False</option>
                              <option value="true">True</option>
                            </BootstrapForm.Select>
                          </BootstrapForm.Group>
                        </Col>

                        {isSection && (
                          <FieldArray name="sectionWise">
                            {({ push, remove }) => (
                              <>
                                {values.sectionWise.map((item, index) => (
                                  <div key={index}>
                                    <Row>
                                      <Col md={4} className="mb-3">
                                        <BootstrapForm.Group>
                                          <BootstrapForm.Label>
                                            Section{" "}
                                            {String.fromCharCode(65 + index)}
                                          </BootstrapForm.Label>
                                          <BootstrapForm.Control
                                            type="text"
                                            placeholder="Enter section"
                                            name={`sectionWise[${index}].section`}
                                            value={item.section}
                                            onChange={handleChange}
                                            isInvalid={
                                              touched.sectionWise?.[index]
                                                ?.section &&
                                              !!errors.sectionWise?.[index]
                                                ?.section
                                            }
                                          />
                                          <BootstrapForm.Control.Feedback type="invalid">
                                            {
                                              errors.sectionWise?.[index]
                                                ?.section
                                            }
                                          </BootstrapForm.Control.Feedback>
                                        </BootstrapForm.Group>
                                      </Col>
                                      <Col md={4} className="mb-3">
                                        <BootstrapForm.Group id="timePicker">
                                          <BootstrapForm.Label>
                                            Time Duration
                                          </BootstrapForm.Label>
                                          <InputGroup>
                                            <InputGroup.Text>
                                              <FontAwesomeIcon icon={faClock} />
                                            </InputGroup.Text>
                                            <TimePicker
                                              disableClock={true}
                                              clearIcon={null}
                                              value={item.time_duration}
                                              onChange={(newTime) => {
                                                setFieldValue(
                                                  `sectionWise[${index}].time_duration`,
                                                  newTime
                                                );
                                              }}
                                              format="HH:mm"
                                            />
                                          </InputGroup>
                                          {touched.sectionWise?.[index]
                                            ?.time_duration &&
                                            errors.sectionWise?.[index]
                                              ?.time_duration && (
                                              <div className="invalid-feedback d-block">
                                                {
                                                  errors.sectionWise?.[index]
                                                    ?.time_duration
                                                }
                                              </div>
                                            )}
                                        </BootstrapForm.Group>
                                      </Col>
                                      <Col md={3} className="mb-3">
                                        <BootstrapForm.Group>
                                          <BootstrapForm.Label>
                                            Number Of Questions {index + 1}
                                          </BootstrapForm.Label>
                                          <BootstrapForm.Control
                                            type="text"
                                            placeholder="Enter Number Of Questions"
                                            name={`sectionWise[${index}].NumberOfQuestions`}
                                            value={item.NumberOfQuestions}
                                            onChange={handleChange}
                                            isInvalid={
                                              touched.sectionWise?.[index]
                                                ?.NumberOfQuestions &&
                                              !!errors.sectionWise?.[index]
                                                ?.NumberOfQuestions
                                            }
                                          />
                                          <BootstrapForm.Control.Feedback type="invalid">
                                            {
                                              errors.sectionWise?.[index]
                                                ?.NumberOfQuestions
                                            }
                                          </BootstrapForm.Control.Feedback>
                                        </BootstrapForm.Group>
                                      </Col>
                                      <Col
                                        md={1}
                                        className="mb-3 align-self-end"
                                      >
                                        <Button
                                          variant="danger"
                                          size="sm"
                                          onClick={() => remove(index)}
                                        >
                                          Remove
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}
                                <Row>
                                  <Col md={12} className="mb-3">
                                    <Button
                                      variant="primary"
                                      onClick={() =>
                                        push({
                                          section: "",
                                          time_duration: "",
                                          NumberOfQuestions: "",
                                        })
                                      }
                                    >
                                      Add Section
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </FieldArray>
                        )}
                      </BootstrapForm>
                    )}
                  </Formik> */}

                  {/* 
                  <Formik
  initialValues={{
    isSection: "false",
    sectionWise: [
      {
        section: "",
        time_duration: "",
        NumberOfQuestions: "",
      },
    ],
  }}
  validationSchema={SectionSchema}
  onSubmit={(values) => {
    console.log(values);
  }}
>
  {({
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    touched,
  }) => (
    <BootstrapForm>
      <Col md={12} className="mb-3">
        <BootstrapForm.Group id="gender">
          <BootstrapForm.Label>isSection</BootstrapForm.Label>
          <BootstrapForm.Select
            name="isSection"
            onChange={(e) => {
              const selectedOption = e.target.value;
              handleChange(e);
              if (selectedOption === true) {
                setFieldValue("isSection", selectedOption); // Update isSection field in values
                setFieldValue("fromtime", null);
                setFieldValue("sectionWise", [
                  {
                    section: "A",
                    time_duration: "",
                    NumberOfQuestions: "",
                  },
                ]);
              } else {
                setFieldValue("isSection", selectedOption); // Update isSection field in values
                setFieldValue("sectionWise", []);
              }
            }}
            onBlur={handleBlur}
            value={values.isSection}
          >
            <option value="false">False</option>
            <option value="true">True</option>
          </BootstrapForm.Select>
        </BootstrapForm.Group>
      </Col>

      {values.isSection === "true" && (
        <FieldArray name="sectionWise">
          {({ push, remove }) => (
            <>
              {values.sectionWise.map((item, index) => (
                <div key={index}>
                  <Row>
                    <Col md={4} className="mb-3">
                      <BootstrapForm.Group>
                        <BootstrapForm.Label>
                          Section {String.fromCharCode(65 + index)}
                        </BootstrapForm.Label>
                        <BootstrapForm.Control
                          type="text"
                          placeholder="Enter section"
                          name={`sectionWise[${index}].section`}
                          value={item.section}
                          onChange={handleChange}
                          isInvalid={
                            touched.sectionWise?.[index]?.section &&
                            !!errors.sectionWise?.[index]?.section
                          }
                        />
                        <BootstrapForm.Control.Feedback type="invalid">
                          {errors.sectionWise?.[index]?.section}
                        </BootstrapForm.Control.Feedback>
                      </BootstrapForm.Group>
                    </Col>
                    <Col md={4} className="mb-3">
                      <BootstrapForm.Group id="timePicker">
                        <BootstrapForm.Label>Time Duration</BootstrapForm.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faClock} />
                          </InputGroup.Text>
                          <TimePicker
                            disableClock={true}
                            clearIcon={null}
                            value={item.time_duration}
                            onChange={(newTime) => {
                              setFieldValue(
                                `sectionWise[${index}].time_duration`,
                                newTime
                              );
                            }}
                            format="HH:mm"
                          />
                        </InputGroup>
                        {touched.sectionWise?.[index]?.time_duration &&
                          errors.sectionWise?.[index]?.time_duration && (
                            <div className="invalid-feedback d-block">
                              {errors.sectionWise?.[index]?.time_duration}
                            </div>
                          )}
                      </BootstrapForm.Group>
                    </Col>
                    <Col md={3} className="mb-3">
                      <BootstrapForm.Group>
                        <BootstrapForm.Label>
                          Number Of Questions {index + 1}
                        </BootstrapForm.Label>
                        <BootstrapForm.Control
                          type="text"
                          placeholder="Enter Number Of Questions"
                          name={`sectionWise[${index}].NumberOfQuestions`}
                          value={item.NumberOfQuestions}
                          onChange={handleChange}
                          isInvalid={
                            touched.sectionWise?.[index]?.NumberOfQuestions &&
                            !!errors.sectionWise?.[index]?.NumberOfQuestions
                          }
                        />
                        <BootstrapForm.Control.Feedback type="invalid">
                          {errors.sectionWise?.[index]?.NumberOfQuestions}
                        </BootstrapForm.Control.Feedback>
                      </BootstrapForm.Group>
                    </Col>
                    <Col md={1} className="mb-3 align-self-end">
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => remove(index)}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}
              <Row>
                <Col md={12} className="mb-3">
                  <Button
                    variant="primary"
                    onClick={() =>
                      push({
                        section: "",
                        time_duration: "",
                        NumberOfQuestions: "",
                      })
                    }
                  >
                    Add Section
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </FieldArray>
      )}
    </BootstrapForm>
  )}
</Formik> */}
                </Formik>

                <div className="mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>

        <Modal open={Editopen} onClose={onEditCloseModal} center>
          <h2>Edit Mock Exam</h2>
          <Formik
            // initialValues={editData}
            initialValues={{...editData,
    sectionWise: editData.sectionWise || [], // Initialize sectionWise based on editData
  }}
            //         initialValues={{
            //   ...editData,
            //   // isSection: editData.isSection || false,
            //   // sectionWise: editData.sectionWise || [],
            //    sectionWise: [
            //   {
            //     section: "",
            //     time_duration: "00:00:00",
            //     NumberOfQuestions: "",
            //   },
            // ],
            // }}
            validationSchema={EditSchema}
            onSubmit={(values) => {
              updateExam(editData._id, values);
              // Pass the ID and updated data to updateExam
            }}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form>
                <Row>
                  <Col md={12} className="mb-3">
                    {console.log(editData, "data*-*-*-*-*-*")}
                    <Form.Group id="firstName">
                      <Form.Label>Exam Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Exam name"
                        name="exam_name"
                        value={values.exam_name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.exam_name &&
                        touched.exam_name &&
                        errors.exam_name}
                    </div>
                  </Col>

                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Category</Form.Label>
                      <Form.Select
                        name="category_id"
                        value={values.category_id}
                        onChange={(e) => {
                          handleCategoryChange(e.target.value);
                          handleChange(e);
                        }}
                      >
                        <option>Select Category</option>
                        {categoryData.map((item, index) => (
                          <option value={item._id}>{item.category_name}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="phone">
                      <Form.Label>Attempt</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="Attempt"
                        name="attempt"
                        value={values.attempt}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.attempt && touched.attempt && errors.attempt}
                    </div>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Is Declarations</Form.Label>
                      <Form.Select
                        name="examType"
                        onChange={(e) => {
                          const selectedOption = e.target.value === "true";
                          handleChange(e);
                          setFieldValue("isDeclaration", selectedOption); // Set isDeclaration based on selectedOption
                        }}
                        onBlur={handleBlur}
                        value={values.isDeclaration ? "true" : "false"} // Ensure correct initial value
                      >
                        <option value="false">False</option>
                        <option value="true">True</option>
                      </Form.Select>
                    </Form.Group>

                    {values.isDeclaration && (
                      <Row>
                        <Col md={12} className="mb-3">
                          <Form.Group id="declarationTime">
                            <Form.Label>Declarations Datetime</Form.Label>
                            <Form.Control
                              type="datetime-local"
                              placeholder="Enter Declarations Datetime"
                              name="declarationTime"
                              value={values.declarationTime}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Form.Group>
                          <div className="mb-3 text-danger">
                            {errors.declarationTime &&
                              touched.declarationTime &&
                              errors.declarationTime}
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Col>

                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Exam Type</Form.Label>
                      <Form.Select
                        name="examType"
                        onChange={(e) => {
                          var selectedOption = e.target.value;
                          handleChange(e);
                          setFieldValue("fromtime", "");
                          setFieldValue("totime", "");
                        }}
                        value={values.examType}
                        onBlur={handleBlur}
                        // Set the value attribute based on the presence of values in fromtime and totime
                        // value={
                        //   values.fromtime && values.totime && values.fromtime != "" && values.totime != ""
                        //     ? "Time Period"
                        //     : "Always Live"
                        // }
                      >
                        <option value="Always Live">Always Live</option>
                        <option value="Time Period">Time Period</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {/* <p>{values.examType}</p> */}
                  {values.examType && values.examType === "Time Period" ? (
                    <Row>
                      <Col md={6} className="mb-3">
                        <Form.Group
                          id="fromdate"
                          className="d-flex flex-column"
                        >
                          <Form.Label>From Datetime</Form.Label>
                          <DatePicker
                            onChange={(date) => {
                              setFieldValue("fromtime", date);
                            }}
                            showTimeSelect
                            selected={values.fromtime}
                            dateFormat="MM/dd/yyyy HH:mm"
                          />
                        </Form.Group>
                        <div className="mb-3 text-danger">
                          {errors.fromtime &&
                            touched.fromtime &&
                            errors.fromtime}
                        </div>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Group id="todate" className="d-flex flex-column">
                          <Form.Label>To Datetime</Form.Label>
                          <DatePicker
                            onChange={(date) => {
                              setFieldValue("totime", date);
                            }}
                            showTimeSelect
                            selected={values.totime}
                            dateFormat="MM/dd/yyyy HH:mm"
                          />
                        </Form.Group>
                        <div className="mb-3 text-danger">
                          {errors.totime && touched.totime && errors.totime}
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                </Row>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="phone">
                      <Form.Label>Marks Awarded per Question</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Marks Awarded Per Question"
                        name="marks_awarded"
                        value={values.marks_awarded}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.marks_awarded &&
                        touched.marks_awarded &&
                        errors.marks_awarded}
                    </div>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="phone">
                      <Form.Label>Instruction</Form.Label>
                      <textarea
                        class="form-control"
                        id="instruction"
                        rows="6"
                        placeholder="Enter your instruction"
                        name="instruction"
                        value={values.instruction}
                        onChange={handleChange}
                      ></textarea>
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.instruction &&
                        touched.instruction &&
                        errors.instruction}
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Negative Marking</Form.Label>
                      <Form.Select
                        defaultValue="false"
                        name="negative_marking"
                        value={values.negative_marking}
                        onChange={(e) => {
                          handleChange(e);
                          handleNegativeMarkingChange(e);
                        }}
                      >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {showMarksDeductedField && (
                    <Row>
                      <Col md={12} className="mb-3">
                        <Form.Group id="emal">
                          <Form.Label>Marks Deducted</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Marks Deducted Per Question"
                            name="marks_deducted"
                            value={values.marks_deducted}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Has Practice Mode?</Form.Label>
                      <Form.Select
                        defaultValue="false"
                        name="is_practice_mode"
                        value={values.is_practice_mode}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={12} className="mb-3">
                    <Form.Group id="timePicker">
                      <Form.Label>Time Duration</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faClock} />
                        </InputGroup.Text>
                        <TimePicker
                          disableClock={true}
                          clearIcon={null}
                          value={values.time_duration}
                          format="HH:mm:ss"
                          maxDetail="second"
                          onChange={(newTime) => {
                            setFieldValue("time_duration", newTime);
                          }}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>

                  <Col>
                    <div class="form-check mb-3">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={values.isPublic}
                        id="flexCheckPublic"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("isPublic", e.target.checked);
                        }}
                      />
                      <label class="form-check-label" for="flexCheckPublic">
                        isPublic
                      </label>
                    </div>
                  </Col>
                  <Col>
                    {/* <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={values.isSection}
                        id="flexCheckPublic"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("isSection", e.target.checked);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckPublic"
                      >
                        IsSection
                      </label>
                    </div>

                    {values.isSection && values.sectionWise && (
                      <FieldArray name="sectionWise">
                        {({ push, remove }) => (
                          <>
                            {values.sectionWise.map((item, index) => (
                              <div key={index}>
                                <Row>
                                  <Col md={4} className="mb-3">
                                    <BootstrapForm.Group>
                                      <BootstrapForm.Label>
                                        Section{" "}
                                        {String.fromCharCode(65 + index)}
                                      </BootstrapForm.Label>
                                      <BootstrapForm.Control
                                        type="text"
                                        placeholder="Enter section"
                                        name={`sectionWise[${index}].section`}
                                        value={item.section}
                                        onChange={handleChange}
                                        isInvalid={
                                          touched.sectionWise?.[index]
                                            ?.section &&
                                          !!errors.sectionWise?.[index]?.section
                                        }
                                      />
                                      <BootstrapForm.Control.Feedback type="invalid">
                                        {errors.sectionWise?.[index]?.section}
                                      </BootstrapForm.Control.Feedback>
                                    </BootstrapForm.Group>
                                  </Col>
                                  <Col md={4} className="mb-3">
                                    <BootstrapForm.Group id="timePicker">
                                      <BootstrapForm.Label>
                                        Time Duration
                                      </BootstrapForm.Label>
                                      <InputGroup>
                                        <InputGroup.Text>
                                          <FontAwesomeIcon icon={faClock} />
                                        </InputGroup.Text>
                                        <TimePicker
                                          disableClock={true}
                                          clearIcon={null}
                                          value={item.time_duration}
                                          onChange={(newTime) => {
                                            setFieldValue(
                                              `sectionWise[${index}].time_duration`,
                                              newTime
                                            );
                                          }}
                                          format="HH:mm:ss"
                                          maxDetail="second"
                                        />
                                      </InputGroup>
                                      {touched.sectionWise?.[index]
                                        ?.time_duration &&
                                        errors.sectionWise?.[index]
                                          ?.time_duration && (
                                          <div className="invalid-feedback d-block">
                                            {
                                              errors.sectionWise?.[index]
                                                ?.time_duration
                                            }
                                          </div>
                                        )}
                                    </BootstrapForm.Group>
                                  </Col>
                                  <Col md={3} className="mb-3">
                                    <BootstrapForm.Group>
                                      <BootstrapForm.Label>
                                        Number Of Questions {index + 1}
                                      </BootstrapForm.Label>
                                      <BootstrapForm.Control
                                        type="text"
                                        placeholder="Enter Number Of Questions"
                                        name={`sectionWise[${index}].NumberOfQuestions`}
                                        value={item.NumberOfQuestions}
                                        onChange={handleChange}
                                        isInvalid={
                                          touched.sectionWise?.[index]
                                            ?.NumberOfQuestions &&
                                          !!errors.sectionWise?.[index]
                                            ?.NumberOfQuestions
                                        }
                                      />
                                      <BootstrapForm.Control.Feedback type="invalid">
                                        {
                                          errors.sectionWise?.[index]
                                            ?.NumberOfQuestions
                                        }
                                      </BootstrapForm.Control.Feedback>
                                    </BootstrapForm.Group>
                                  </Col>
                                  <Col md={1} className="mb-3 align-self-end">
                                    <Button
                                      variant="danger"
                                      size="sm"
                                      onClick={() => remove(index)}
                                    >
                                      Remove
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            ))}
                            <Row>
                              <Col md={12} className="mb-3">
                                <Button
                                  variant="primary"
                                  onClick={() =>
                                    push({
                                      section: "",
                                      time_duration: "00:00:00",
                                      NumberOfQuestions: "",
                                    })
                                  }
                                >
                                  Add Section
                                </Button>
                              </Col>
                            </Row>
                          </>
                        )}
                      </FieldArray>
                    )} */}
                  </Col>
                </Row>
                <Col>
                  <Row></Row>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={values.isSection}
                      id="flexCheckPublic"
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue("isSection", e.target.checked);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckPublic"
                    >
                      isSection
                    </label>
                  </div>
                </Col>
                {values.isSection && (
                  <FieldArray name="sectionWise">
                    {({ push, remove }) => (
                      <>
                        {values.sectionWise.map((item, index) => {
                          return (
                            <div key={index}>
                            {console.log(values.sectionWise,"values.sectionWise*-*-*-")}
                              <Row>
                                <Col md={3} className="mb-3">
                                  <BootstrapForm.Group>
                                    <BootstrapForm.Label>
                                      Section {String.fromCharCode(65 + index)}
                                    </BootstrapForm.Label>
                                    <BootstrapForm.Control
                                      type="text"
                                      placeholder="Enter section"
                                      name={`sectionWise[${index}].section`}
                                      value={item.section}
                                      onChange={handleChange}
                                      isInvalid={
                                        touched.sectionWise?.[index]?.section &&
                                        !!errors.sectionWise?.[index]?.section
                                      }
                                    />
                                    <BootstrapForm.Control.Feedback type="invalid">
                                      {errors.sectionWise?.[index]?.section}
                                    </BootstrapForm.Control.Feedback>
                                  </BootstrapForm.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                  <BootstrapForm.Group id="timePicker">
                                    <BootstrapForm.Label>
                                      Time Duration
                                    </BootstrapForm.Label>
                                    <InputGroup>
                                      <InputGroup.Text>
                                        <FontAwesomeIcon icon={faClock} />
                                      </InputGroup.Text>
                                      <TimePicker
                                        disableClock={true}
                                        clearIcon={null}
                                        value={item.time_duration}
                                        onChange={(newTime) => {
                                          setFieldValue(
                                            `sectionWise[${index}].time_duration`,
                                            newTime
                                          );
                                        }}
                                        format="HH:mm:ss"
                                        maxDetail="second"
                                      />
                                    </InputGroup>
                                    {touched.sectionWise?.[index]
                                      ?.time_duration &&
                                      errors.sectionWise?.[index]
                                        ?.time_duration && (
                                        <div className="invalid-feedback d-block">
                                          {
                                            errors.sectionWise?.[index]
                                              ?.time_duration
                                          }
                                        </div>
                                      )}
                                  </BootstrapForm.Group>
                                </Col>
                                <Col md={4} className="mb-3">
                                  <BootstrapForm.Group>
                                    <BootstrapForm.Label>
                                      Number Of Questions {index + 1}
                                    </BootstrapForm.Label>
                                    <BootstrapForm.Control
                                      type="text"
                                      placeholder="Enter Number Of Questions"
                                      name={`sectionWise[${index}].NumberOfQuestions`}
                                      value={item.NumberOfQuestions}
                                      onChange={handleChange}
                                      isInvalid={
                                        touched.sectionWise?.[index]
                                          ?.NumberOfQuestions &&
                                        !!errors.sectionWise?.[index]
                                          ?.NumberOfQuestions
                                      }
                                    />
                                    <BootstrapForm.Control.Feedback type="invalid">
                                      {
                                        errors.sectionWise?.[index]
                                          ?.NumberOfQuestions
                                      }
                                    </BootstrapForm.Control.Feedback>
                                  </BootstrapForm.Group>
                                </Col>
                                <Col md={2} className="mb-3 align-self-end">
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                        <Row>
                          <Col md={12} className="mb-3">
                            <Button
                              variant="primary"
                              onClick={() =>
                                push({
                                  section: "",
                                  time_duration: "00:00:00",
                                  NumberOfQuestions: "",
                                })
                              }
                            >
                              Add Section
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </FieldArray>
                )}
                {/* <Formik
        initialValues={{
          isSection: editData.isSection || false,
          sectionWise: editData.sectionWise || [],
        }}
        validationSchema={SectionSchema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          errors,
          touched,
        }) => {
      

          return (
            <BootstrapForm>
              <Col md={12} className="mb-3">
                <BootstrapForm.Group id="isSection">
                  <BootstrapForm.Label>isSection</BootstrapForm.Label>
                  <BootstrapForm.Select
                    name="isSection"
                    onChange={(e) => {
                      const selectedOption = e.target.value === 'true';
                      handleChange(e);
                      if (selectedOption) {
                        setFieldValue("fromtime", null);
                        if (values.sectionWise.length === 0) {
                          setFieldValue("sectionWise", [
                            {
                              section: "A",
                              time_duration: "",
                              NumberOfQuestions: "",
                            },
                          ]);
                        }
                      } else {
                        setFieldValue("sectionWise", []);
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.isSection ? 'true' : 'false'}
                  >
                    <option value="false">False</option>
                    <option value="true">True</option>
                  </BootstrapForm.Select>
                </BootstrapForm.Group>
              </Col>
              {values.isSection && (
                <FieldArray name="sectionWise">
                  {({ push, remove }) => (
                    <>
                      {mockEditSection.map((item, index) => (
                        <div key={index}>
                          <Row>
                            <Col md={4} className="mb-3">
                              <BootstrapForm.Group>
                                <BootstrapForm.Label>
                                  Section {String.fromCharCode(65 + index)}
                                </BootstrapForm.Label>
                                <BootstrapForm.Control
                                  type="text"
                                  placeholder="Enter section"
                                  name={`sectionWise[${index}].section`}
                                  value={item.section}
                                  onChange={handleChange}
                                  isInvalid={
                                    touched.sectionWise?.[index]?.section &&
                                    !!errors.sectionWise?.[index]?.section
                                  }
                                />
                                <BootstrapForm.Control.Feedback type="invalid">
                                  {errors.sectionWise?.[index]?.section}
                                </BootstrapForm.Control.Feedback>
                              </BootstrapForm.Group>
                            </Col>
                            <Col md={4} className="mb-3">
                              <BootstrapForm.Group id="timePicker">
                                <BootstrapForm.Label>
                                  Time Duration
                                </BootstrapForm.Label>
                                <InputGroup>
                                  <InputGroup.Text>
                                    <FontAwesomeIcon icon={faClock} />
                                  </InputGroup.Text>
                                  <TimePicker
                                    disableClock={true}
                                    clearIcon={null}
                                    value={item.time_duration}
                                    onChange={(newTime) => {
                                      setFieldValue(
                                        `sectionWise[${index}].time_duration`,
                                        newTime
                                      );
                                    }}
                                    format="HH:mm"
                                  />
                                </InputGroup>
                                {touched.sectionWise?.[index]?.time_duration &&
                                  errors.sectionWise?.[index]?.time_duration && (
                                    <div className="invalid-feedback d-block">
                                      {errors.sectionWise?.[index]?.time_duration}
                                    </div>
                                  )}
                              </BootstrapForm.Group>
                            </Col>
                            <Col md={3} className="mb-3">
                              <BootstrapForm.Group>
                                <BootstrapForm.Label>
                                  Number Of Questions {index + 1}
                                </BootstrapForm.Label>
                                <BootstrapForm.Control
                                  type="text"
                                  placeholder="Enter Number Of Questions"
                                  name={`sectionWise[${index}].NumberOfQuestions`}
                                  value={item.NumberOfQuestions}
                                  onChange={handleChange}
                                  isInvalid={
                                    touched.sectionWise?.[index]?.NumberOfQuestions &&
                                    !!errors.sectionWise?.[index]?.NumberOfQuestions
                                  }
                                />
                                <BootstrapForm.Control.Feedback type="invalid">
                                  {errors.sectionWise?.[index]?.NumberOfQuestions}
                                </BootstrapForm.Control.Feedback>
                              </BootstrapForm.Group>
                            </Col>
                            <Col md={1} className="mb-3 align-self-end">
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => remove(index)}
                              >
                                Remove
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      ))}
                      <Row>
                        <Col md={12} className="mb-3">
                          <Button
                            variant="primary"
                            onClick={() =>
                              push({
                                section: "",
                                time_duration: "",
                                NumberOfQuestions: "",
                              })
                            }
                          >
                            Add Section
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </FieldArray>
              )}
            </BootstrapForm>
          );
        }}
      </Formik> */}

                <div className="mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>

        <div>
          {isLoadingData ? (
            <div className="loader-container">
              <Image
                className="loader-element animate__animated animate__jackInTheBox"
                src={ReactLogo}
                height={40}
              />
            </div>
          ) : (
            <MDBDataTable striped bordered small data={datatable} />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};
