import React, { useState, useEffect, useRef } from "react";
import { Form as BootstrapForm, Col } from "react-bootstrap";
import DragAndDrop from "./dragAndDrop";
import CreatableSelect from "react-select";
import JoditEditor from "jodit-react";
import moment from "moment";
import { Image } from "@themesberg/react-bootstrap";
import ReactLogo from "../assets/img/technologies/loading-loader-svgrepo-com.svg";
import dayjs from "dayjs";
import TimePicker from "react-time-picker";
import {
  // Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faEye,
  faMinus,
  faClosedCaptioning,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
// import TimePicker from "react-time-picker";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import "react-responsive-modal/styles.css";
import * as Yup from "yup";
import { Modal } from "react-responsive-modal";
import { MDBDataTable } from "mdbreact";

import axios from "axios";

import { Formik, FieldArray } from "formik";

import "jspdf-autotable";
import "react-datepicker/dist/react-datepicker.css";

import { useHistory } from "react-router-dom";
export const Quiz = () => {
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(true);
  const [isError, setIsError] = useState("");
  const [data, setData] = useState([]);
  const [examData, setExamData] = useState([]);
  // const [time, setTime] = useState("00:00:00");
  // const [time, setTime] = useState(
  //   dayjs("2022-04-17T15:30").format("HH:mm:ss")
  // );
  const [time, setTime] = useState("00:00:00");

  const initialValues = {
    quiz_name: "",
    day: 1,
    dateTime: "",
    marks_deducted: 0,
    marks_awarded: 0,
    description: "",
    time_duration: "00:00:00", // Ensure initial value is set properly
    question: [],
  };
  
  const [QuizData, setQuizData] = useState({
    quiz_name: "",
    day: 1,
    dateTime: "",
    marks_deducted: 0,
    marks_awarded: 0,
    description: "",
    time_duration: "00:00:00",
    question: data.data_list,
  });
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [fileBuffer, setFileBuffer] = useState("");
  const [editData, setEditData] = useState({});
  const [AllQuizData, setAllQuizData] = useState({});
  const [Addopen, setAddOpen] = useState(false);
  const onAddOpenModal = () => setAddOpen(true);
  const onAddCloseModal = () => setAddOpen(false);
  const [Editopen, setEditOpen] = useState(false);
  const onEditOpenModal = () => setEditOpen(true);
  const onEditCloseModal = () => setEditOpen(false);
  const editor = useRef(null);
  const validationSchema = Yup.object().shape({
    quiz_name: Yup.string().required("Quiz Name is required"),
    day: Yup.number().required("Quiz Day is required"),
    dateTime: Yup.string().required("Date and Time are required"),
    marks_deducted: Yup.number().required("Marks Deducted is required"),
    marks_awarded: Yup.number().required("Marks Awarded is required"),
    description: Yup.string().required("Description is required"),
    // time_duration: Yup.string().required("Time Duration is required"),
    time_duration: Yup.string().matches(
      /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/,
      "Time must be in HH:mm:ss format"
    ),
  });

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const history = useHistory();
  const navigateDetails = (id) => {
    history.push(`/quizDetails?id=${id}`);
  };
  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "No",
        field: "id",
        width: 270,
      },
      {
        label: "Quiz Name",
        field: "quiz_name",
        width: 100,
      },
      {
        label: "Day",
        field: "day",
        width: 100,
      },
      {
        label: "Date Time",
        field: "dateTime",
        width: 100,
      },
      {
        label: "Time Duration",
        field: "time_duration",
        width: 100,
      },
      {
        label: "Marks Deducted",
        field: "marks_deducted",
        width: 100,
      },
      {
        label: "Marks Awarded",
        field: "marks_awarded",
        width: 100,
      },
      {
        label: "Description",
        field: "description",
        width: 100,
      },
      {
        label: "Action",
        field: "actions",
        width: 100,
      },
    ],
    rows: [],
  });
  // const initialValues = {
  //   quiz_name: "",
  //   day: 1,
  //   dateTime: "",
  //   marks_deducted: 0,
  //   marks_awarded: 0,
  //   description: "",
  //   time_duration: "",
  //   question: [],
  // };
  const getMyQuizData = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getAllQuizExam`,
        {
          headers: { Authorization: token },
        }
      );
      setAllQuizData(res.data);
      const data = res.data?.map((item, index) => {
        return {
          id: index + 1,
          _id: item._id,
          quiz_name: item.quiz_name,
          day: item.day,
          dateTime: item.dateTime,
          time_duration: item.time_duration,
          marks_deducted: item.marks_deducted,
          marks_awarded: item.marks_awarded,
          description: item.description,
          actions: (
            <div>
              <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(item)} />
              <FontAwesomeIcon
                icon={faTrash}
                className="mx-3"
                onClick={() => handleDelete(item._id)}
              />
              <FontAwesomeIcon
                style={{ marginRight: "1rem" }}
                icon={faEye}
                onClick={() => navigateDetails(item._id)}
              />
            </div>
          ),
        };
      });
      setDatatable((prevState) => ({
        ...prevState,
        rows: data,
      }));
    } catch (error) {
      // setIsError(error.response);
    }
  };
  const updateQuiz = async (id, updatedData) => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/updateQuizExam/${id}`,
        updatedData,
        {
          headers: { Authorization: token },
        }
      );

      console.log(res.data); // Log the response or handle it as required.

      onEditCloseModal(); // Close the modal after successful update.
      Swal.fire({
        position: "center",
        icon: "success",
        title: " Quiz Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getMyQuizData();
    } catch (error) {
      console.error(error.response); // Handle error responses
    }
  };

  const EditSchema = Yup.object().shape({
    marks_deducted: Yup.string().required("marks_deducted name is Required"),
    dateTime: Yup.string().required("dateTime name is Required"),
    quiz_name: Yup.string().required("quiz_name is Required"),
    time_duration: Yup.string().required("time_duration is Required"),
    marks_awarded: Yup.string().required("marks_awarded is Required"),
    description: Yup.string().required("description is Required"),
    day: Yup.string().required("day is Required"),
  });

  const getFiles = (files) => {
    setFileBuffer(files[0]);
  };

  const getQuation = async () => {
    setIsLoadingQuestions(true);

    try {
      // Ensure fileBuffer is not empty
      if (!fileBuffer) {
        throw new Error("File buffer is empty");
      }

      const formData = new FormData();
      formData.append("file", fileBuffer);

      // Logging form data keys to ensure the file is appended
      for (let key of formData.keys()) {
        console.log(key);
      }

      const res = await axios.post(
        `https://docparser.sushrutalgs.in/process_file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(res, "********************************");

      setData(res.data.data_list);
      console.log(setData, "setdataaaaaaaaaaaaa");
      setIsLoadingQuestions(false);
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      setIsError(errorMessage);
      setIsLoadingQuestions(false);
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const token = localStorage.getItem("token");
          const res = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/deleteQuizExam/${id}`,
            {
              headers: { Authorization: token },
            }
          );
          Swal.fire("Deleted!", "Quiz has been deleted.", "success");
          getMyQuizData();
        } catch (error) {
          Swal.fire("Error", "Failed to delete the Quiz", "error");
        }
      }
    });
  };

  const createQuiz = async (values, setFieldValue) => {
    const token = localStorage.getItem("token");
    const formattedTime = values.time_duration
    ? values.time_duration
    : "00:00:00";
    // const formattedTime = values.time_duration
    // ? `${values.time_duration}`
    // : "00:00:00";
    // const formattedTime = values.time_duration
    //   ? values.time_duration
    //   : "00:00:00";
    try {
      const dataWithQuestionNumber = data.map((question, index) => ({
        ...question,
        question_number: index + 1,
      }));
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/quizExam/create2`,
        { ...values, formattedTime, question: dataWithQuestionNumber },
        {
          headers: { Authorization: `${token}` },
        }
      );
      console.log("🚀 ~ createQuiz ~ res:", res);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Quiz has been Added Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      await getMyQuizData(); // Ensure this function retrieves updated quiz data
      onAddCloseModal();
      setFieldValue("quiz_name", "");
      setDroppedFiles([]);
      setData([]);
      setQuizData({
        time_duration: "00:00:00",
      });
      setFieldValue("description", "");
      setFieldValue("time_duration", "00:00:00");
    } catch (error) {
      setIsError(error.response?.data?.err?.message || "An error occurred");
    }
  };

  useEffect(() => {
    setData([]);
    getQuation();
  }, [fileBuffer]);

  const handleEdit = (data) => {
    const fromtime = data.fromtime ? new Date(data.fromtime) : "";
    const totime = data.totime ? new Date(data.totime) : "";
    // const time_duration = data.time_duration || 0;
    console.log(data.time_duration, "timeduration%%%%%%%%%%%%%%%%");
    setEditData({
      ...data,
      fromtime,
      totime,
      examType: fromtime && totime ? "Time Period" : "Always Live",
    }); // Set the data of the selected row
    setEditOpen(true); // Open the modal
  };
  useEffect(() => {
    getMyQuizData();
    getQuation();
  }, [fileBuffer]);
  return (
    <Card border="black">
      <Card.Body>
        <h5 className="mb-4">Quiz</h5>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <button className="btn btn-primary" onClick={onAddOpenModal}>
            Add Quiz
            <FontAwesomeIcon icon={faPlus} className="mx-2" />
          </button>
        </div>
      </Card.Body>
      <Modal open={Addopen} onClose={onAddCloseModal} center>
        <h2>Add Quiz</h2>
        <Formik
          initialValues={QuizData}
          validationSchema={validationSchema}
          onSubmit={(values, setFieldValue) => {
            createQuiz(values, setFieldValue);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form>
              <Row>
                <Col md={12} className="mt-3">
                  <Form.Group controlId="quiz_name">
                    <Form.Label>Quiz Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="quiz_name"
                      value={values.quiz_name}
                      onChange={handleChange}
                    />
                    {touched.quiz_name && errors.quiz_name && (
                      <div className="text-danger">{errors.quiz_name}</div>
                    )}
                  </Form.Group>
                </Col>
                <Col md={12} className="mt-3">
                  <Form.Group controlId="day">
                    <Form.Label>Quiz Day</Form.Label>
                    <Form.Control
                      type="number"
                      name="day"
                      value={values.day}
                      onChange={handleChange}
                    />
                    {touched.day && errors.day && (
                      <div className="text-danger">{errors.day}</div>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6} className="mt-3">
                  <Form.Group controlId="dateTime">
                    <Form.Label>Date Time</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      name="dateTime"
                      value={values.dateTime}
                      onChange={handleChange}
                    />
                    {touched.dateTime && errors.dateTime && (
                      <div className="text-danger">{errors.dateTime}</div>
                    )}
                  </Form.Group>
                </Col>
                
                <Col md={6} className="mt-3">
                  <Form.Group controlId="marks_deducted">
                    <Form.Label>Marks Deducted</Form.Label>
                    <Form.Control
                      type="number"
                      name="marks_deducted"
                      value={values.marks_deducted}
                      onChange={handleChange}
                    />
                    {touched.marks_deducted && errors.marks_deducted && (
                      <div className="text-danger">{errors.marks_deducted}</div>
                    )}
                  </Form.Group>
                </Col>
                
                <Col md={12} className="mt-3">
                  <Form.Group controlId="marks_awarded">
                    <Form.Label>Marks Awarded</Form.Label>
                    <Form.Control
                      type="number"
                      name="marks_awarded"
                      value={values.marks_awarded}
                      onChange={handleChange}
                    />
                    {touched.marks_awarded && errors.marks_awarded && (
                      <div className="text-danger">{errors.marks_awarded}</div>
                    )}
                  </Form.Group>
                  
                </Col>
                <Col md={12} className="mb-3">
                  <Form.Group id="timePicker">
                    <Form.Label>Time Duration</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faClock} />
                      </InputGroup.Text>
                      <TimePicker
                        disableClock={true}
                        clearIcon={null}
                        value={values.time_duration}
                        onChange={(newTime) => {
                          setFieldValue("time_duration", newTime);
                        }}
                        format="HH:mm:ss"
                        maxDetail="second"
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                {/* <Col md={12} className="mb-3">
                  <Form.Group id="firstName">
                    <Form.Label>Description</Form.Label>
                    <JoditEditor
                      ref={editor}
                      value={values.description}
                      onBlur={(newContent) =>
                        setFieldValue("description", newContent)
                      }
                      onChange={(newContent) =>
                        setFieldValue("description", newContent)
                      }
                      tabIndex={1}
                    />
                  </Form.Group>
                  <div className="mb-3 text-danger">
                    {errors.description &&
                      touched.description &&
                      errors.description}
                  </div>
                </Col> */}
                <Col md={12} className="mb-3">
                    <Form.Label>Description</Form.Label>
                    {/* <Form.Group id="phone">
                      <Form.Label>Instruction</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="instruction"
                        name="instruction"
                        value={values.instruction}
                        onChange={handleChange}
                      />
                    </Form.Group> */}
                    <textarea
                      class="form-control"
                      id="description"
                      rows="6"
                      placeholder="Enter your Description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                    ></textarea>
                    <div className="mb-3 text-danger">
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </div>
                  </Col>
                
{/* 
                <TimePicker
  disableClock={true}
  clearIcon={null}
  value={values.time_duration}
  onChange={(newTime) => {
    setFieldValue("time_duration", newTime);
  }}
  format="HH:mm:ss"
/> */}
                

                <DragAndDrop
                  getFiles={getFiles}
                  droppedFiles={droppedFiles}
                  setDroppedFiles={setDroppedFiles}
                />
                <div className="container mt-3">
                  <div className="row">
                    {data.length > 0 && (
                      <div className="text-center mt-2 mb-2">
                        <button
                          className="btn btn-primary"
                          onClick={onOpenModal}
                        >
                          Add Question
                          <FontAwesomeIcon icon={faPlus} className="mx-2" />
                        </button>
                      </div>
                    )}
                    {isLoadingQuestions ? (
                      <div className="loader-container">
                        <Image
                          className="loader-element animate__animated animate__jackInTheBox"
                          src={ReactLogo}
                          height={40}
                        />
                      </div>
                    ) : (
                      data.map((question, index) => (
                        <div key={index} className="question-card col-md-6">
                          <div>
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="mx-3 float-rightIcon"
                              onClick={() => handleDelete(index)}
                            />
                            <FontAwesomeIcon
                              icon={faEdit}
                              className="float-rightIcon"
                              onClick={() => handleEdit(question, index)}
                            />
                          </div>
                          <div className="question-header">
                            <h2>Question {index + 1}</h2>
                          </div>
                          <div className="question-content">
                            {question.question_text && (
                              <p className="question-text">
                                {question.question_text}
                              </p>
                            )}
                            {question.question_image &&
                              question.question_image[0] && (
                                <img
                                  src={`data:image/png;base64,${question.question_image[0]}`}
                                  alt={`Question ${index + 1}`}
                                  className="question-image"
                                />
                              )}
                              <Col md={12} className="mb-3">
                                                            <div class="form-group">
                                                                <label for="explanation">
                                                                    Explanation (Read only)
                                                                </label>
                                                                <textarea
                                                                    readonly
                                                                    class="form-control"
                                                                    id="explanation"
                                                                    rows="12"
                                                                    name="explanation"
                                                                    value={question.explanation}
                                                                ></textarea>
                                                            </div>
                                                        </Col>

                            <div className="options-container row">
                              {question.options.map((option, optionIndex) => (
                                <div
                                  key={option.value}
                                  className="option d-flex col-md-6 mb-2"
                                >
                                  <p className="mx-1">{option.value}</p>
                                  {option.answer_image ? (
                                    <img
                                      src={`data:image/png;base64,${option.answer_image}`}
                                      alt={`Option ${optionIndex + 1}`}
                                      className="option-image"
                                    />
                                  ) : (
                                    <div
                                      className={`ans-text ${
                                        option.value === question.correct_option
                                          ? "correct-option"
                                          : ""
                                      }`}
                                    >
                                      {option.answer_title}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                    
                  </div>
                 

                  <div className="text-center mt-2 mb-2">
                    {data.length > 0 && (
                      <div className="text-center mt-2 mb-2">
                        <button
                          className="btn btn-primary"
                          onClick={handleSubmit}
                        >
                          Create Question Paper
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal>
      <Modal open={Editopen} onClose={onEditCloseModal} center>
        <h2>Edit Quiz</h2>
        <Formik
          initialValues={editData}
          validationSchema={EditSchema}
          onSubmit={(values) => {
            updateQuiz(editData._id, values); // Pass the ID and updated data to updateUser
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => (
            <Form>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="firstName">
                    <Form.Label>Quiz Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your quiz_name"
                      name="quiz_name"
                      value={values.quiz_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <div className="mb-3 text-danger">
                    {errors.fullname && touched.fullname && errors.fullname}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="lastName">
                    <Form.Label>Marks Deducted</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter marks_deducted"
                      name="marks_deducted"
                      value={values.marks_deducted}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <div className="mb-3 text-danger">
                    {errors.email && touched.email && errors.email}
                  </div>
                </Col>
                <Col md={12} className="mb-3">
                  <Form.Group id="lastName">
                    <Form.Label>Marks Awarded</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter marks_awarded"
                      name="marks_awarded"
                      value={values.marks_awarded}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <div className="mb-3 text-danger">
                    {errors.email && touched.email && errors.email}
                  </div>
                </Col>

                <Col md={12} className="mb-3">
                  <Form.Group id="lastName">
                    <Form.Label>DateTime</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Enter dateTime"
                      name="dateTime"
                      value={values.dateTime}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <div className="mb-3 text-danger">
                    {errors.dateTime && touched.dateTime && errors.dateTime}
                  </div>
                </Col>

                <Col md={12} className="mb-3">
                  <Form.Group id="timePicker">
                    <Form.Label>Time Duration</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faClock} />
                      </InputGroup.Text>
                      <TimePicker
                        disableClock={true}
                        clearIcon={null}
                        value={values.time_duration}
                        format="HH:mm:ss"
                        onChange={(newTime) => {
                          const formattedTime = moment(
                            newTime,
                            "HH:mm:ss"
                          ).format("HH:mm:ss");
                          setFieldValue("time_duration", formattedTime);
                        }}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <BootstrapForm.Group
                as={Col}
                md={12}
                className="mb-3"
                controlId="description"
              >
                {/* <BootstrapForm.Label>Description</BootstrapForm.Label> */}

                <Col md={12} className="mb-3">
                  <Form.Group id="firstName">
                    <Form.Label>Description</Form.Label>
                    <JoditEditor
                      ref={editor}
                      value={values.description}
                      onBlur={(newContent) =>
                        setFieldValue("description", newContent)
                      }
                      onChange={(newContent) =>
                        setFieldValue("description", newContent)
                      }
                      tabIndex={1}
                    />
                  </Form.Group>
                  <div className="mb-3 text-danger">
                    {errors.description &&
                      touched.description &&
                      errors.description}
                  </div>
                </Col>
              </BootstrapForm.Group>

              <div className="mt-3">
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  Update
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      <MDBDataTable striped bordered small data={datatable} />
    </Card>
  );
};
