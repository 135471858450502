import React, { useState, useEffect, useMemo } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import "react-time-picker/dist/TimePicker.css";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import DownloadExcelButton from "./downloadExel";
import { MaterialReactTable } from "material-react-table";
import Select from "react-select";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import moment from "moment";
import ReactLogo from "../assets/img/technologies/loading-loader-svgrepo-com.svg";
import { Image } from '@themesberg/react-bootstrap';
export const ZoomMeeting = () => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [SubscriptionData, setMySubscriptionData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [Editopen, setEditOpen] = useState(false);
  const onEditOpenModal = () => setEditOpen(true);
  const onEditCloseModal = () => setEditOpen(false);
  const [categoryData, setMyCategoryData] = useState([]);

  const [time, setTime] = useState("00:00:00");
  const [myData, setMyData] = useState({
    topic: "",
    description: "",
    subscription_id: [],
  });
  const [jsonData, setJsonData] = useState([]);
  const [editData, setEditData] = useState({});

  const history = useHistory();

  const createZoomMeeting = async (values) => {
    const token = localStorage.getItem("token");
    console.log("values -------- ", values);
    let date = moment(values.dateTime).format("YYYY-MM-DD");
    let time = moment(values.dateTime).format("HH:mm");

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/createMeetingV2`,
        {
          ...values,
          date,
          time,
        },
        {
          headers: { Authorization: `${token}` },
        }
      );
      onCloseModal();
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Meeting has been Added Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getZoomMeetingData();
    } catch (error) {}
  };

  const getMySubscriptionData = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Subscription/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      setMySubscriptionData(res.data);
    } catch (error) {
      //   setIsError(error.response);
    }
  };

  const SigninSchema = Yup.object().shape({
    topic: Yup.string().required("Topic is Required"),
    description: Yup.string().required("description is Required"),
    subscription_id: Yup.array()
      .of(Yup.string().required("subscription is required"))
      .min(1, "Select at least one subscription"),
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "topic",
        header: "Topic",
      },
      {
        accessorKey: "description",
        header: "Description",
      },
      {
        accessorKey: "join_url",
        header: "URL",
      },
      {
        accessorKey: "start_time",
        header: "Start Time",
      },
      {
        accessorKey: "duration",
        header: "Duration",
      },
      {
        accessorKey: "actions",
        header: "actions",
      },
    ],
    []
    //end
  );

  const getZoomMeetingData = async () => {
    const token = localStorage.getItem("token");
    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getAllMeetingByAdmin`,
        {
          headers: { Authorization: token },
        }
      );
      const data = res.data?.map((item, index) => {
        return {
          ...item,
          id: index + 1,
          topic: item.topic,
          description: item.description,
          _id: item._id,
          actions: (
            <div>
              <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(item)} />

              <FontAwesomeIcon
                className="mx-3"
                icon={faTrash}
                onClick={() => handleDelete(item._id)}
              />
            </div>
          ),
        };
      });
      setJsonData(data);
      setIsLoadingData(false);
    } catch (error) {
      setIsLoadingData(false);
    }
  };

  const handleEdit = (data) => {
    setEditData({
      ...data,
      dateTime: moment(data.start_time, "DD MMM, h:mm a").toDate(),
    }); // Set the data of the selected row
    setEditOpen(true); // Open the modal
  };

  const updateSubcategory = async (id, updatedData) => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/deleteMeeting/${id}`,
        {
          headers: { Authorization: token },
        }
      );
      let date = moment(updatedData.dateTime).format("YYYY-MM-DD");
      let time = moment(updatedData.dateTime).format("HH:mm");

      let newData = {
        topic: updatedData.topic,
        duration: updatedData.duration,
        description: updatedData.description,
        subscription_id: updatedData.subscription_id,
        date,
        time,
      };
      const res1 = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/createMeetingV2`,
        newData,
        {
          headers: { Authorization: `${token}` },
        }
      );
      console.log(res1.data); // Log the response or handle it as required.
      // Optionally, you can refetch the subcategory data after successful update.
      // getMySubCategoryData();
      onEditCloseModal(); // Close the modal after successful update.
      Swal.fire({
        position: "center",
        icon: "success",
        title: " Meeting Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getZoomMeetingData();
    } catch (error) {
      console.error(error.response); // Handle error responses
    }
  };

  const handleDelete = (id) => {
    // Show the confirmation dialog using SweetAlert
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with the delete operation
        try {
          const token = localStorage.getItem("token");
          const res = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/deleteMeeting/${id}`,
            {
              headers: { Authorization: token },
            }
          );
          console.log(res.data); // Log the response or handle it as required.
          // Optionally, you can refresh the subcategory data after successful deletion.
          // getMySubCategoryData();
          // Show a success message using SweetAlert
          Swal.fire("Deleted!", "Category has been deleted.", "success");
          getZoomMeetingData();
        } catch (error) {
          console.error(error.response); // Handle error responses
          // Show an error message using SweetAlert
          Swal.fire("Error", "Failed to delete the Category.", "error");
        }
      }
    });
  };

  const EditSchema = Yup.object().shape({
    topic: Yup.string().required("Topic is Required"),
    description: Yup.string().required("description is Required"),
    subscription_id: Yup.array()
      .of(Yup.string().required("subscription is required"))
      .min(1, "Select at least one subscription"),
  });

  useEffect(() => {
    getZoomMeetingData();
    getPdfData();
    getMySubscriptionData();
  }, []);

  const getPdfData = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Content/getAllByPdf`,
        {
          headers: { Authorization: token },
        }
      );
      setMyCategoryData(res.data);
    } catch (error) {
      // setIsError(error.response);
    }
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Zoom Meeting</h5>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <Modal open={Editopen} onClose={onEditCloseModal} center>
            <h2>Edit ZoomMeeting</h2>
            <Formik
              initialValues={editData}
              validationSchema={EditSchema}
              onSubmit={(values) => {
                updateSubcategory(editData._id, values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <Form>
                  <Row>
                    <Col md={12} className="">
                      <Form.Group id="topic">
                        <Form.Label>Topic</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter topic"
                          name="topic"
                          value={values.topic}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <div className="mb-3 text-danger">
                        {errors.topic && touched.topic && errors.topic}
                      </div>
                    </Col>
                  
                    <Col md={12} className="mb-3">
                      <Form.Group id="gender">
                        <Form.Label>Select Subscription Plan</Form.Label>
                        <Select
                          name="subscription_id"
                          value={values.subscription_id?.map((id) => ({
                            value: id,
                            label:
                              SubscriptionData.find((item) => item.sid === id)
                                ?.plan_name || "",
                          }))}
                          options={SubscriptionData.map((item) => ({
                            value: item.sid,
                            label: item.plan_name,
                          }))}
                          isMulti
                          onChange={(selectedOptions) => {
                            setFieldValue(
                              "subscription_id",
                              selectedOptions.map((option) => option.value)
                            );
                          }}
                        />
                      </Form.Group>
                      <div className="mb-3 text-danger">
                        {errors.subscription_id &&
                          touched.subscription_id &&
                          errors.subscription_id}
                      </div>
                    </Col>

                    <Col md={6} className="mb-3">
                      <Form.Group id="fromdate" className="d-flex flex-column">
                        <Form.Label>Date & Time</Form.Label>
                        <DatePicker
                          onChange={(date) => {
                            setFieldValue("dateTime", moment(date).toDate());
                          }}
                          showTimeSelect
                          selected={values.dateTime}
                          dateFormat="MM/dd/yyyy HH:mm"
                        />
                      </Form.Group>
                      <div className="mb-3 text-danger">
                        {errors.dateTime && touched.dateTime && errors.dateTime}
                      </div>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="phone">
                        <Form.Label>Duration ( in Minutes )</Form.Label>
                        <Form.Control
                          required
                          type="number"
                          placeholder=""
                          name="duration"
                          value={values.duration}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <div className="mb-3 text-danger">
                        {errors.duration && touched.duration && errors.duration}
                      </div>
                    </Col>
                    <Col md={12} className="mb-3">
                      <Form.Group id="lastName">
                        <textarea
                          class="form-control"
                          id="description"
                          rows="6"
                          placeholder="Enter Description"
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                        ></textarea>
                      </Form.Group>
                      <div className="mb-3 text-danger">
                        {errors.description &&
                          touched.description &&
                          errors.description}
                      </div>
                    </Col>
                  </Row>

                  <div className="mt-3 flex">
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal>

          <button className="btn btn-primary" onClick={onOpenModal}>
            Add Zoom Meeting
            <FontAwesomeIcon icon={faPlus} className="mx-2" />
          </button>
          <DownloadExcelButton jsonData={jsonData} fileName="ZoomMeeting" />
        </div>
        <Modal open={open} onClose={onCloseModal} center>
          <h2>Add Zoom Meeting</h2>
          <Formik
            initialValues={myData}
            validationSchema={SigninSchema}
            onSubmit={(values) => {
              createZoomMeeting(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form>
                <Row>
                  <Col md={12} className="">
                    <Form.Group id="topic">
                      <Form.Label>Topic</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter topic"
                        name="topic"
                        value={values.topic}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.topic && touched.topic && errors.topic}
                    </div>
                  </Col>

                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Subscription Plan</Form.Label>
                      <Select
                        name="subscription_id"
                        value={values.subscription_id.map((id) => ({
                          value: id,
                          label:
                            SubscriptionData.find((item) => item.sid === id)
                              ?.plan_name || "",
                        }))}
                        options={SubscriptionData.map((item) => ({
                          value: item.sid,
                          label: item.plan_name,
                        }))}
                        isMulti
                        onChange={(selectedOptions) => {
                          setFieldValue(
                            "subscription_id",
                            selectedOptions.map((option) => option.value)
                          );
                        }}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.subscription_id &&
                        touched.subscription_id &&
                        errors.subscription_id}
                    </div>
                  </Col>

                  <Col md={6} className="mb-3">
                    <Form.Group id="fromdate" className="d-flex flex-column">
                      <Form.Label>Date & Time</Form.Label>
                      <DatePicker
                        onChange={(date) => {
                          setFieldValue("dateTime", date);
                        }}
                        showTimeSelect
                        selected={values.dateTime}
                        dateFormat="MM/dd/yyyy HH:mm"
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.dateTime && touched.dateTime && errors.dateTime}
                    </div>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="phone">
                      <Form.Label>Duration ( in Minutes )</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder=""
                        name="duration"
                        value={values.duration}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.duration && touched.duration && errors.duration}
                    </div>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="lastName">

                      <textarea
                        class="form-control"
                        id="description"
                        rows="6"
                        placeholder="Enter Description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      ></textarea>
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </div>
                  </Col>
                </Row>

                <div className="mt-3 flex">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
        <div>
          {isLoadingData ? (
            <div className="loader-container">
          <Image className="loader-element animate__animated animate__jackInTheBox" src={ReactLogo} height={40} />
          </div>
          ) : (
            <MaterialReactTable
            autoResetPageIndex={false}
            columns={columns}
            data={jsonData}
            // enableRowOrdering
            enableSorting={false}
          />
          )}
        </div>
        
      </Card.Body>
    </Card>
  );
};
