// import React from "react";
// import * as XLSX from "xlsx";
// import { Button } from "@themesberg/react-bootstrap";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faDownload } from '@fortawesome/free-solid-svg-icons';

// const DownloadExcelButton = ({ jsonData, fileName ,columnOrder  }) => {
//   const downloadExcel = () => {
    
//     const ws = XLSX.utils.json_to_sheet(jsonData,{ header: columnOrder });
//     console.log("🚀 ~ downloadExcel ~ jsonData:", jsonData)
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

//     // Convert the workbook to an array buffer
//     const buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

//     // Create a blob from the buffer and download it
//     const blob = new Blob([buffer], {
//       type: "application/octet-stream",
//     });

//     const url = URL.createObjectURL(blob);
//     const a = document.createElement("a");
//     a.href = url;
//     a.download = `${fileName}.xlsx`;
//     a.click();
//     URL.revokeObjectURL(url);
//   };

//   return (
//     <Button
//       onClick={downloadExcel}
//       variant="primary"
//       style={{ backgroundColor: "#2042d0" }}
//     >
//       Download Excel <FontAwesomeIcon icon={faDownload} className="mx-2"/>
//     </Button>
//   );
// };

// export default DownloadExcelButton;


// import React from "react";
// import * as XLSX from "xlsx";
// import { Button } from "@themesberg/react-bootstrap";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faDownload } from '@fortawesome/free-solid-svg-icons';

// const DownloadExcelButton = ({ jsonData, fileName, columnOrder }) => {
//   const downloadExcel = () => {
//     // Add row number to each item in jsonData
//     const jsonDataWithRowNumbers = jsonData.map((item, index) => ({
//       RowNumber: index + 1, // Adding 1 to make it 1-based index
//       ...item
//     }));

//     // Ensure columnOrder includes "RowNumber"
//     const updatedColumnOrder = ["RowNumber", ...columnOrder];

//     // Convert JSON data to worksheet
//     const ws = XLSX.utils.json_to_sheet(jsonDataWithRowNumbers, { header: updatedColumnOrder });
//     console.log("🚀 ~ downloadExcel ~ jsonDataWithRowNumbers:", jsonDataWithRowNumbers);

//     // Create a new workbook and append the worksheet
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

//     // Convert the workbook to an array buffer
//     const buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

//     // Create a blob from the buffer and download it
//     const blob = new Blob([buffer], { type: "application/octet-stream" });
//     const url = URL.createObjectURL(blob);
//     const a = document.createElement("a");
//     a.href = url;
//     a.download = `${fileName}.xlsx`;
//     a.click();
//     URL.revokeObjectURL(url);
//   };

//   return (
//     <Button
//       onClick={downloadExcel}
//       variant="primary"
//       style={{ backgroundColor: "#2042d0" }}
//     >
//       Download Excel <FontAwesomeIcon icon={faDownload} className="mx-2" />
//     </Button>
//   );
// };

// export default DownloadExcelButton;
// import React from "react";
// import * as XLSX from "xlsx";
// import { Button } from "@themesberg/react-bootstrap";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faDownload } from '@fortawesome/free-solid-svg-icons';

// const DownloadExcelButton = ({ jsonData, fileName, columnOrder }) => {
//   const downloadExcel = () => {
//     // Add "No" field to each item in jsonData
//     const jsonDataWithNo = jsonData.map((item, index) => ({
//       No: index + 1, // Adding 1 to make it 1-based index
//       ...item
//     }));

//     // Ensure columnOrder includes "No" at the correct position
//     const updatedColumnOrder = ["No", ...columnOrder.filter(col => col !== "No")];

//     // Convert JSON data to worksheet
//     const ws = XLSX.utils.json_to_sheet(jsonDataWithNo, { header: updatedColumnOrder });
//     console.log("🚀 ~ downloadExcel ~ jsonDataWithNo:", jsonDataWithNo);

//     // Create a new workbook and append the worksheet
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

//     // Convert the workbook to an array buffer
//     const buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

//     // Create a blob from the buffer and download it
//     const blob = new Blob([buffer], { type: "application/octet-stream" });
//     const url = URL.createObjectURL(blob);
//     const a = document.createElement("a");
//     a.href = url;
//     a.download = `${fileName}.xlsx`;
//     a.click();
//     URL.revokeObjectURL(url);
//   };

//   return (
//     <Button
//       onClick={downloadExcel}
//       variant="primary"
//       style={{ backgroundColor: "#2042d0" }}
//     >
//       Download Excel <FontAwesomeIcon icon={faDownload} className="mx-2" />
//     </Button>
//   );
// };

// export default DownloadExcelButton;
import React from "react";
import * as XLSX from "xlsx";
import { Button } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const DownloadExcelButton = ({ jsonData = [], fileName = "data", columnOrder = [] }) => {
  const downloadExcel = () => {
    // Check if jsonData and columnOrder are valid
    if (!Array.isArray(jsonData) || jsonData.length === 0 || !Array.isArray(columnOrder)) {
      console.error("Invalid jsonData or columnOrder");
      return;
    }

    // Add "No" field to each item in jsonData
    const jsonDataWithNo = jsonData.map((item, index) => ({
      No: index + 1, // Adding 1 to make it 1-based index
      ...item
    }));

    // Ensure columnOrder includes "No" at the correct position
    const updatedColumnOrder = ["No", ...columnOrder.filter(col => col !== "No")];

    // Convert JSON data to worksheet
    const ws = XLSX.utils.json_to_sheet(jsonDataWithNo, { header: updatedColumnOrder });
    console.log("🚀 ~ downloadExcel ~ jsonDataWithNo:", jsonDataWithNo);

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Convert the workbook to an array buffer
    const buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Create a blob from the buffer and download it
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <Button
      onClick={downloadExcel}
      variant="primary"
      style={{ backgroundColor: "#2042d0" }}
    >
      Download Excel <FontAwesomeIcon icon={faDownload} className="mx-2" />
    </Button>
  );
};

export default DownloadExcelButton;
