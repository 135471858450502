import React, { useState, useEffect, useMemo } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import "react-time-picker/dist/TimePicker.css";
import { Formik, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import DownloadExcelButton from "../pages/downloadExel";
import { MaterialReactTable } from "material-react-table";
import ReactLogo from "../assets/img/technologies/loading-loader-svgrepo-com.svg";
import { Image } from '@themesberg/react-bootstrap';

export const Count = () => {
  const [birthday, setBirthday] = useState("");
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [Editopen, setEditOpen] = useState(false);
  const onEditOpenModal = () => setEditOpen(true);
  const onEditCloseModal = () => setEditOpen(false);

  const [time, setTime] = useState("00:00:00");
  const [myData, setMyData] = useState({
    downloadApp: "",
    activeUser: "",
    youtubeSubscriber: "",
    liveLecture: "",
  });
  const [isError, setIsError] = useState("");
  const [jsonData, setJsonData] = useState({});
  const [editData, setEditData] = useState({});
  const [pagination, setPagination] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const createCount = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/UserCount/create`,
        values,
        {
          headers: { Authorization: `${token}` },
        }
      );
      // setMyData(res.data);
      onCloseModal();
      onEditCloseModal();
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Count has been Added Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getCount();
      // setMyData("");
    } catch (error) {
      setIsError(error.response.data.err.message);
    }
  };

  const SigninSchema = Yup.object().shape({
    downloadApp: Yup.string().required("downloadApp is Required"),
    activeUser: Yup.string().required("active User is Required"),
    youtubeSubscriber: Yup.string().required("youtube Subscribers is Required"),
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "activeUser",
        header: "Active Users",
      },
      {
        accessorKey: "downloadApp",
        header: "App Downloads",
      },
      {
        accessorKey: "youtubeSubscriber",
        header: "youtube Subscribers",
      },
      {
        accessorKey: "liveLecture",
        header: "Live Lecture",
      },

      {
        accessorKey: "image",
        header: "Action",
        Cell: ({ row }) => {
          return (
            <>
              <FontAwesomeIcon
                icon={faEdit}
                onClick={() => handleEdit(row.original)}
              />
              <FontAwesomeIcon
                className="mx-3"
                icon={faTrash}
                onClick={() => handleDelete(row.original._id)}
              />
            </>
          );
        },
      },
    ],
    []
  );

  const jsonDataArray = [jsonData];

  const getCount = async () => {
    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/UserCount/getAll`
      );

      setJsonData(res.data);
      setIsLoadingData(false);
    } catch (error) {
      setIsError(error.response);
      setIsLoadingData(false);
    }
  };

  const handleEdit = (data) => {
    setEditData(data); // Set the data of the selected row
    setEditOpen(true); // Open the modal
  };

  const handleDelete = (id) => {
    // Show the confirmation dialog using SweetAlert
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with the delete operation
        try {
          const token = localStorage.getItem("token");
          const res = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/deleteUserCount/${id}`,
            {
              headers: { Authorization: token },
            }
          );
          console.log(res.data); // Log the response or handle it as required.
          // Optionally, you can refresh the subcategory data after successful deletion.
          // getMySubCategoryData();
          // Show a success message using SweetAlert
          Swal.fire("Deleted!", "Tetsimonial has been deleted.", "success");
          getCount();
        } catch (error) {
          console.error(error.response); // Handle error responses
          // Show an error message using SweetAlert
          Swal.fire("Error", "Failed to delete the Tetsimonial.", "error");
        }
      }
    });
  };

  useEffect(() => {
    getCount();
  }, []);

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Count</h5>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <button className="btn btn-primary" onClick={onOpenModal}>
            Add Count
            <FontAwesomeIcon icon={faPlus} className="mx-2" />
          </button>
          <DownloadExcelButton jsonData={jsonData} fileName="Category" />
        </div>
        <Modal open={open} onClose={onCloseModal} center>
          <h2>Add Testimonials</h2>
          <Formik
            initialValues={myData}
            validationSchema={SigninSchema}
            onSubmit={(values) => {
              createCount(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label> App downloads</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter App Downloads"
                        name="downloadApp"
                        value={values.downloadApp}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.downloadApp &&
                        touched.downloadApp &&
                        errors.downloadApp}
                    </div>
                  </Col>

                  <Col md={12} className="mb-3">
                    <Form.Group id="lastName">
                      <Form.Label>Active Users</Form.Label>
                      <Form.Control
                        class="form-control"
                        id="activeUser"
                        rows="6"
                        placeholder="Enter Active Users"
                        name="activeUser"
                        value={values.activeUser}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <div className="mb-3 text-danger">
                      {errors.activeUser &&
                        touched.activeUser &&
                        errors.activeUser}
                    </div>
                  </Col>

                  <Col md={12} className="mb-3">
                    <Form.Group id="lastName">
                      <Form.Label>Youtube Subscribers</Form.Label>
                      <Form.Control
                        class="form-control"
                        id="activeUser"
                        rows="6"
                        placeholder="Enter Youtube Subscribers"
                        name="youtubeSubscriber"
                        value={values.youtubeSubscriber}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <div className="mb-3 text-danger">
                      {errors.youtubeSubscriber &&
                        touched.youtubeSubscriber &&
                        errors.youtubeSubscriber}
                    </div>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="lastName">
                      <Form.Label>Live Lectures</Form.Label>
                      <Form.Control
                        class="form-control"
                        id="activeUser"
                        rows="6"
                        placeholder="Enter Live Lecture"
                        name="liveLecture"
                        value={values.liveLecture}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="mt-3 flex">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>

        <Modal open={Editopen} onClose={onEditCloseModal} center>
          <h2>Edit Testimonials</h2>
          <Formik
            initialValues={editData}
            validationSchema={SigninSchema}
            onSubmit={(values) => {
              createCount(values); // Pass the ID and updated data to editTetsimonial
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label> App downloads</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter App Downloads"
                        name="downloadApp"
                        value={values.downloadApp}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.downloadApp &&
                        touched.downloadApp &&
                        errors.downloadApp}
                    </div>
                  </Col>

                  <Col md={12} className="mb-3">
                    <Form.Group id="lastName">
                      <Form.Label>Active Users</Form.Label>
                      <Form.Control
                        class="form-control"
                        id="activeUser"
                        rows="6"
                        placeholder="Enter Active Users"
                        name="activeUser"
                        value={values.activeUser}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <div className="mb-3 text-danger">
                      {errors.activeUser &&
                        touched.activeUser &&
                        errors.activeUser}
                    </div>
                  </Col>

                  <Col md={12} className="mb-3">
                    <Form.Group id="lastName">
                      <Form.Label>Youtube Subscribers</Form.Label>
                      <Form.Control
                        class="form-control"
                        id="activeUser"
                        rows="6"
                        placeholder="Enter Youtube Subscribers"
                        name="youtubeSubscriber"
                        value={values.youtubeSubscriber}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <div className="mb-3 text-danger">
                      {errors.youtubeSubscriber &&
                        touched.youtubeSubscriber &&
                        errors.youtubeSubscriber}
                    </div>
                  </Col>

                  <Col md={12} className="mb-3">
                    <Form.Group id="lastName">
                      <Form.Label>Live Lectures</Form.Label>
                      <Form.Control
                        class="form-control"
                        id="activeUser"
                        rows="6"
                        placeholder="Enter Live Lecture"
                        name="liveLecture"
                        value={values.liveLecture}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
        <div>
          {isLoadingData ? (
            <div className="loader-container">
          <Image className="loader-element animate__animated animate__jackInTheBox" src={ReactLogo} height={40} />
          </div>
          ) : (
            <MaterialReactTable
          autoResetPageIndex={false}
          columns={columns}
          data={jsonDataArray}
          enableSorting={false}
        />
          )}
        </div>
       
      </Card.Body>
    </Card>
  );
};
