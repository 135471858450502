import React, { useState, useEffect, useMemo, useRef } from "react";
import moment from "moment-timezone";
import JoditEditor from "jodit-react";
import Datetime from "react-datetime";
import { Row, Col, Button, Form as BootstrapForm ,FormCheck} from "react-bootstrap";
import {
  // Col,
  // Row,
  Card,
  Form,
  // Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import DownloadExcelButton from "./downloadExel";
import { MDBDataTable } from "mdbreact";
import { useLocation } from "react-router-dom";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
import Select from "react-select";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ReactLogo from "../assets/img/technologies/loading-loader-svgrepo-com.svg";
import { Image } from "@themesberg/react-bootstrap";
export const CouponCode = () => {
  const editor = useRef(null);
  // Add Coupon
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  // Add Book Order
  const [open1, setOpen1] = useState(false);
  const onOpenModal1 = () => setOpen1(true);
  const onCloseModal1 = () => setOpen1(false);

  
  // edit Book order
  const [Editopen1, setEditOpen1] = useState(false);
  const onEditOpenModal1 = () => setEditOpen1(true);
  const onEditCloseModal1 = () => setEditOpen1(false);

  // edit Coupon
  const [Editopen, setEditOpen] = useState(false);
  const onEditOpenModal = () => setEditOpen(true);
  const onEditCloseModal = () => setEditOpen(false);

  // Add Offer
  const [openOffer, setOpenOffer] = useState(false);
  const onOpenOfferModal = () => setOpenOffer(true);
  const onCloseOffereModal = () => setOpenOffer(false);

  // Edit Offer
  const [EditOfferopen, setEditOfferOpen] = useState(false);
  const onEditOpenOfferModal = () => setEditOfferOpen(true);
  const onEditCloseOfferModal = () => setEditOfferOpen(false);

  const [jsonData, setJsonData] = useState([]);
  const [SubscriptionData, setMySubscriptionData] = useState([]);
  const [code, setCode] = useState("");
  const [discountPrize, setDiscountPrize] = useState(0);

  const [value, setValue] = useState("1");
  const [editOfferData1, setEdiOfferData1] = useState({});

  const [items, setItems] = useState([]); // Initialize items state
  const [myData, setMyData] = useState({
    code: "",
    discountPrize: "",
    isSingleUse: false,
    isMultipleUse: false,
  });
  const [myData1, setMyData1] = useState({
    discount: "",

  });
  const [myOfferData, setMyOfferData] = useState({
    subscription_id: [],
    description: "",
    discountPrize: "",
    discountPercentage: "",
    isSingleUse: false,
    isMultipleUse: false,
  });
  const [myofferData, setMyofferData] = useState({
    discountPrize: "",
    discountPercentage: "",
    isSingleUse: "",
    isMultipleUse: "",
  });

  const [editCoupanData, setEditCoupanData] = useState({
    subscription_id: [], // Initialize as an empty array
    code: "",
    discountPrize: "",
    isSingleUse: false,
    isMultipleUse: false,
    // Add other fields as necessary
  });
  const [editBookOrderData1, setEditBookOrderData1]= useState({
    // Initialize as an empty array
    discount: "",
  
  });
  const [editOfferData, setEditOfferData] = useState({
    // subscription_id: [], // Initialize as an empty array
    // code: "",
    // discountPrize: "",
    // Add other fields as necessary
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [isError, setIsError] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(true);

  const [editData, setEditData] = useState({});
  // const [editOfferData, setEdiOfferData] = useState({});
  const history = useHistory();
  useEffect(() => {
    getMySubscriptionData();
    getMyCouponCodeData();
  }, []);

  const navigateDetails = (userId) => {
    history.push(`/topics?id=${userId}`);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "code",
        header: "Coupon Code",
      },
      {
        accessorKey: "discountPrize",
        header: "Discount Amount",
      },

      {
        accessorKey: "isSingleUse",
        header: "SingleUse",
      },

      {
        accessorKey: "isMultipleUse",
        header: "MultipleUse",
      },
      {
        accessorKey: "actions",
        header: "actions",
      },
    ],
    []
    //end
  );
  const columns2 = useMemo(
    () => [
      {
        accessorKey: "discountPrize",
        header: "DiscountPrize",
      },
      {
        accessorKey: "discountPercentage",
        header: "Discount Percentage",
      },
      {
        accessorKey: "description",
        header: "Description",
      },
      {
        accessorKey: "isSingleUse",
        header: "SingleUse",
      },

      {
        accessorKey: "isMultipleUse",
        header: "MultipleUse",
      },

      {
        accessorKey: "actions",
        header: "Actions",
      },
    ],
    []
    //end
  );
  const columns3 = useMemo(
    () => [
      {
        accessorKey: "discount",
        header: "discount",
      },

      {
        accessorKey: "actions",
        header: "Actions",
      },
    ],
    []
    //end
  );
  const handleToggleActive = (event, item, field) => {
    const updatedItem = { ...item, [field]: event.target.checked };
    const updatedItems = items.map((i) => (i.id === item.id ? updatedItem : i));
    setItems(updatedItems); // Update the state with the modified items array
  };

  const handleToggleActiveCoupon = (event, item, field) => {
    const updatedItem = { ...item, [field]: event.target.checked };
    const updatedItems = items.map((i) => (i.id === item.id ? updatedItem : i));
    setItems(updatedItems); // Update the state with the modified items array
  };

  const getMySubscriptionData = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Subscription/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      setMySubscriptionData(res.data);
      setIsLoadingData(false);
    } catch (error) {
      setIsError(error.response);
    }
  };

  const getMyCouponCodeData = async () => {
    const token = localStorage.getItem("token");
    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Coupon/getAll`,
        {
          headers: { Authorization: token },
        }
      );
console.log(res,"coupan code");
      const data = res.data?.map((item, index) => {
        return {
          id: index + 1,
          code: item.code,
          discountPrize: item.discountPrize,
          isSingleUse: (
            <input
              type="checkbox"
              style={{ margin: "10px -10px 0 0" }}
              checked={item.isSingleUse}
              onChange={(event) => handleToggleActiveCoupon(event, item)}
            />
          ),
          isSingleUseText: item.isSingleUse ? "True" : "False",

          isMultipleUse: (
            <input
              type="checkbox"
              style={{ margin: "10px -10px 0 0" }}
              checked={item.isMultipleUse}
              onChange={(event) => handleToggleActiveCoupon(event, item)}
            />
          ),
          isMultipleUseText: item.isMultipleUse ? "True" : "False",
          _id: item._id,
          actions: (
            <div>
              <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(item)} />

              <FontAwesomeIcon
                className="mx-3"
                icon={faTrash}
                onClick={() => handleDelete(item._id)}
              />
            </div>
          ),
        };
      });

      setJsonData(data);
      setIsLoadingData(false);
      // setDatatable((prevState) => ({
      //   ...prevState,
      //   rows: data,
      // }));
    } catch (error) {
      setIsError(error.response);
      setIsLoadingData(false);
    }
  };
  // const getMyBookOfferData = async () => {
  //   const token = localStorage.getItem("token");
  //   setIsLoadingData(true);
  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/api/getBookOffer`,
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );
  //     console.log(res, "&&&&&&&&&&&&&&&&&&&&");
  
  //     const data = res.data?.map((item, index) => {
  //       return {
  //         id: index + 1,
  //         discount: item.discount,
  //         actions: (
  //           <div>
  //             <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit1(item)} />
  //             <FontAwesomeIcon
  //               className="mx-3"
  //               icon={faTrash}
  //               onClick={() => handleDelete(item._id)}
  //             />
  //           </div>
  //         ),
  //       };
  //     });
  
  //     console.log("Mapped Data: ", data); // Log the mapped data
  
  //     setJsonData(data);
  //   } catch (error) {
  //     console.error("Error fetching data: ", error); // Log the error
  //     setIsError(error.response);
  //   } finally {
  //     setIsLoadingData(false);
  //   }
  // };
  

  const getMyOfferData = async () => {
    const token = localStorage.getItem("token");
    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Offer/getAll`,
        {
          headers: { Authorization: token },
        }
      );

      const data = res.data?.map((item, index) => {
        return {
          id: index + 1,
          discountPrize: item.discountPrize,
          discountPercentage: item.discountPercentage,
          description: item.description,

          isSingleUse: (
            <input
              type="checkbox"
              style={{ margin: "10px -10px 0 0" }}
              checked={item.isSingleUse}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          isSingleUseText: item.isSingleUse ? "True" : "False",

          isMultipleUse: (
            <input
              type="checkbox"
              style={{ margin: "10px -10px 0 0" }}
              checked={item.isMultipleUse}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          isMultipleUseText: item.isMultipleUse ? "True" : "False",

          _id: item._id,
          actions: (
            <div>
              <FontAwesomeIcon
                icon={faEdit}
                onClick={() => handleEditOffer(item)}
              />

              <FontAwesomeIcon
                className="mx-3"
                icon={faTrash}
                onClick={() => handleDeleteOffer(item._id)}
              />
            </div>
          ),
        };
      });

      setJsonData(data);
      // setDatatable((prevState) => ({
      //   ...prevState,
      //   rows: data,
      // }));
    } catch (error) {
      setIsError(error.response);
      setIsLoadingData(false);
    }
  };

  const addCoupon = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/Coupon/create`,
        values,
        {
          headers: { Authorization: `${token}` },
        }
      );
      setMyData(res.data);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Coupon Added Successfully ",
        showConfirmButton: false,
        timer: 1500,
      });
      getMyCouponCodeData();
      onCloseModal();
      setMyData({
        code: "",
        discountPrize: "",
        isSingleUse: false,
        isMultipleUse: false,
      });
    } catch (error) {
      setIsError(error.response.data.err.message);
    }
  };
  const addBookOrder = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/createBookOffer`,
        values,
        {
          headers: { Authorization: `${token}` },
        }
      );
      console.log(res,"addbookorderrrrrrrrrrrrr");
      setMyData1(res.data);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Book Order Added Successfully ",
        showConfirmButton: false,
        timer: 1500,
      });
      getMyCouponCodeData();
      onCloseModal();
      setMyData1({
        discount: "",
     
      });
    } catch (error) {
      setIsError(error.response.data.err.message);
    }
  };

  const addOffer = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/Offer/create`,
        values,
        {
          headers: { Authorization: `${token}` },
        }
      );
      setMyOfferData(res.data);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Offer Added Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getMyOfferData();
      onCloseOffereModal();
      setMyOfferData({
        subscription_id: [],
        description: "",
        discountPrize: "",
        discountPercentage: "",
        isSingleUse: false,
        isMultipleUse: false,
      });
    } catch (error) {
      setIsError(error.response.data.err.message);
    }
  };

  const updateCoupon = async (id, updatedData) => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/updateCoupon/${id}`,
        updatedData,
        {
          headers: { Authorization: token },
        }
      );
      console.log("🚀 ~ updateCoupon ~ res:", res);
      console.log(res.data); // Log the response or handle it as required.

      onEditCloseModal(); // Close the modal after successful update.
      Swal.fire({
        position: "center",
        icon: "success",
        title: " Coupon Code Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getMyCouponCodeData();
    } catch (error) {
      console.error(error.response); // Handle error responses
    }
  };
  
  // const updateBookOrder = async (id, updatedData) => {
  //   try {
  //     const token = localStorage.getItem("token");
  //     const res = await axios.put(
  //       `${process.env.REACT_APP_BASE_URL}/api/createBookOffer/${id}`,
  //       updatedData,
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );
  //     console.log("🚀 ~ updateCoupon ~ res:", res);
  //     console.log(res.data); // Log the response or handle it as required.

  //     onEditCloseModal1(); // Close the modal after successful update.
  //     Swal.fire({
  //       position: "center",
  //       icon: "success",
  //       title: " Book Order Updated Successfully",
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //     getMyBookOfferData();
  //   } catch (error) {
  //     console.error(error.response); // Handle error responses
  //   }
  // };

  const updateOffer = async (id, updatedData) => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/updateOffer/${id}`,
        updatedData,
        {
          headers: { Authorization: token },
        }
      );
      console.log("🚀 ~ updateCoupon ~ res:", res);
      console.log(res.data); // Log the response or handle it as required.

      onEditCloseOfferModal(); // Close the modal after successful update.
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Offer Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getMyOfferData();
    } catch (error) {
      console.error(error.response); // Handle error responses
    }
  };

  const handleDelete = (id) => {
    // Show the confirmation dialog using SweetAlert
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with the delete operation
        try {
          const token = localStorage.getItem("token");
          const res = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/deleteCoupon/${id}`,
            {
              headers: { Authorization: token },
            }
          );
          console.log(res.data); // Log the response or handle it as required.
          // Optionally, you can refresh the subcategory data after successful deletion.
          // getMySubCategoryData();
          // Show a success message using SweetAlert
          Swal.fire("Deleted!", "Your Coupon  has been deleted.", "success");
          getMyCouponCodeData();
        } catch (error) {
          console.error(error.response); // Handle error responses
          // Show an error message using SweetAlert
          Swal.fire("Error", "Failed to delete the Coupon.", "error");
        }
      }
    });
  };
  const token = localStorage.getItem("token");
  const getMyBookOrderData = async (values) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/createBookOffer`,
        { ...values },
        {
          headers: { Authorization: `${token}` },
        }
      );
      console.log("🚀 ~ getMyPostDataAAAAAAAAAA ~ res:", res)

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Book Offer updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      setIsError(error.response.data.err.message);
    }
  };
  const handleDeleteOffer = (id) => {
    // Show the confirmation dialog using SweetAlert
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with the delete operation
        try {
          const token = localStorage.getItem("token");
          const res = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/deleteOffer/${id}`,
            {
              headers: { Authorization: token },
            }
          );
          console.log(res.data); // Log the response or handle it as required.
          // Optionally, you can refresh the subcategory data after successful deletion.
          // getMySubCategoryData();
          // Show a success message using SweetAlert
          Swal.fire("Deleted!", "Your Offer  has been deleted.", "success");
          getMyOfferData();
        } catch (error) {
          console.error(error.response); // Handle error responses
          // Show an error message using SweetAlert
          Swal.fire("Error", "Failed to delete the Offer.", "error");
        }
      }
    });
  };
  const getBookOrderData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getBookOffer`,
        {
          headers: { Authorization: token },
        }
      );
      setMyNewData(res.data);
      setIsLoadingData(true);
    } catch (error) {
      setIsError(error.response);
      setIsLoadingData(true);
    }
  };
  const [myNewData, setMyNewData] = useState({
    discount: "",
  
  });
  const SigninNewSchema = Yup.object().shape({
    discount: Yup.string().required("discount is required"),
    
  });
  const handleChange = (event, newValue) => {
    if (newValue === "2") {
      getMyOfferData(newValue);
    }
    if (newValue === "3") {
      getMyCouponCodeData(newValue);
    } else {
      // getMyBookOfferData(newValue);
    }
    setValue(newValue);
  };

  const handleEdit = (data) => {
    setEditCoupanData({
      ...data,
      subscription_id: data.subscription_id || [], // Ensure subscription_id is an array
    });
    setEditOpen(true); // Open the modal
  };

  const handleEdit1 = (data) => {
    setEditBookOrderData1({data});
    setEditOpen1(true); // Open the modal
  };
  const handleEditOffer = (data) => {
    setEditOfferData(data); // Set the data of the selected row
    setEditOfferOpen(true); // Open the modal
  };

  const tabListStyles = {
    display: "flex",
    justifyContent: "center",
    background: "#f5f5f5",
    padding: "10px",
    borderRadius: "5px",
  };

  const tabStyles = {
    fontSize: "18px",
    fontWeight: "bold",
    textTransform: "uppercase",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "5px",
    cursor: "pointer",
    color: "#333",
    background: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "background 0.3s ease-in-out",
  };

  const activeTabStyles = {
    background: "#007bff", // Change this to the desired background color for the active tab
    color: "#fff", // Change this to the desired text color for the active tab
  };
  const OfferSchema = Yup.object()
    .shape({
      subscription_id: Yup.array()
        .min(1, "Select at least one subscription plan")
        .required("Subscription plan is required"),
      description: Yup.string().required("Description is required"),
      discountPrize: Yup.number().nullable(true),
      discountPercentage: Yup.number().nullable(true),
      isSingleUse: Yup.boolean(),
      isMultipleUse: Yup.boolean(),
    })
    .test(
      "one-of-two",
      "Either discountPrize or discountPercentage must be provided",
      function (value) {
        const { discountPrize, discountPercentage } = value;
        if (!discountPrize && !discountPercentage) {
          return this.createError({
            path: "discountPrize",
            message:
              "Either discountPrize or discountPercentage must be provided",
          });
        }
        return true;
      }
    );

  const SigninSchema = Yup.object().shape({
    discountPrize: Yup.string().required("discountPrize  is Required"),
    code: Yup.string().required("code  is Required"),
    isSingleUse: Yup.boolean(),
    isMultipleUse: Yup.boolean(),
  });
  const SigninSchema1 = Yup.object().shape({
    discount: Yup.string().required("discount  is Required"),

  });

  const EditSchema = Yup.object().shape({
    code: Yup.string().required("Coupon Code  is Required"),
    discountPrize: Yup.string().required("Discount Amount is Required"),
  });

  const EditSchema1 = Yup.object().shape({
    // discountPrize: Yup.string().required("Discount Prize is required"),
    // discountPercentage: Yup.string().required("Discount Percentage is required"),
    // isSingleUse: Yup.boolean().required("Single Use is required"),
    // isMultipleUse: Yup.boolean().required("Multiple Use is required"),
    description: Yup.string().required("Description is required"),
  });
  useEffect(() => {
    if (value === "1") {
      getMyCouponCodeData();
    }
    if (value === "2") {
      getMyOfferData();
    } else if (value === "3") {
      // getMyBookOfferData();
    }
    getBookOrderData();
  }, [value]);
  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Coupon Code</h5>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="">
            <button className="btn btn-primary " onClick={onOpenModal}>
              Add Coupon Code
              <FontAwesomeIcon icon={faPlus} className="mx-2" />
            </button>
            <button
              className="btn btn-primary"
              style={{ marginLeft: 5 }}
              onClick={onOpenOfferModal}
            >
              Add Offer
              <FontAwesomeIcon icon={faPlus} className="mx-2" />
            </button>
            {/* <button className="btn btn-primary " onClick={onOpenModal1}>
              Add Book Order
              <FontAwesomeIcon icon={faPlus} className="mx-2" />
            </button> */}
          </div>
          <DownloadExcelButton jsonData={jsonData} fileName="Couponcode" />
        </div>

        <Modal open={open} onClose={onCloseModal} center>
          <h2 className="md-12">Add Coupon Code</h2>
          <Formik
            initialValues={myData}
            validationSchema={SigninSchema}
            onSubmit={(values) => {
              addCoupon(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Form>
                <Row>
                  <Row>
                    <Col md={12} className="mb-3">
                      <Form.Group id="gender">
                        <Form.Label>Select SubScription Plan</Form.Label>
                        <Select
                          name="subscription_id"
                          options={SubscriptionData.map((item) => ({
                            value: item.sid,
                            label: item.plan_name,
                          }))}
                          isMulti
                          onChange={(selectedOptions) => {
                            setFieldValue(
                              "subscription_id",
                              selectedOptions.map((option) => option.value)
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group id="lastName">
                        <Form.Label>Coupon Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Coupon Code"
                          name="code"
                          value={values.code}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <div className="mb-3 text-danger">
                        {errors.code && touched.code && errors.code}
                      </div>
                    </Col>

                    <Col md={6} className="mb-3">
                      <Form.Group id="lastName">
                        <Form.Label>Discount Amount</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Discount Amount"
                          name="discountPrize"
                          value={values.discountPrize}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <div className="mb-3 text-danger">
                        {errors.discountPrize &&
                          touched.discountPrize &&
                          errors.discountPrize}
                      </div>
                    </Col>
                  </Row>
                  <Col md={6} className={values.isSingleUse ? "disabled" : ""}>
                    <BootstrapForm.Group id="isSingleUse">
                      <BootstrapForm.Label> Single Use</BootstrapForm.Label>
                      <BootstrapForm.Check
                        type="checkbox"
                        name="isSingleUse"
                        checked={values.isSingleUse}
                        onChange={handleChange}
                        disabled={Boolean(values.isMultipleUse)}
                      />
                    </BootstrapForm.Group>
                    {errors.isSingleUse && touched.isSingleUse && (
                      <div className="text-danger">{errors.isSingleUse}</div>
                    )}
                  </Col>

                  <Col
                    md={6}
                    className={values.isMultipleUse ? "disabled" : ""}
                  >
                    <BootstrapForm.Group id="isMultipleUse">
                      <BootstrapForm.Label> Multiple Use</BootstrapForm.Label>
                      <BootstrapForm.Check
                        type="checkbox"
                        name="isMultipleUse"
                        checked={values.isMultipleUse}
                        onChange={handleChange}
                        disabled={Boolean(values.isSingleUse)}
                      />
                    </BootstrapForm.Group>
                    {errors.isMultipleUse && touched.isMultipleUse && (
                      <div className="text-danger">{errors.isMultipleUse}</div>
                    )}
                  </Col>
                </Row>

                <div className="mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
        <Modal open={open1} onClose={onCloseModal1} center>
          <h2 className="md-12">Add Book Order</h2>
          <Formik
            initialValues={myData1}
            validationSchema={SigninSchema1}
            onSubmit={(values) => {
              addBookOrder(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Form>
                <Row>
                  <Row>
                  <Col md={12} className="mb-3">
                    <BootstrapForm.Group id="discount">
                      <BootstrapForm.Label>discount</BootstrapForm.Label>
                      <BootstrapForm.Control
                        type="text"
                        placeholder="Enter discount"
                        name="discount"
                        value={values.discount}
                        onChange={handleChange}
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.discount &&
                        touched.discount &&
                        errors.discount}
                    </div>
                  </Col>
                  </Row>
                </Row>

                <div className="mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
        <Modal open={Editopen} onClose={onEditCloseModal} center>
          <h2>Edit Coupon Code</h2>
          <Formik
            initialValues={editCoupanData}
            validationSchema={EditSchema}
            onSubmit={(values) => {
              updateCoupon(editCoupanData._id, values); // Pass the ID and updated data to updateCoupon
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Form>
                <Row>
                  <Row>
                    <Col md={12} className="mb-3">
                      <Form.Group id="gender">
                        <Form.Label>Select Subscription Plan</Form.Label>
                        <Select
                          name="subscription_id"
                          value={
                            Array.isArray(values.subscription_id)
                              ? values.subscription_id.map((id) => ({
                                  value: id,
                                  label:
                                    SubscriptionData.find(
                                      (item) => item.sid === id
                                    )?.plan_name || "",
                                }))
                              : []
                          }
                          options={SubscriptionData.map((item) => ({
                            value: item.sid,
                            label: item.plan_name,
                          }))}
                          isMulti
                          onChange={(selectedOptions) => {
                            setFieldValue(
                              "subscription_id",
                              selectedOptions.map((option) => option.value)
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group id="firstName">
                        <Form.Label>Coupon Code </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Coupon Code"
                          name="code"
                          value={values.code}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <div className="mb-3 text-danger">
                        {errors.code && touched.code && errors.code}
                      </div>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="firstName">
                        <Form.Label>Discount Amount </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Discount Amount"
                          name="discountPrize"
                          value={values.discountPrize}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <div className="mb-3 text-danger">
                        {errors.discountPrize &&
                          touched.discountPrize &&
                          errors.discountPrize}
                      </div>
                    </Col>
                    <Col md={6}>
                      <BootstrapForm.Group id="isSingleUse">
                        <BootstrapForm.Label>Is Single Use</BootstrapForm.Label>
                        <BootstrapForm.Check
                          type="checkbox"
                          name="isSingleUse"
                          checked={values.isSingleUse}
                          onChange={handleChange}
                        />
                      </BootstrapForm.Group>
                      <div className="mb-3 text-danger">
                        {errors.isSingleUse &&
                          touched.isSingleUse &&
                          errors.isSingleUse}
                      </div>
                    </Col>
                    <Col md={6}>
                      <BootstrapForm.Group id="isMultipleUse">
                        <BootstrapForm.Label>
                          Is Multiple Use
                        </BootstrapForm.Label>
                        <BootstrapForm.Check
                          type="checkbox"
                          name="isMultipleUse"
                          checked={values.isMultipleUse}
                          onChange={handleChange}
                        />
                      </BootstrapForm.Group>
                      <div className="mb-3 text-danger">
                        {errors.isMultipleUse &&
                          touched.isMultipleUse &&
                          errors.isMultipleUse}
                      </div>
                    </Col>
                  </Row>
                </Row>
                <div className="mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
        {/* <Modal open={Editopen1} onClose={onEditCloseModal1} center>
          <h2>Edit Book Order</h2>
          <Formik
            initialValues={editCoupanData}
            validationSchema={EditSchema}
            onSubmit={(values) => {
              updateBookOrder(editCoupanData._id, values); // Pass the ID and updated data to updateCoupon
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
               and other goodies 
            }) => (
              <Form>
                <Row>
                  
                  <Row>
                   
                    <Col md={6} className="mb-3">
                      <Form.Group id="firstName">
                        <Form.Label>Discount</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter discount"
                          name="discount"
                          value={values.discount}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <div className="mb-3 text-danger">
                        {errors.discount &&
                          touched.discount &&
                          errors.discount}
                      </div>
                    </Col>
                   
                   
                  </Row>
                </Row>
                <div className="mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal> */}
        <Modal open={openOffer} onClose={onCloseOffereModal} center>
          <h2 className="md-12">Add Offer Details</h2>
          <Formik
            initialValues={myOfferData}
            validationSchema={OfferSchema}
            onSubmit={(values) => {
              addOffer(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form>
                <Row>
                  <Col md={12} className="mb-3">
                    <BootstrapForm.Group id="subscription">
                      <BootstrapForm.Label>
                        Select Subscription Plan
                      </BootstrapForm.Label>
                      <Select
                        name="subscription_id"
                        options={SubscriptionData.map((item) => ({
                          value: item.sid,
                          label: item.plan_name,
                        }))}
                        isMulti
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions.map(
                            (option) => option.value
                          );
                          setFieldValue("subscription_id", selectedValues);
                        }}
                      />
                    </BootstrapForm.Group>
                    {errors.subscription_id && touched.subscription_id && (
                      <div className="text-danger">
                        {errors.subscription_id}
                      </div>
                    )}
                  </Col>
                  <Col md={12} className="mb-3">
                    <BootstrapForm.Group id="description">
                      <BootstrapForm.Label>Description</BootstrapForm.Label>
                      <BootstrapForm.Control
                        type="text"
                        placeholder="Enter Description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </div>
                  </Col>
                  

                  {/* <Col md={12} className="mb-3">
                <BootstrapForm.Group id="description">
                  <BootstrapForm.Label>Description</BootstrapForm.Label>
                  <JoditEditor
                    ref={editor}
                    value={values.description}
                    onBlur={(newContent) => setFieldValue('description', newContent)}
                    onChange={(newContent) => setFieldValue('description', newContent)}
                    tabIndex={1}
                  />
                </BootstrapForm.Group>
                {errors.description && touched.description && (
                  <div className="text-danger">{errors.description}</div>
                )}
              </Col> */}

                  <Col
                    md={6}
                    className={values.discountPrize ? "disabled" : ""}
                  >
                    <BootstrapForm.Group id="discountPrize">
                      <BootstrapForm.Label>Discount Prize</BootstrapForm.Label>
                      <BootstrapForm.Control
                        type="text"
                        placeholder="Discount Prize"
                        name="discountPrize"
                        value={values.discountPrize}
                        onChange={handleChange}
                        disabled={Boolean(values.discountPercentage)}
                      />
                    </BootstrapForm.Group>
                    {errors.discountPrize && touched.discountPrize && (
                      <div className="text-danger">{errors.discountPrize}</div>
                    )}
                  </Col>

                  <Col
                    md={6}
                    className={values.discountPercentage ? "disabled" : ""}
                  >
                    <BootstrapForm.Group id="discountPercentage">
                      <BootstrapForm.Label>
                        Discount Percentage
                      </BootstrapForm.Label>
                      <BootstrapForm.Control
                        type="text"
                        placeholder="Discount Percentage"
                        name="discountPercentage"
                        value={values.discountPercentage}
                        onChange={handleChange}
                        disabled={Boolean(values.discountPrize)}
                      />
                    </BootstrapForm.Group>
                    {errors.discountPercentage &&
                      touched.discountPercentage && (
                        <div className="text-danger">
                          {errors.discountPercentage}
                        </div>
                      )}
                  </Col>

                  <Col md={6} className={values.isSingleUse ? "disabled" : ""}>
                    <BootstrapForm.Group id="isSingleUse">
                      <BootstrapForm.Label>Is Single Use</BootstrapForm.Label>
                      <BootstrapForm.Check
                        type="checkbox"
                        name="isSingleUse"
                        checked={values.isSingleUse}
                        onChange={handleChange}
                        disabled={Boolean(values.isMultipleUse)}
                      />
                    </BootstrapForm.Group>
                    {errors.isSingleUse && touched.isSingleUse && (
                      <div className="text-danger">{errors.isSingleUse}</div>
                    )}
                  </Col>

                  <Col
                    md={6}
                    className={values.isMultipleUse ? "disabled" : ""}
                  >
                    <BootstrapForm.Group id="isMultipleUse">
                      <BootstrapForm.Label>Is Multiple Use</BootstrapForm.Label>
                      <BootstrapForm.Check
                        type="checkbox"
                        name="isMultipleUse"
                        checked={values.isMultipleUse}
                        onChange={handleChange}
                        disabled={Boolean(values.isSingleUse)}
                      />
                    </BootstrapForm.Group>
                    {errors.isMultipleUse && touched.isMultipleUse && (
                      <div className="text-danger">{errors.isMultipleUse}</div>
                    )}
                  </Col>

                  <div className="mt-3">
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                </Row>
              </Form>
            )}
          </Formik>
          {isError && <div className="text-danger">{isError}</div>}
        </Modal>

        <Modal open={EditOfferopen} onClose={onEditCloseOfferModal}>
          <h2>Edit Offer</h2>
          <Formik
            initialValues={editOfferData}
            validationSchema={EditSchema1}
            onSubmit={(values) => {
              updateOffer(editOfferData._id, values); // Pass the ID and updated data to updateOffer
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12} className="mb-3">
                    <BootstrapForm.Group id="subscription_id">
                      <BootstrapForm.Label>
                        Select Subscription Plan
                      </BootstrapForm.Label>
                      <Select
                        name="subscription_id"
                        value={values.subscription_id.map((id) => ({
                          value: id,
                          label:
                            SubscriptionData.find((item) => item.sid === id)
                              ?.plan_name || "",
                        }))}
                        options={SubscriptionData.map((item) => ({
                          value: item.sid,
                          label: item.plan_name,
                        }))}
                        isMulti
                        onChange={(selectedOptions) => {
                          setFieldValue(
                            "subscription_id",
                            selectedOptions.map((option) => option.value)
                          );
                        }}
                      />
                    </BootstrapForm.Group>
                  </Col>
                </Row>
                <Row>
                  {/* <Col md={12} className="mb-3">
                    <BootstrapForm.Group id="description">
                      <BootstrapForm.Label>Description</BootstrapForm.Label>
                      <JoditEditor
                        ref={editor}
                        value={values.description}
                        onBlur={(newContent) =>
                          setFieldValue("description", newContent)
                        }
                        onChange={(newContent) =>
                          setFieldValue("description", newContent)
                        } // Update Formik state on change
                        tabIndex={1} // tabIndex of textarea
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </div>
                  </Col> */}
                  <Col md={12} className="mb-3">
                    <BootstrapForm.Group id="description">
                      <BootstrapForm.Label>Description</BootstrapForm.Label>
                      <BootstrapForm.Control
                        type="text"
                        placeholder="Enter Description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </div>
                  </Col>
                </Row>
                
                <Row>
                  {/* <Col md={12} className="mb-3">
                <BootstrapForm.Group id="offerAmount">
                  <BootstrapForm.Label>Offer Amount</BootstrapForm.Label>
                  <BootstrapForm.Control
                    type="text"
                    placeholder="Enter Offer Amount"
                    name="offerAmount"
                    value={values.offerAmount}
                    onChange={handleChange}
                  />
                </BootstrapForm.Group>
              </Col> */}
                </Row>
                <Row>
                  <Col
                    md={6}
                    className={values.discountPrize ? "disabled" : ""}
                  >
                    <BootstrapForm.Group id="discountPrize">
                      <BootstrapForm.Label>Discount Prize</BootstrapForm.Label>
                      <BootstrapForm.Control
                        type="text"
                        placeholder="Enter Discount Prize"
                        name="discountPrize"
                        value={values.discountPrize}
                        onChange={handleChange}
                        disabled={Boolean(values.discountPercentage)}
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.discountPrize &&
                        touched.discountPrize &&
                        errors.discountPrize}
                    </div>
                  </Col>
                  <Col
                    md={6}
                    className={values.discountPercentage ? "disabled" : ""}
                  >
                    <BootstrapForm.Group id="discountPercentage">
                      <BootstrapForm.Label>
                        Discount Percentage
                      </BootstrapForm.Label>
                      <BootstrapForm.Control
                        type="text"
                        placeholder="Enter Discount Percentage"
                        name="discountPercentage"
                        value={values.discountPercentage}
                        onChange={handleChange}
                        disabled={Boolean(values.discountPrize)}
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.discountPercentage &&
                        touched.discountPercentage &&
                        errors.discountPercentage}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <BootstrapForm.Group id="isSingleUse">
                      <BootstrapForm.Label>Is Single Use</BootstrapForm.Label>
                      <BootstrapForm.Check
                        type="checkbox"
                        name="isSingleUse"
                        checked={values.isSingleUse}
                        onChange={handleChange}
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.isSingleUse &&
                        touched.isSingleUse &&
                        errors.isSingleUse}
                    </div>
                  </Col>
                  <Col md={6}>
                    <BootstrapForm.Group id="isMultipleUse">
                      <BootstrapForm.Label>Is Multiple Use</BootstrapForm.Label>
                      <BootstrapForm.Check
                        type="checkbox"
                        name="isMultipleUse"
                        checked={values.isMultipleUse}
                        onChange={handleChange}
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.isMultipleUse &&
                        touched.isMultipleUse &&
                        errors.isMultipleUse}
                    </div>
                  </Col>
                </Row>
                <div className="mt-3">
                  <Button variant="primary" type="submit">
                    Update
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>

        {/* <MDBDataTable striped bordered small data={datatable} /> */}

        <TabContext value={value}>
          <TabList style={tabListStyles} onChange={handleChange}>
            <Tab
              style={
                value === "1" ? { ...tabStyles, ...activeTabStyles } : tabStyles
              }
              label="Coupon"
              value="1"
            />
            <Tab
              style={
                value === "2" ? { ...tabStyles, ...activeTabStyles } : tabStyles
              }
              label="Offer"
              value="2"
            />
            <Tab
              style={
                value === "3" ? { ...tabStyles, ...activeTabStyles } : tabStyles
              }
              label=" Book Offer"
              value="3"
            />
          </TabList>
          <TabPanel value="1">
            <div>
              {isLoadingData ? (
                <div className="loader-container">
                  <Image
                    className="loader-element animate__animated animate__jackInTheBox"
                    src={ReactLogo}
                    height={40}
                  />
                </div>
              ) : (
                <MaterialReactTable
                  autoResetPageIndex={false}
                  columns={columns}
                  data={jsonData}
                  enableRowOrdering
                  enableSorting={false}
                  muiTableBodyRowDragHandleProps={({ table }) => ({
                    onDragEnd: () => {
                      const { draggingRow, hoveredRow } = table.getState();
                      if (hoveredRow && draggingRow) {
                        jsonData.splice(
                          hoveredRow.index,
                          0,
                          jsonData.splice(draggingRow.index, 1)[0]
                        );
                        setJsonData([...jsonData]);
                        console.log("new jsonData", jsonData);
                        let position_data = {
                          Coupon_id: [],
                          position: [],
                        };
                        jsonData.forEach((element, index) => {
                          position_data.Coupon_id.push(element._id);
                          position_data.position.push(index + 1);
                        });
                      }
                    },
                  })}
                />
              )}
            </div>
          </TabPanel>
          <TabPanel value="2">
            {/* <div>
              {isLoadingData ? (
                <div className="loader-container">
                  <Image
                    className="loader-element animate__animated animate__jackInTheBox"
                    src={ReactLogo}
                    height={40}
                  />
                </div>
              ) : (
                <></>
              )}
            </div> */}
            <MaterialReactTable
              autoResetPageIndex={false}
              columns={columns2}
              data={jsonData}
              enableRowOrdering
              enableSorting={false}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    jsonData.splice(
                      hoveredRow.index,
                      0,
                      jsonData.splice(draggingRow.index, 1)[0]
                    );
                    setJsonData([...jsonData]);
                    console.log("new jsonData", jsonData);
                    let position_data = {
                      Offer_id: [],
                      position: [],
                    };
                    jsonData.forEach((element, index) => {
                      position_data.Offer_id.push(element._id);
                      position_data.position.push(index + 1);
                    });
                  }
                },
              })}
            />
          </TabPanel>
          <TabPanel value="3">
            {/* <div>
              {isLoadingData ? (
                <div className="loader-container">
                  <Image
                    className="loader-element animate__animated animate__jackInTheBox"
                    src={ReactLogo}
                    height={40}
                  />
                </div>
              ) : (
                <MaterialReactTable
                  autoResetPageIndex={false}
                  columns={columns3}
                  data={jsonData}
                  enableRowOrdering
                  enableSorting={false}
                  muiTableBodyRowDragHandleProps={({ table }) => ({
                    onDragEnd: () => {
                      const { draggingRow, hoveredRow } = table.getState();
                      if (hoveredRow && draggingRow) {
                        jsonData.splice(
                          hoveredRow.index,
                          0,
                          jsonData.splice(draggingRow.index, 1)[0]
                        );
                        setJsonData([...jsonData]);
                        console.log("new jsonData", jsonData);
                        let position_data = {
                          Coupon_id: [],
                          position: [],
                        };
                        jsonData.forEach((element, index) => {
                          position_data.Coupon_id.push(element._id);
                          position_data.position.push(index + 1);
                        });
                      }
                    },
                  })}
                />
              )}
            </div> */}
            <div className="container">
        
        <hr></hr>
  
          <Formik
            initialValues={myNewData}
            validationSchema={SigninNewSchema}
            onSubmit={(values) => {
              getMyBookOrderData(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              /* and other goodies */
            }) => (
              <Form>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="body">
                      <Form.Label>Discount</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter discount"
                        name="discount"
                        value={values.discount}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.discount && touched.discount && errors.discount}
                    </div>
                  </Col>
                  
                 
                </Row>
                <div className="mt-3">
                  <Button
                    variant="primary"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
      
         
            {/* <Image className="loader-element animate__animated animate__jackInTheBox" src={ReactLogo} height={40} /> */}
      
          {/* // <p>Loading ..</p> */}
        
      </div>
          </TabPanel>
        </TabContext>
      </Card.Body>
    </Card>
  );
};
