import React, { useState, useEffect, useMemo } from "react";
import { Col, Row, Card, Form, Button } from "@themesberg/react-bootstrap";
import "react-time-picker/dist/TimePicker.css";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import DownloadExcelButton from "../pages/downloadExel";
import { MaterialReactTable } from "material-react-table";
import Select from "react-select";
import ReactLogo from "../assets/img/technologies/loading-loader-svgrepo-com.svg";
import { Image } from "@themesberg/react-bootstrap";
export const MockExamCategory = () => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [SubscriptionData, setMySubscriptionData] = useState([]);

  const [Editopen, setEditOpen] = useState(false);
  const onEditOpenModal = () => setEditOpen(true);
  const onEditCloseModal = () => setEditOpen(false);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const [time, setTime] = useState("00:00:00");
  const [myData, setMyData] = useState({
    category_name: "",
    description: "",
    subscription_id: [],
  });
  const [jsonData, setJsonData] = useState([]);
  const [editData, setEditData] = useState({});

  const history = useHistory();

  const navigateDetails = (userId) => {
    history.push(`/subcategory?id=${userId}`);
  };

  const getMySubscriptionData = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Subscription/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      setMySubscriptionData(res.data);
    } catch (error) {}
  };

  const createMockCategory = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/MasterCategory/create`,
        { ...values },
        {
          headers: { Authorization: `${token}` },
        }
      );
      onCloseModal();
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Category has been Added Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getMyCategoryData();
    } catch (error) {}
  };

  const SigninSchema = Yup.object().shape({
    category_name: Yup.string().required("Category name is Required"),
    description: Yup.string().required("description is Required"),
    subscription_id: Yup.array()
      .of(Yup.string().required("subscription is required"))
      .min(1, "Select at least one subscription"),
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "category_name",
        header: "Mock Exam Category name",
      },
      {
        accessorKey: "description",
        header: "Description",
      },

      {
        accessorKey: "Neet_SS",
        header: "Neet_SS",
      },
      {
        accessorKey: "INISS_ET",
        header: "INISS_ET",
      },
      {
        accessorKey: "actions",
        header: "actions",
      },
    ],
    []
    //end
  );
  const [items, setItems] = useState([]);
  const handleToggleActive = (event, item, field) => {
    const updatedItem = { ...item, [field]: event.target.checked };
    const updatedItems = items.map((i) => (i.id === item.id ? updatedItem : i));
    setItems(updatedItems); // Update the state with the modified items array
  };
  const getMyCategoryData = async () => {
    const token = localStorage.getItem("token");
    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/MasterCategory/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      const data = res.data?.map((item, index) => {
        return {
          id: index + 1,
          category_name: item.category_name,
          description: item.description,
          // Neet_SS: item.Neet_SS,
          Neet_SS: (
            <input
              type="checkbox"
              style={{ margin: "10px -10px 0 0" }}
              checked={item.Neet_SS}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          Neet_SS: item.Neet_SS ? "True" : "False",
          INISS_ET: (
            <input
              type="checkbox"
              style={{ margin: "10px -10px 0 0" }}
              checked={item.INISS_ET}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          INISS_ET: item.INISS_ET ? "True" : "False",
          // INISS_ET: item.INISS_ET,
          _id: item._id,
          actions: (
            <div>
              <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(item)} />

              <FontAwesomeIcon
                className="mx-3"
                icon={faTrash}
                onClick={() => handleDelete(item._id)}
              />
            </div>
          ),
        };
      });
      setJsonData(data);
      setIsLoadingData(false);
    } catch (error) {
      setIsLoadingData(false);
    }
  };

  const handleEdit = (data) => {
    setEditData(data); // Set the data of the selected row
    onEditOpenModal(); // Open the modal
  };

  const updateSubcategory = async (id, updatedData) => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/updateMasterCategory/${id}`,
        updatedData,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res.data); // Log the response or handle it as required.
      // Optionally, you can refetch the subcategory data after successful update.
      // getMySubCategoryData();
      onEditCloseModal(); // Close the modal after successful update.
      Swal.fire({
        position: "center",
        icon: "success",
        title: " Category Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getMyCategoryData();
    } catch (error) {
      console.error(error.response); // Handle error responses
    }
  };

  const handleDelete = (id) => {
    // Show the confirmation dialog using SweetAlert
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with the delete operation
        try {
          const token = localStorage.getItem("token");
          const res = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/deleteMasterCategory/${id}`,
            {
              headers: { Authorization: token },
            }
          );
          console.log(res.data); // Log the response or handle it as required.
          // Optionally, you can refresh the subcategory data after successful deletion.
          // getMySubCategoryData();
          // Show a success message using SweetAlert
          Swal.fire("Deleted!", "Category has been deleted.", "success");
          getMyCategoryData();
        } catch (error) {
          console.error(error.response); // Handle error responses
          // Show an error message using SweetAlert
          Swal.fire("Error", "Failed to delete the Category.", "error");
        }
      }
    });
  };

  const EditSchema = Yup.object().shape({
    category_name: Yup.string().required("Category name is Required"),
    description: Yup.string().required("description is Required"),
    subscription_id: Yup.array()
      .of(Yup.string().required("subscription is required"))
      .min(1, "Select at least one subscription"),
  });

  useEffect(() => {
    getMyCategoryData();
    getMySubscriptionData();
  }, []);

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4"> Mock Category</h5>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <button className="btn btn-primary" onClick={onOpenModal}>
            Add Mock Category
            <FontAwesomeIcon icon={faPlus} className="mx-2" />
          </button>
          <DownloadExcelButton jsonData={jsonData} fileName="Category" />
        </div>
        <Modal open={open} onClose={onCloseModal} center>
          <h2>Add Mock Category</h2>
          <Formik
            initialValues={myData}
            validationSchema={SigninSchema}
            onSubmit={(values) => {
              createMockCategory(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form>
                <Row>
                  <Col md={12} className="">
                    <Form.Group id="firstName">
                      <Form.Label>Category Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your first name"
                        name="category_name"
                        value={values.category_name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.category_name &&
                        touched.category_name &&
                        errors.category_name}
                    </div>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Subscription Plan</Form.Label>
                      <Select
                        name="subscription_id"
                        value={values.subscription_id.map((id) => ({
                          value: id,
                          label:
                            SubscriptionData.find((item) => item.sid === id)
                              ?.plan_name || "",
                        }))}
                        options={SubscriptionData.map((item) => ({
                          value: item.sid,
                          label: item.plan_name,
                        }))}
                        isMulti
                        onChange={(selectedOptions) => {
                          setFieldValue(
                            "subscription_id",
                            selectedOptions.map((option) => option.value)
                          );
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Group id="lastName">
                      <textarea
                        class="form-control"
                        id="description"
                        rows="6"
                        placeholder="Enter Description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      ></textarea>
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </div>
                  </Col>
                </Row>
                <Col>
                  <Form.Check
                    type="checkbox"
                    id="Neet_SS"
                    label="Neet_SS"
                    checked={values.Neet_SS}
                    onChange={(e) => {
                      setFieldValue("Neet_SS", e.target.checked);
                    }}
                  />
                </Col>

                <Col>
                  <Form.Check
                    type="checkbox"
                    id="INISS_ET"
                    label=" INISS_ET"
                    checked={values.INISS_ET}
                    onChange={(e) => {
                      setFieldValue("INISS_ET", e.target.checked);
                    }}
                  />
                </Col>

                <div className="mt-3 flex">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>

        <Modal open={Editopen} onClose={onEditCloseModal} center>
          <h2>Edit Category</h2>
          <Formik
            initialValues={editData}
            validationSchema={EditSchema}
            onSubmit={(values) => {
              updateSubcategory(editData._id, values); // Pass the ID and updated data to updateSubcategory
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>Category Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your first name"
                        name="category_name"
                        value={values.category_name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.category_name &&
                        touched.category_name &&
                        errors.category_name}
                    </div>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Subscription Plan</Form.Label>
                      <Select
                        name="subscription_id"
                        value={values.subscription_id.map((id) => ({
                          value: id,
                          label:
                            SubscriptionData.find((item) => item.sid === id)
                              ?.plan_name || "",
                        }))}
                        options={SubscriptionData.map((item) => ({
                          value: item.sid,
                          label: item.plan_name,
                        }))}
                        isMulti
                        onChange={(selectedOptions) => {
                          setFieldValue(
                            "subscription_id",
                            selectedOptions.map((option) => option.value)
                          );
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="lastName">
                      {/* <Form.Label>Description</Form.Label> */}

                      <textarea
                        class="form-control"
                        id="description"
                        rows="6"
                        placeholder="Enter Description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      ></textarea>
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </div>
                  </Col>
                </Row>
                <Col>
                  <Form.Check
                    type="checkbox"
                    id="Neet_SS"
                    label="Neet_SS"
                    checked={values.Neet_SS}
                    onChange={(e) => {
                      setFieldValue("Neet_SS", e.target.checked);
                    }}
                  />
                </Col>

                <Col>
                  <Form.Check
                    type="checkbox"
                    id="INISS_ET"
                    label=" INISS_ET"
                    checked={values.INISS_ET}
                    onChange={(e) => {
                      setFieldValue("INISS_ET", e.target.checked);
                    }}
                  />
                </Col>

                <div className="mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>

        <div>
          {isLoadingData ? (
            <div className="loader-container">
              <Image
                className="loader-element animate__animated animate__jackInTheBox"
                src={ReactLogo}
                height={40}
              />
            </div>
          ) : (
            <MaterialReactTable
              autoResetPageIndex={false}
              columns={columns}
              data={jsonData}
              enableRowOrdering
              enableSorting={false}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    jsonData.splice(
                      hoveredRow.index,
                      0,
                      jsonData.splice(draggingRow.index, 1)[0]
                    );
                    setJsonData([...jsonData]);
                    console.log("new jsonData", jsonData);
                    let position_data = {
                      category_id: [],
                      position: [],
                    };
                    jsonData.forEach((element, index) => {
                      position_data.category_id.push(element._id);
                      position_data.position.push(index + 1);
                    });

                    var newtoken = localStorage.getItem("token");

                    let config = {
                      method: "put",
                      maxBodyLength: Infinity,
                      url: "https://api.sushrutalgs.in/api/updateCategoryPosition",
                      headers: {
                        Authorization: newtoken,
                        "Content-Type": "application/json",
                      },
                      data: position_data,
                    };

                    axios
                      .request(config)
                      .then((response) => {
                        console.log(JSON.stringify(response.data));
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                },
              })}
            />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};
