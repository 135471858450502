import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faGithub,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import Logo from "../../assets/img/logo2.png";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";

export default () => {
  const history = useHistory();
  const [myData, setMyData] = useState({
    username: "",
    password: "",
  });
  const [isError, setIsError] = useState("");

  const getMyPostData = async (values) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/admin/login`,
        values
      );
      // setMyData(res.data);
      localStorage.setItem("token", res.data.token);
      if (res.data.token) {
        history.push("/users");
      }
    } catch (error) {
      setIsError(error.response.data.err.message);
    }
  };

  const SigninSchema = Yup.object().shape({
    username: Yup.string().required("User name is Required"),
    password: Yup.string().required("Password is Required"),
  });

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          {/* <p className="text-center">
            <Card.Link
              as={Link}
              to={Routes.DashboardOverview.path}
              className="text-gray-700"
            >
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to
              homepage
            </Card.Link>
          </p> */}
          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <img width={200} src={Logo}></img>
                  {/* <h3 className="mb-0">Admin Login</h3> */}
                </div>
                {/* <Form className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="email" placeholder="example@company.com" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control required type="password" placeholder="Password" />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                      </Form.Check>
                      <Card.Link className="small text-end">Lost password?</Card.Link>
                    </div>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100">
                    Sign in
                  </Button>
                </Form> */}
                <Formik
                  initialValues={myData}
                  validationSchema={SigninSchema}
                  onSubmit={(values) => {
                    getMyPostData(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form className="mt-4">
                      <div className="mb-4">
                        <label htmlFor="email">Your Email</label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faEnvelope} />
                          </span>
                          <Field
                            autoFocus
                            type="text"
                            placeholder="User Name"
                            value={values.username}
                            name="username"
                            id="username1"
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                        <div className="mb-3 text-danger">
                          {errors.username &&
                            touched.username &&
                            errors.username}
                        </div>
                      </div>
                      <div className="mb-4">
                        <label htmlFor="password">Your Password</label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </span>
                          <Field
                            type="password"
                            placeholder="password"
                            value={values.password}
                            name="password"
                            id="username1"
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                        <div className="mb-3 text-danger">
                          {errors.password &&
                            touched.password &&
                            errors.password}
                        </div>
                        <div className="text-danger">{isError}</div>
                      </div>
                      {/* <div className="d-flex justify-content-between align-items-center mb-4">
                        <Card.Link className="small text-end">
                          Lost password?
                        </Card.Link>
                      </div> */}
                      <button
                        type="submit"
                        className="btn btn-primary w-100"
                        onClick={handleSubmit}
                      >
                        Sign in
                      </button>
                    </Form>
                  )}
                </Formik>
{/* 
                <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">or login with</span>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Button
                    variant="outline-light"
                    className="btn-icon-only btn-pill text-facebook me-2"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Button>
                  <Button
                    variant="outline-light"
                    className="btn-icon-only btn-pill text-twitter me-2"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </Button>
                  <Button
                    variant="outline-light"
                    className="btn-icon-only btn-pil text-dark"
                  >
                    <FontAwesomeIcon icon={faGithub} />
                  </Button>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link
                      as={Link}
                      to={Routes.Signup.path}
                      className="fw-bold"
                    >
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
