import React, { useState, useEffect, useMemo } from "react";
import {
    Col,
    Row,
    Card,
    Form,
    Button,
    InputGroup,
} from "@themesberg/react-bootstrap";
import "react-time-picker/dist/TimePicker.css";
import { Formik, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import DownloadExcelButton from "../pages/downloadExel";
import { MaterialReactTable } from "material-react-table";
import ReactLogo from "../assets/img/technologies/loading-loader-svgrepo-com.svg";
import { Image } from '@themesberg/react-bootstrap';
export const PreparingFor = () => {
    const [birthday, setBirthday] = useState("");
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const [Editopen, setEditOpen] = useState(false);
    const onEditOpenModal = () => setEditOpen(true);
    const onEditCloseModal = () => setEditOpen(false);

    const [time, setTime] = useState("00:00:00");
    const [myData, setMyData] = useState({
        preparing_for: "",
        checkbox: []
    });
    const [isError, setIsError] = useState("");
    const [jsonData, setJsonData] = useState([]);
    const [editData, setEditData] = useState({});
    const [pagination, setPagination] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const history = useHistory();

    const navigateDetails = (userId) => {
        history.push(`/subcategory?id=${userId}`);
    };

    const createPreparingFor = async (values) => {
        console.log("createPreparingFor ----- ", values);
        const token = localStorage.getItem("token");
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/api/preparing/create`,
                { ...values, time_duration: time },
                {
                    headers: { Authorization: `${token}` },
                }
            );
            // setMyData(res.data);
            onCloseModal();
            Swal.fire({
                position: "center",
                icon: "success",
                title: "Preparing has been Added Successfully",
                showConfirmButton: false,
                timer: 1500,
            });
            getPreparingData();
            // setMyData("");
        } catch (error) {
            setIsError(error.response.data.err.message);
        }
    };

    const SigninSchema = Yup.object().shape({
        preparing_for: Yup.string().required("Preparing for is Required"),
        checkbox: Yup.array().of(Yup.string().required("Exam is required"))
    });

    const columns = useMemo(
        () => [
            {
                accessorKey: "preparing_for",
                header: "Preparing for",
            },
            {
                accessorKey: "checkbox",
                header: "Exam",
            },
            {
                accessorKey: "actions",
                header: "actions",
            },
        ],
        []
    );

    const getPreparingData = async () => {
        const token = localStorage.getItem("token");

        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/api/preparing/getpreparing`,
                {
                    headers: { Authorization: token },
                }
            );
                     setIsLoadingData(false);
            const data = res.data?.map((item, index) => {
                return {
                    id: index + 1,
                    preparing_for: item.preparing_for,
                    checkbox: item.checkbox.toString(),
                    _id: item._id,
                    actions: (
                        <div>
                            <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(item)} />

                            <FontAwesomeIcon
                                className="mx-3"
                                icon={faTrash}
                                onClick={() => handleDelete(item._id)}
                            />
                        </div>
                    ),
                };
            });
            setJsonData(data);
          
        } catch (error) {
            setIsError(error.response);
            setIsLoadingData(false);
        }
    };

    const handleEdit = (data) => {
        setEditData(data); // Set the data of the selected row
        setEditOpen(true); // Open the modal
    };

    const editPreparingFor = async (id, updatedData) => {
        console.log("editPreparingFor ----- ", id, updatedData);
        try {
            const token = localStorage.getItem("token");
            const res = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/api/updatepreparing/${id}`,
                updatedData,
                {
                    headers: { Authorization: token },
                }
            );
            console.log(res.data); // Log the response or handle it as required.
            // Optionally, you can refetch the subcategory data after successful update.
            // getMySubCategoryData();
            onEditCloseModal(); // Close the modal after successful update.
            Swal.fire({
                position: "center",
                icon: "success",
                title: " Category Updated Successfully",
                showConfirmButton: false,
                timer: 1500,
            });
            getPreparingData();
        } catch (error) {
            console.error(error.response); // Handle error responses
        }
    };

    const handleDelete = (id) => {
        // Show the confirmation dialog using SweetAlert
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Proceed with the delete operation
                try {
                    const token = localStorage.getItem("token");
                    const res = await axios.delete(
                        `${process.env.REACT_APP_BASE_URL}/api/deletepreparing/${id}`,
                        {
                            headers: { Authorization: token },
                        }
                    );
                    console.log(res.data); // Log the response or handle it as required.
                    // Optionally, you can refresh the subcategory data after successful deletion.
                    // getMySubCategoryData();
                    // Show a success message using SweetAlert
                    Swal.fire("Deleted!", "Category has been deleted.", "success");
                    getPreparingData();
                } catch (error) {
                    console.error(error.response); // Handle error responses
                    // Show an error message using SweetAlert
                    Swal.fire("Error", "Failed to delete the Category.", "error");
                }
            }
        });
    };

    const EditSchema = Yup.object().shape({
        preparing_for: Yup.string().required("Preparing for is Required"),
        checkbox: Yup.array().of(Yup.string().required("Exam is required")),
    });

    useEffect(() => {
        getPreparingData();
    }, []);

    return (
        <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
                <h5 className="mb-4">Preparing For</h5>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <button className="btn btn-primary" onClick={onOpenModal}>
                        Add Preparing For
                        <FontAwesomeIcon icon={faPlus} className="mx-2" />
                    </button>
                    <DownloadExcelButton jsonData={jsonData} fileName="Category" />
                </div>
                <Modal open={open} onClose={onCloseModal} center>
                    <h2>Add Preparing For</h2>
                    <Formik
                        initialValues={myData}
                        validationSchema={SigninSchema}
                        onSubmit={(values) => {
                            createPreparingFor(values);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <Form>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group id="firstName">
                                            <Form.Label>Preparing For</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter preparing for"
                                                name="preparing_for"
                                                value={values.preparing_for}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <div className="mb-3 text-danger">
                                            {errors.preparing_for &&
                                                touched.preparing_for &&
                                                errors.preparing_for}
                                        </div>
                                    </Col>
                                    <Col md={12} className="mb-3">
                                        <Row>
                                            <FieldArray name="checkbox">
                                                {({ push, remove }) => (
                                                    <>
                                                        {values.checkbox.map((item, index) => (
                                                            <Col md={6} className="mb-3" key={index}>
                                                                <Form.Group>
                                                                    <Form.Label>Exam {index + 1}</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter exam"
                                                                        name={`checkbox.${index}`}
                                                                        value={item}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.checkbox &&
                                                                        touched.checkbox &&
                                                                        touched.checkbox[index] && (
                                                                            <div className="text-danger">
                                                                                {errors.checkbox[index]}
                                                                            </div>
                                                                        )}
                                                                </Form.Group>
                                                            </Col>
                                                        ))}

                                                        {/* Show one "checkbox" field by default */}
                                                        {values.checkbox.length === 0 && (
                                                            <Col md={6} className="mb-3">
                                                                <Form.Group>
                                                                    <Form.Label>Exam 1</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter exam"
                                                                        name={`checkbox.0`}
                                                                        value={values.checkbox[0]}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.checkbox &&
                                                                        touched.checkbox &&
                                                                        touched.checkbox[0] && (
                                                                            <div className="text-danger">
                                                                                {errors.checkbox[0]}
                                                                            </div>
                                                                        )}
                                                                </Form.Group>
                                                            </Col>
                                                        )}

                                                        <Row>
                                                            <Col md={12} className="mb-3">
                                                                <Button
                                                                    variant="primary"
                                                                    onClick={() => push("")}
                                                                >
                                                                    Add exam
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            </FieldArray>
                                        </Row>
                                    </Col>
                                </Row>

                                <div className="mt-3 flex">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal>


                <Modal open={Editopen} onClose={onEditCloseModal} center>
                    <h2>Edit Preparing For</h2>
                    <Formik
                        initialValues={editData}
                        validationSchema={EditSchema}
                        onSubmit={(values) => {
                            editPreparingFor(editData._id, values); // Pass the ID and updated data to editPreparingFor
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <Form>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group id="firstName">
                                            <Form.Label>Preparing For</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter preparing for"
                                                name="preparing_for"
                                                value={values.preparing_for}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <div className="mb-3 text-danger">
                                            {errors.preparing_for &&
                                                touched.preparing_for &&
                                                errors.preparing_for}
                                        </div>
                                    </Col>
                                    <Col md={12} className="mb-3">
                                        <Row>
                                            <FieldArray name="checkbox">
                                                {({ push, remove }) => (
                                                    <>
                                                        {values.checkbox.map((item, index) => (
                                                            <Col md={6} className="mb-3" key={index}>
                                                                <Form.Group>
                                                                    <Form.Label>Exam {index + 1}</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter exam"
                                                                        name={`checkbox.${index}`}
                                                                        value={item}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.checkbox &&
                                                                        touched.checkbox &&
                                                                        touched.checkbox[index] && (
                                                                            <div className="text-danger">
                                                                                {errors.checkbox[index]}
                                                                            </div>
                                                                        )}
                                                                </Form.Group>
                                                            </Col>
                                                        ))}

                                                        {/* Show one "checkbox" field by default */}
                                                        {values.checkbox.length === 0 && (
                                                            <Col md={6} className="mb-3">
                                                                <Form.Group>
                                                                    <Form.Label>Exam 1</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter exam"
                                                                        name={`checkbox.0`}
                                                                        value={values.checkbox[0]}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {errors.checkbox &&
                                                                        touched.checkbox &&
                                                                        touched.checkbox[0] && (
                                                                            <div className="text-danger">
                                                                                {errors.checkbox[0]}
                                                                            </div>
                                                                        )}
                                                                </Form.Group>
                                                            </Col>
                                                        )}

                                                        <Row>
                                                            <Col md={12} className="mb-3">
                                                                <Button
                                                                    variant="primary"
                                                                    onClick={() => push("")}
                                                                >
                                                                    Add exam
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            </FieldArray>
                                        </Row>
                                    </Col>
                                </Row>

                                <div className="mt-3">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        Update
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal>
                <div>
                    {isLoadingData ? (
                        <div className="loader-container">
                            <Image className="loader-element animate__animated animate__jackInTheBox" src={ReactLogo} height={40} />
                        </div>
                    ) : (
                        <MaterialReactTable
                            autoResetPageIndex={false}
                            columns={columns}
                            data={jsonData}
                            enableSorting={false}
                        />

                    )}
                </div>

            </Card.Body>
        </Card>
    );
};
