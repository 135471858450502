
import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import "react-time-picker/dist/TimePicker.css";
import { Formik, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import DownloadExcelButton from "../pages/downloadExel";
import { MaterialReactTable } from "material-react-table";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import ReactLogo from "../assets/img/technologies/loading-loader-svgrepo-com.svg";
import { Image } from '@themesberg/react-bootstrap';
// import draftToHtml from 'draftjs-to-html';
import JoditEditor from "jodit-react";


export const Blog = () => {
  const [birthday, setBirthday] = useState("");
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
    setEditorState(EditorState.createEmpty());
  };

  const [Editopen, setEditOpen] = useState(false);
  const onEditOpenModal = () => setEditOpen(true);
  const onEditCloseModal = () => {
    setEditOpen(false);
    setEditorState(EditorState.createEmpty());
  };
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [time, setTime] = useState("00:00:00");
  const [myData, setMyData] = useState({
    title: "",
    Image: "",
    alias: "",
    blogCategory_id: "",
  });

  const [isError, setIsError] = useState("");
  const [jsonData, setJsonData] = useState([]);
  const [categoryData, setcategoryData] = useState([]);
  const [editData, setEditData] = useState({});

  const SigninSchema = Yup.object().shape({
    title: Yup.string().required("title is Required"),
    Image: Yup.string().required("Image is Required"),
    alias: Yup.string().required("alias is Required"),
    blogCategory_id: Yup.string().required("please select Blog Category"),
  });

  const getCategory = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/BlogCategory/getAll`
      );

      setcategoryData(res.data);
    } catch (error) {
      setIsError(error.response);
    }
  };

  function convertContentToHTML(content, wordLimit) {
    if (!content || !content.blocks) {
      return "";
    }

    const text = content.blocks
      .map((block) => {
        if (block.type === "unstyled") {
          return block.text;
        } else if (block.type === "header-four") {
          return `<h4>${block.text}</h4>`;
        } else {
          // Handle other block types as needed
          return "";
        }
      })
      .join(" ");

    const words = text.split(" ");
    const limitedText = words.slice(0, wordLimit).join(" ");

    if (words.length > wordLimit) {
      return `${limitedText} ...`;
    }

    return limitedText;
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: "Title",
      },
      {
        accessorKey: "Image",
        header: "Image",
        Cell: ({ renderedCellValue }) => {
          return (
            <img
              src={renderedCellValue} // Use the base64 image data directly
              alt="User"
              style={{ width: "110px", height: "4rem" }} // Adjust styling as needed
            />
          );
        },
      },
      
      // {
      //   accessorKey: "Image",
      //   header: "Image",
      //   Cell: ({ renderedCellValue }) => {
      //     return (
      //       <img
      //         src={`data:image/png;base64,${renderedCellValue}`} // Prepend the prefix
      //         alt="User"
      //         style={{ width: "110px", height: "4rem" }} // Adjust styling as needed
      //       />
      //     );
      //   },
      // },
      // {
      //   accessorKey: "content",
      //   header: "content",
      //   Cell: ({ renderedCellValue }) => (
      //     <div
      //       dangerouslySetInnerHTML={{
      //         __html: convertContentToHTML(JSON.parse(renderedCellValue), 10), // Adjust the word limit as needed
      //       }}
      //     />
      //   ),
      // },

      {
        accessorKey: "alias",
        header: "Alias",
      },
      {
        accessorKey: "BlogCategoryName",
        header: "Blog Category Name",
      },
      {
        accessorKey: "actions",
        header: "Actions",
      },
    ],
    []
  );

  // const getBlog = async () => {
  //   const token = localStorage.getItem("token");
  //   setIsLoadingData(true);
  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/api/Blog/getAll`,
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );
  //     console.log(res.data);
  //     const data = res.data?.map((item, index) => {
  //       return {
  //         id: index + 1,
  //         title: item.title,
  //         Image: item.Image,
  //         content: item.content,
  //         alias: item.alias,
  //         blogCategoryName: item.blogCategoryName,
  //         _id: item._id,
  //         actions: (
  //           <div>
  //             <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(item)} />

  //             <FontAwesomeIcon
  //               className="mx-3"
  //               icon={faTrash}
  //               onClick={() => handleDelete(item._id)}
  //             />
  //           </div>
  //         ),
  //       };
  //     });
  //     setJsonData(data);
  //     setIsLoadingData(false);
  //   } catch (error) {
  //     setIsError(error.response);
  //     setIsLoadingData(false);
  //   }
  // };

  const getBlog = async () => {
    const token = localStorage.getItem("token");
    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Blog/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      console.log(JSON.parse(JSON.stringify(res.data)));
      // var content = res.data.content;
      // console.log(content)
      const data =res.data?.map((item, index) => {
        // const base64Image = item.Image.replace(/^data:image\/\w+;base64,/, ''); // Remove the prefix
        
        return {
          id: index + 1,
          title: item.title,
          Image: item.Image,
          // content: JSON.stringify(item.content),
          alias: item.alias,
          blogCategoryName: item.blogCategoryName,
          _id: item._id,
          actions: (
            <div>
              <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(item)} />
              <FontAwesomeIcon
                className="mx-3"
                icon={faTrash}
                onClick={() => handleDelete(item._id)}
              />
            </div>
          ),
        };
      });
      setJsonData(data);
      setIsLoadingData(false);
    } catch (error) {
      setIsError(error.response);
      setIsLoadingData(false);
    }
  };
  

  const handleEdit = (data) => {
    // setSerializedContent(data.content);
    setEditData(data); // Set the data of the selected row
    setEditOpen(true); // Open the modal
  };

  const editTetsimonial = async (id, updatedData) => {
    // const serializedContent = getSerializedContent();
    // const serializedContent =editorState.getCurrentContent();
    // var markup = draftToHtml(
    //   editorState.getCurrentContent()
    // );
    // console.log("markup --- ",markup)
    try {
      const token = localStorage.getItem("token");
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/updateBlog/${id}`,
        { ...updatedData, content: editorState },
        {
          headers: { Authorization: token },
        }
      );
      // Log the response or handle it as required.
      // Optionally, you can refetch the subcategory data after successful update.
      // getMySubCategoryData();
      onEditCloseModal(); // Close the modal after successful update.
      setEditorState(EditorState.createEmpty());
      Swal.fire({
        position: "center",
        icon: "success",
        title: " Blog Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getBlog();
    } catch (error) {
      console.error(error.response); // Handle error responses
    }
  };

  const handleDelete = (id) => {
    // Show the confirmation dialog using SweetAlert
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with the delete operation
        try {
          const token = localStorage.getItem("token");
          const res = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/deleteBlog/${id}`,
            {
              headers: { Authorization: token },
            }
          );
          console.log(res.data); // Log the response or handle it as required.
          // Optionally, you can refresh the subcategory data after successful deletion.
          // getMySubCategoryData();
          // Show a success message using SweetAlert
          Swal.fire("Deleted!", "Blog has been deleted.", "success");
          getBlog();
        } catch (error) {
          console.error(error.response); // Handle error responses
          // Show an error message using SweetAlert
          Swal.fire("Error", "Failed to delete the Blog.", "error");
        }
      }
    });
  };

  const [editorState, setEditorState] = useState("");
  const editor = useRef(null);
	const [content, setContent] = useState('');

	// const config = useMemo(
	// 	{
	// 		readonly: false, // all options from https://xdsoft.net/jodit/docs/,
	// 		placeholder:  'Start typings...'
	// 	},
	// 	[]
	// );
  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  // const getSerializedContent = () => {
  //   const contentState = editorState.getCurrentContent();
  //   return JSON.stringify(convertToRaw(contentState));
  // };

  // const setSerializedContent = (serializedContent) => {
  //   const contentState = convertFromRaw(JSON.parse(serializedContent));
  //   setEditorState(EditorState.createWithContent(contentState));
  // };

  const createBlog = async (values) => {
    // const serializedContent =editorState.getCurrentContent();
    // var markup = draftToHtml(
    //   serializedContent
    // );
    console.log("values --- ",editorState)
    const token = localStorage.getItem("token");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/Blog/create`,
        { ...values, content: editorState },
        {
          headers: { Authorization: `${token}` },
        }
      );
      onCloseModal();
      setEditorState(EditorState.createEmpty());
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Blog has been Added Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getBlog();
      // setMyData("");
    } catch (error) {
      setIsError(error.response.data.err.message);
    }
  };

  useEffect(() => {
    getBlog();
    getCategory();
  }, []);

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Blog</h5>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <button className="btn btn-primary" onClick={onOpenModal}>
            Add Blog
            <FontAwesomeIcon icon={faPlus} className="mx-2" />
          </button>
          <DownloadExcelButton jsonData={jsonData} fileName="Category" />
        </div>
        <Modal open={open} onClose={onCloseModal} center>
          <h2>Add Blog</h2>
          <Formik
            initialValues={myData}
            validationSchema={SigninSchema}
            onSubmit={(values) => {
              createBlog(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter title"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.title && touched.title && errors.title}
                    </div>
                  </Col>
                  {/* <Col md={12} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>Image</Form.Label>
                      <Form.Control
                        type="file"
                        name="Image"
                        onChange={(event) => {
                          const file = event.target.files[0];
                          if (file) {
                            const reader = new FileReader();
                            reader.onload = (e) => {
                              const base64String = e.target.result;
                              setFieldValue("Image", base64String);
                            };
                            reader.readAsDataURL(file);
                          }
                        }}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.Image && touched.Image && errors.Image}
                    </div>
                  </Col> */}
                  {/* <Col md={12} className="mb-3">
            <div className="form-group">
              <label htmlFor="Image">Image</label>
              <input
                type="file"
                name="Image"
                onChange={(event) => {
                  const file = event.target.files[0];
                  if (file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      const base64String = e.target.result;
                      setFieldValue("Image", base64String);
                    };
                    reader.readAsDataURL(file);
                  }
                }}
                className="form-control"
              />
              <ErrorMessage
                name="Image"
                component="div"
                className="text-danger"
              />
            </div>
          </Col> */}


          <Col md={12} className="mb-3">
  <div className="form-group">
    <label htmlFor="Image">Image</label>
    <input
      type="file"
      name="Image"
      onChange={(event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const base64String = e.target.result.replace(/^data:image\/\w+;base64,/, ''); // Remove the prefix
            setFieldValue("Image", base64String);
          };
          reader.readAsDataURL(file);
        }
      }}
      className="form-control"
    />
    <ErrorMessage
      name="Image"
      component="div"
      className="text-danger"
    />
  </div>
</Col>

                  {/* <FieldArray name="comments">
                    {({ push, remove }) => (
                      <>
                        {values.comments.map((item, index) => {
                          return (
                            <div key={index}>
                              <Row>
                                <Col md={4} className="mb-3">
                                  <Form.Group>
                                    <Form.Label>name {index + 1}</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter name"
                                      name={`comments[${index}].name`}
                                      onChange={handleChange}
                                    />
                                    {errors.comments &&
                                      errors.comments[index] &&
                                      errors.comments[index].name && (
                                        <div className="text-danger">
                                          {errors.comments[index].name}
                                        </div>
                                      )}
                                  </Form.Group>
                                </Col>
                                <Col md={4} className="mb-3">
                                  <Form.Group>
                                    <Form.Label>
                                      description {index + 1}
                                    </Form.Label>
                                
                                    <textarea
                                      class="form-control"
                                      id="description"
                                      rows="6"
                                      type="text"
                                      placeholder="Enter Description"
                                      name={`comments[${index}].description`}
                                      onChange={handleChange}
                                    ></textarea>
                                    {errors.comments &&
                                      errors.comments[index] &&
                                      errors.comments[index].description && (
                                        <div className="text-danger">
                                          {errors.comments[index].description}
                                        </div>
                                      )}
                                  </Form.Group>
                                </Col>
                                <Col md={4} className="mb-3">
                                  <Form.Group>
                                    <Form.Label>Image {index + 1}</Form.Label>
                                    <Form.Control
                                      type="file"
                                      name={`comments[${index}].Image`}
                                      onChange={(event) => {
                                        const file = event.target.files[0];
                                        if (file) {
                                          const reader = new FileReader();
                                          reader.onload = (e) => {
                                            const base64String =
                                              e.target.result;
                                            setFieldValue(
                                              "Image",
                                              base64String
                                            );
                                          };
                                          reader.readAsDataURL(file);
                                        }
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          );
                        })}

                        <Row>
                          <Col md={12} className="mb-3">
                            <Button
                              variant="primary"
                              onClick={() =>
                                push({
                                  name: "",
                                  Image: "",
                                  discountedPrice: "",
                                })
                              }
                            >
                              Add Duration
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </FieldArray> */}
                  {/* <Col md={12} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>description</Form.Label>
                      <textarea
                        class="form-control"
                        id="description"
                        rows="6"
                        placeholder="Enter Description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      ></textarea>
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </div>
                  </Col> */}
                  <Col md={12} className="mb-3">
                    <JoditEditor
                      // editorState={editorState}
                      // onEditorStateChange={onEditorStateChange}

                      ref={editor}
			value={values.content}
			// config={config}
			tabIndex={1} // tabIndex of textarea
			// onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
			onChange={onEditorStateChange}
                    />
                  </Col>

                  <Col md={12} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>Alias</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter alias"
                        name="alias"
                        value={values.alias}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.alias && touched.alias && errors.alias}
                    </div>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Blog Category</Form.Label>
                      <Form.Select
                        name="blogCategory_id"
                        value={values.blogCategory_id}
                        onChange={handleChange}
                      >
                        <option>Select Blog Category</option>
                        {categoryData.map((item, index) => (
                          <option value={item._id}>
                            {item.blogCategoryName}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <div className="mt-3 flex">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>

        <Modal open={Editopen} onClose={onEditCloseModal} center>
          <h2>Edit Blog</h2>
          <Formik
            initialValues={editData}
            validationSchema={SigninSchema}
            onSubmit={(values) => {
              editTetsimonial(editData._id, values); // Pass the ID and updated data to editTetsimonial
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Form>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter title"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.title && touched.title && errors.title}
                    </div>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>Image</Form.Label>
                      <Form.Control
                        type="file"
                        name="Image"
                        onChange={(event) => {
                          const file = event.target.files[0];
                          if (file) {
                            const reader = new FileReader();
                            reader.onload = (e) => {
                              const base64String = e.target.result;
                              setFieldValue("Image", base64String);
                            };
                            reader.readAsDataURL(file);
                          }
                        }}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.Image && touched.Image && errors.Image}
                    </div>
                  </Col>

                  <Col md={12} className="mb-3">
                  <JoditEditor
                      // editorState={editorState}
                      // onEditorStateChange={onEditorStateChange}

                      ref={editor}
			value={values.content}
			// config={config}
			tabIndex={1} // tabIndex of textarea
			// onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
			onChange={onEditorStateChange}
                    />
                  </Col>

                  <Col md={12} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>Alias</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter alias"
                        name="alias"
                        value={values.alias}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.alias && touched.alias && errors.alias}
                    </div>
                  </Col>

                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Blog Category</Form.Label>
                      <Form.Select
                        name="blogCategory_id"
                        value={values.blogCategory_id}
                        onChange={handleChange}
                      >
                        <option>Select Blog Category</option>
                        {categoryData.map((item, index) => (
                          <option value={item._id}>
                            {item.blogCategoryName}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <div className="mt-3 flex">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
        <div>
          {isLoadingData ? (
            <div className="loader-container">
          <Image className="loader-element animate__animated animate__jackInTheBox" src={ReactLogo} height={40} />
          </div>
          ) : (
            <MaterialReactTable
            autoResetPageIndex={false}
            columns={columns}
            data={jsonData}
            enableSorting={false}
          />
          )}
        </div>

       
      </Card.Body>
    </Card>
  );
};
