import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form as BootstrapForm,
  Button,
  Spinner,
} from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import moment from "moment";
import TimePicker from "react-time-picker";
import { faClock } from "@fortawesome/free-solid-svg-icons";

import {
  // Col,
  // Row,
  Card,
  Form,
  // Button,FieldArray
} from "@themesberg/react-bootstrap";
import { FieldArray } from "formik";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import DownloadExcelButton from "./downloadExel";
import { MDBDataTable } from "mdbreact";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useHistory } from "react-router-dom";
import ReactLogo from "../assets/img/technologies/loading-loader-svgrepo-com.svg";
import { Image } from "@themesberg/react-bootstrap";
import { useLocation } from "react-router-dom";

export const Content = () => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [filteredPDF, setFilteredPdf] = useState([]);
  const [isError, setIsError] = useState("");
  const [value, setValue] = useState("1");
  const [SubscriptionData, setMySubscriptionData] = useState([]);
  const [topicsData, setTopicsData] = useState([]);
  const [contentData, setContentData] = useState([]);
  const [editData, setEditData] = useState({});
  const [categoryData, setMyCategoryData] = useState([]);
  const [subcategoryData, setMysubCategoryData] = useState([]);
  const [fileBuffer, setFileBuffer] = useState(null);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [filteredTopics, setFilteredTopics] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const [contentshowdata, setContentShowData] = useState(false);
  const onCloseModal = () => {
    setFileBuffer(null);
    setOpen(false);
  };

  // const navigateDetails = (userId) => {
  //   history.push(`/questionDetails?id=${userId}`);
  // };
  // const history = useHistory();

  const [openVideo, setOpenVideo] = useState(false);
  const onOpenVideoModal = () => setOpenVideo(true);
  const onCloseVideoModal = () => setOpenVideo(false);

  const [Editopen, setEditOpen] = useState(false);
  const onEditOpenModal = () => setEditOpen(true);
  const onEditCloseModal = () => setEditOpen(false);

  // view
  const [Editopen1, setEditOpen1] = useState(false);
  const onEditOpenModal1 = () => setEditOpen1(true);
  const onEditCloseModal1 = () => setEditOpen1(false);

  const [selectedOption, setSelectedOption] = useState("");
  const [jsonData, setJsonData] = useState([]);

  const [myData, setMyData] = useState({
    title: "",
    content_id: "",
    content_type: [],
    content_url: "",
    topic_id: "",
    video_url: "",
    isfeatured: false,
    Banner_img: "",
    subscription_id: [],
    chapter: [],
    isPublic: false,
  });

  const filteredSubcategories1 = subcategoryData.filter(
    (subcategory) => subcategory.category_id === editData.category_id
  );
  console.log(subcategoryData, "subcategoryData8888");

  const filteredTopics1 = topicsData.filter(
    (topic) => topic.subcategory_id === editData.subcategory_id
  );
  console.log(topicsData, "topicsData0000000000");
  // Filter topics based on selected subcategory
  // setFilteredTopics(topicsData.filter(
  //   (topic) => topic.subcategory_id === editData.subcategory_id
  // ));
  // const filteredTopics1 = filteredTopics
  // console.log(filteredTopics1,"filteredTopics1filteredTopics1",filteredTopics);
  // const [myChapterData, setMyChapterData] = useState({
  //   // console.log(setMyChapterData,"setMyChapterDatasetMyChapterDatasetMyChapterData"),
  //   content_id:"",
  //   section: [
  //     {
  //       sectionName: "",
  //       sectionTime: "",
  //       description: "",
  //       chapter: [
  //         {
  //           title: "",
  //           time: "",

  //         }
  //       ],

  //     }

  //   ],

  // });
  const [myChapterData, setMyChapterData] = useState({
    content_id: "",
    section: [
      {
        sectionName: "",
        sectionTime: "00:00:00",
        description: "",
        chapter: [
          {
            title: "",
            time: "00:00:00",
          },
        ],
      },
    ],
  });

  const mergeChapterData = () => {
    setMyData((prevData) => ({
      ...prevData,
      chapter: myChapterData.section,
    }));
  };

  console.log(myChapterData, "myChapterDatamyChapterData");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFileBuffer(selectedFile);
  };

  const contentChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "No",
        field: "id",
        width: 270,
      },

      {
        label: "Title",
        field: "title",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "Category",
        field: "category_name",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "Subcategory",
        field: "subcategory_name",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "Topic",
        field: "name",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "Linked PDF Title",
        field: "pdf_title",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "Vimeo ID",
        field: "url",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Link",
        },
      },
      {
        label: "Action",
        field: "actions",
        width: 100,
      },
    ],
  });

  const [pdfdatatable, setpdfDatatable] = useState({
    columns: [
      {
        label: "No",
        field: "id",
        width: 270,
      },

      {
        label: "Title",
        field: "title",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "Category",
        field: "category_name",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "Subcategory",
        field: "subcategory_name",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "Topic",
        field: "name",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "Action",
        field: "actions",
        width: 100,
      },
    ],
  });

  const getMyContentData = async (tabId) => {
    const token = localStorage.getItem("token");
    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Content/V2/getAllByvideoV2`,
        {
          headers: { Authorization: token },
        }
      );
      const data = res.data?.map((item, index) => {
        console.log(item, "item********");
        return {
          ...item,
          id: index + 1,
          name: item.topic_name,
          url: item.video_url,
          chapter: item.chapter,
          plan_name:
            item.plan_name.length > 1
              ? item.plan_name.map((plan, planIndex) => (
                  <div key={planIndex}>{`${planIndex + 1}. ${plan}`}</div>
                ))
              : item.plan_name[0],

          actions: (
            <div>
              <FontAwesomeIcon
                icon={faEdit}
                className="mx-3"
                onClick={() => handleEdit(item, tabId)}
              />

              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => handleDelete(item._id)}
              />
              {/* <FontAwesomeIcon
                style={{ marginRight: "2rem" }}
                icon={faEye}
                className="mx-3 "
                // onClick={() => navigateDetails(item._id)}
                onClick={() => handleview(item,item._id)}
              /> */}
              {/* <FontAwesomeIcon
  style={{ marginRight: "2rem" }}
  icon={faEye}
  className="mx-3 "
  onClick={() => handleview(item, "6576d3449d22926f91aa23dc")} // Pass the content_id here
/> */}
              <FontAwesomeIcon
                style={{ marginRight: "2rem" }}
                icon={faEye}
                className="mx-3"
                onClick={() => handleViewCode(item, item._id)} // Assuming item.content_id contains the dynamic content_id
              />
            </div>
          ),
        };
      });
      setJsonData(res.data);

      setDatatable((prevState) => ({
        ...prevState,
        rows: data,
      }));
      setIsLoadingData(false);
    } catch (error) {
      setIsError(error.response);
      setIsLoadingData(false);
    }
  };

  // const getMychaterData = async (id) => {
  //   const token = localStorage.getItem("token");
  //   setIsLoadingData(true);

  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/api/getVideoChapter/${id}`,
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );

  //     console.log("🚀 ~ getMychaterData ~ res:", res);

  //     const data = res.data?.map((item, index) => {
  //       return {
  //         id: index + 1,
  //         sectionName: item.sectionName,
  //         sectionTime: item.sectionTime,
  //         description: item.description,
  //         chapters: item.chapter.map((chapter, chapIndex) => ({
  //           id: chapIndex + 1,
  //           title: chapter.title,
  //           time: chapter.time,
  //         })),
  //       };
  //     });

  //     setIsLoadingData(false);
  //     setJsonData(data);
  //   } catch (error) {
  //     setIsError(error.response);
  //     setIsLoadingData(false);
  //   }
  // };

  const getMychaterData = async (id) => {
    const token = localStorage.getItem("token");
    setIsLoadingData(true);

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getVideoChapter/${id}`,
        {
          headers: { Authorization: token },
        }
      );

      console.log("🚀 ~ getMychaterData ~ res:", res);

      const data = res.data?.map((item, index) => {
        return {
          id: index + 1,
          sectionName: item.sectionName,
          sectionTime: item.sectionTime,
          description: item.description,
          chapters: item.chapter.map((chapter, chapIndex) => ({
            id: chapIndex + 1,
            title: chapter.title,
            time: chapter.time,
          })),
        };
      });

      setIsLoadingData(false);
      setJsonData(data);
    } catch (error) {
      setIsError(error.response);
      setIsLoadingData(false);
    }
  };

  const getMyPdfData = async (tabId) => {
    const token = localStorage.getItem("token");
    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Content/getAllByPdf`,
        {
          headers: { Authorization: token },
        }
      );
      const data = res.data?.map((item, index) => {
        return {
          ...item,
          id: index + 1,
          name: item.topic_name,
          plan_name:
            item.plan_name.length > 1
              ? item.plan_name.map((plan, planIndex) => (
                  <div key={planIndex}>{`${planIndex + 1}. ${plan}`}</div>
                ))
              : item.plan_name[0],

          actions: (
            <div>
              <FontAwesomeIcon
                icon={faEdit}
                className="mx-3"
                onClick={() => handleEdit(item, tabId)}
              />

              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => handleDelete(item._id)}
              />
            </div>
          ),
        };
      });
      setJsonData(res.data);

      setpdfDatatable((prevState) => ({
        ...prevState,
        rows: data,
      }));
      setIsLoadingData(false);
    } catch (error) {
      setIsError(error.response);
      setIsLoadingData(false);
    }
  };
  // const createContent = async (values) => {
  //   const token = localStorage.getItem("token");

  //   // Process video URL
  //   if (values?.video_url && values.video_url.includes("http")) {
  //     let newVal = values?.video_url.split("/");
  //     values.video_url = newVal[newVal.length - 1];
  //   }

  //   // Prepare formData for video and PDF content
  //   const formData = new FormData();
  //   const videoFormData = new FormData();
  //   const category_id = topicsData.filter((i) => i._id === values.topic_id)[0].category_id;
  //   const subcategory_id = topicsData.filter((i) => i._id === values.topic_id)[0].subcategory_id;

  //   // Prepare video_data object with chapter array
  //   const video_data = {
  //     topic_id: values.topic_id,
  //     content_type: "video",
  //     video_url: values.video_url,
  //     isfeatured: values.isfeatured,
  //     Banner_img: values.Banner_img,
  //     subscription_id: JSON.stringify(values.subscription_id),
  //     chapter: values.chapter,
  //   };

  //   // Append data to formData and videoFormData
  //   formData.append("topic_id", values.topic_id);
  //   formData.append("category_id", category_id);
  //   formData.append("subcategory_id", subcategory_id);
  //   formData.append("title", values.title);
  //   formData.append("subscription_id", JSON.stringify(values.subscription_id));

  //   videoFormData.append("topic_id", values.topic_id);
  //   videoFormData.append("category_id", category_id);
  //   videoFormData.append("subcategory_id", subcategory_id);
  //   videoFormData.append("title", values.title);
  //   videoFormData.append("subscription_id", JSON.stringify(values.subscription_id));

  //   // Append chapter data to videoFormData
  //   values.chapter.forEach((chapter, index) => {
  //     videoFormData.append(`chapter[${index}][title]`, chapter.title);
  //     videoFormData.append(`chapter[${index}][time]`, chapter.time);
  //   });

  //   // Handle file upload for PDF content
  //   if (fileBuffer) {
  //     formData.append("file", fileBuffer);
  //     formData.append("content_type", "PDF");
  //     formData.append("isfeatured", values.isfeaturedpdf || false);
  //   }

  //   try {
  //     // Make API calls to create content
  //     if (fileBuffer) {
  //       await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v2/createContent`, formData, {
  //         headers: {
  //           Authorization: `${token}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       });
  //     }

  //     await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v2/createContent`, videoFormData, {
  //       headers: {
  //         Authorization: `${token}`,
  //       },
  //     });

  //     // Show success message and update state
  //     Swal.fire({
  //       position: "center",
  //       icon: "success",
  //       title: "Content has been Added Successfully",
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //     setFileBuffer(null);
  //     getMyContentData();
  //     getMyPdfData();
  //     onCloseModal();
  //     onCloseVideoModal();
  //     getMyTopicData();
  //   } catch (error) {
  //     setIsError(error);
  //   }
  // };
  const SigninSchemasection = Yup.object().shape({
    // Define validation rules for your fields
    sections: Yup.array().of(
      Yup.object().shape({
        sectionName: Yup.string().required("Section Name is required"),
        sectionTime: Yup.string().required("Section Time is required"),
        description: Yup.string().required("Description is required"),
      })
    ),
    chapters: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Chapter Title is required"),
        time: Yup.string().required("Chapter Time is required"),
        sections: Yup.array().of(
          Yup.object().shape({
            // Define validation rules for sections within chapters if needed
          })
        ),
      })
    ),
  });

  // const addChapter = async (values,id) => {
  //   const token = localStorage.getItem('token');
  //   try {
  //     const res = await axios.post(
  //       `${process.env.REACT_APP_BASE_URL}/api/createChapter/${id}`,
  //       values,
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );
  //     console.log(res.data,"add chapter@@@@@@@@@@@@@@@");
  //     setMyChapterData(res.data);
  //     getMychaterData();
  //     Swal.fire({
  //       position: 'center',
  //       icon: 'success',
  //       title: 'Chapter Added Successfully',
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //     onCloseModal();
  //     setMyChapterData({
  //       sections: [
  //         {
  //           sectionName: '',
  //           sectionTime: '',
  //           description: '',
  //         },
  //       ],
  //       chapters: [
  //         {
  //           title: '',
  //           time: '',
  //         },
  //       ],
  //     });
  //   } catch (error) {
  //     setIsError(error.response.data.err.message);
  //   }
  // };

  // const addChapter = async (values,) => {
  //   const token = localStorage.getItem('token');
  //   try {
  //     const res = await axios.post(
  //       `${process.env.REACT_APP_BASE_URL}/api/createChapter`, // Not sure where id comes from
  //       values,
  //       { headers: { Authorization: token } }
  //     );
  //     console.log(res.data, "add chapter@@@@@@@@@@@@@@@");
  //     setMyChapterData(res.data);
  //     getMychaterData(); // Assuming this function exists to fetch updated data
  //     Swal.fire({
  //       position: 'center',
  //       icon: 'success',
  //       title: 'Chapter Added Successfully',
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //     onEditCloseModal1(); // Close modal after successful addition
  //     // Reset form values
  //     setMyChapterData({
  //       sections: [{
  //         sectionName: '',
  //         sectionTime: '',
  //         description: '',
  //       }],
  //       chapters: [{
  //         title: '',
  //         time: '',
  //         sections: []
  //       }],
  //     });
  //   } catch (error) {
  //     console.error(error);
  //     // Handle error
  //   }
  // };
  // const addChapter = async (values) => {
  //   const token = localStorage.getItem('token');
  //   try {
  //     // Assuming content_id comes from values object
  //     const { content_id, ...otherValues } = values;

  //     const res = await axios.post(
  //       `${process.env.REACT_APP_BASE_URL}/api/createChapter`,
  //       { content_id, ...otherValues}, // Include content_id in the request body
  //       { headers: { Authorization: token } }
  //     );
  //     console.log(res.data, "add chapter@@@@@@@@@@@@@@@");
  //     setMyChapterData(res.data);
  //     getMychaterData(); // Assuming this function exists to fetch updated data
  //     Swal.fire({
  //       position: 'center',
  //       icon: 'success',
  //       title: 'Chapter Added Successfully',
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //     onEditCloseModal1(); // Close modal after successful addition
  //     // Reset form values
  //     setMyChapterData({
  //       sections: [{
  //         sectionName: '',
  //         sectionTime: '',
  //         description: '',
  //       }],
  //       chapters: [{
  //         title: '',
  //         time: '',
  //         sections: []
  //       }],
  //     });
  //   } catch (error) {
  //     console.error(error);
  //     // Handle error
  //   }
  // };

  // const addChapter = async (values) => {

  //   const token = localStorage.getItem('token');
  //   try {
  //     const { content_id, ...otherValues } = values;

  //     const res = await axios.post(
  //       `${process.env.REACT_APP_BASE_URL}/api/createChapter`,
  //       myChapterData,
  //       { headers: { Authorization: token } }
  //     );
  //   // console.log(res,"res@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");

  //     setMyChapterData(res.data);
  //     getMychaterData(myChapterData.content_id); // Assuming this function exists to fetch updated data
  //     Swal.fire({
  //       position: 'center',
  //       icon: 'success',
  //       title: 'Chapter Added Successfully',
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //     onEditCloseModal1(); // Close modal after successful addition
  //     // Reset form values
  //     setMyChapterData({
  //       sections: [{
  //         sectionName: '',
  //         sectionTime: '',
  //         description: '',
  //         chapters: [{
  //           title: '',
  //           time: '',

  //         }],
  //       }],

  //     });
  //   } catch (error) {
  //     console.error(error);
  //     // Handle error
  //   }
  // };
  // const addChapter = async (values) => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const res = await axios.post(
  //       `${process.env.REACT_APP_BASE_URL}/api/createChapter`,
  //       values,
  //       { headers: { Authorization: token } }
  //     );
  //     console.log(res.data,"addchapter@@@@@@@@@@@@@@@@@");
  //     setMyChapterData(res.data);
  //     getMychaterData(values.content_id); // Assuming this function exists to fetch updated data
  //     Swal.fire({
  //       position: "center",
  //       icon: "success",
  //       title: "Chapter Added Successfully",
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //     onEditCloseModal1(); // Close modal after successful addition
  //     // Reset form values
  //     setMyChapterData({
  //       content_id: "",
  //       section: [
  //         {
  //           sectionName: "",
  //           sectionTime: "00:00:00",
  //           description: "",
  //           chapter: [
  //             {
  //               title: "",
  //               time: "00:00:00",
  //             },
  //           ],
  //         },
  //       ],
  //     });
  //   } catch (error) {
  //     console.error(error);
  //     // Handle error
  //   }
  // };

  const addChapter = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/createChapter`,
        values,
        { headers: { Authorization: token } }
      );
      console.log(res.data, "addchapter@@@@@@@@@@@@@@@@@");
      setMyChapterData(res.data);
      getMychaterData(values.content_id); // Assuming this function exists to fetch updated data
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Chapter Added Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      onEditCloseModal1(); // Close modal after successful addition
      // Reset form values
      setMyChapterData({
        content_id: "",
        section: [
          {
            sectionName: "",
            sectionTime: "00:00:00",
            description: "",
            chapter: [
              {
                title: "",
                time: "00:00:00",
              },
            ],
          },
        ],
      });
    } catch (error) {
      console.error(error);
      // Handle error
    }
  };

  // const addChapter = async (values) => {
  //   const token = localStorage.getItem('token');
  //   try {
  //     const { content_id, ...otherValues } = values;
  //     const res = await axios.post(
  //       `${process.env.REACT_APP_BASE_URL}/api/createChapter`,
  //       myChapterData,
  //       // { ...otherValues, content_id },
  //       { headers: { Authorization: token } }
  //     );
  //     setMyChapterData(res.data);
  //     // getMychaterData(content_id);
  //     getMychaterData(myChapterData.content_id);// Assuming this function exists to fetch updated data
  //     Swal.fire({
  //       position: 'center',
  //       icon: 'success',
  //       title: 'Chapter Added Successfully',
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //     onEditCloseModal1(); // Close modal after successful addition
  //     // Reset form values
  //     setMyChapterData({
  //       content_id: "",
  //       section: [
  //         {
  //           sectionName: '',
  //           sectionTime: '',
  //           description: '',
  //           chapter: [{
  //             title: '',
  //             time: '',
  //           }],
  //         }
  //       ]
  //     });
  //   } catch (error) {
  //     console.error(error);
  //     // Handle error
  //   }
  // };

  // const addChapter = async (values) => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const res = await axios.post(
  //       `${process.env.REACT_APP_BASE_URL}/api/createChapter`,
  //       values,
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );
  //     setMyChapterData(res.data);
  //     Swal.fire({
  //       position: "center",
  //       icon: "success",
  //       title: "chapter Added Successfully",
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //     // getMyBookData();
  //     onCloseModal();
  //     setMyChapterData({
  //       section: [
  //         {
  //           sectionName: "",
  //           sectionTime: "",
  //           description: "",
  //         }
  //       ],
  //       chapter: [
  //         {
  //           title: "",
  //           time: "",

  //         }
  //       ]

  //     });
  //   } catch (error) {
  //     setIsError(error.response.data.err.message);
  //   }
  // };

  const createContent = async (values) => {
    // const base64String = Buffer.from(fileBuffer).toString("base64");
    const token = localStorage.getItem("token");
    setIsLoading(true); // Set loading to true when starting the request
    // setIsLoadingData(true); // Set loading to true when fetching data
    if (values?.video_url) {
      if (values?.video_url.includes("http")) {
        let newVal = values?.video_url.split("/");
        values.video_url = newVal[newVal.length - 1];
      }
      // values.content_type = "video";
    }
    const formData = new FormData();
    const videoFormData = new FormData();
    var video_data = {
      topic_id: values.topic_id,
      content_type: "video",
      video_url: values.video_url,
      isfeatured: values.isfeatured,
      isPublic: values.isPublic,
      Banner_img: values.Banner_img,
      // chapter: values.chapter,
      subscription_id: JSON.stringify(values.subscription_id),
      // chapter: values.chapter,
    };
    videoFormData.append("chapter", JSON.stringify(values.chapter));
    console.log("🚀 ~ createContent ~ video_data:", typeof video_data.chapter);
    const category_id = topicsData.filter((i) => i._id === values.topic_id)[0]
      .category_id;
    const subcategory_id = topicsData.filter(
      (i) => i._id === values.topic_id
    )[0].subcategory_id;
    if (fileBuffer) {
      formData.append("file", fileBuffer);
      formData.append("content_type", "PDF");
      formData.append("isfeatured", values.isfeaturedpdf || false);
      formData.append("isPublic", values.isPublic || false);
    }
    if (values.video_url) {
      videoFormData.append("content_type", "video");
      videoFormData.append("isfeatured", values.isfeatured || false);
      videoFormData.append("isPublic", values.isPublic || false);

      videoFormData.append("video_url", values.video_url);
    }
    if (values.Banner_img) {
      videoFormData.append("Banner_img", values.Banner_img);
    }
    formData.append("topic_id", values.topic_id);
    formData.append("category_id", category_id);
    formData.append("subcategory_id", subcategory_id);
    formData.append("title", values.title);
    videoFormData.append("topic_id", values.topic_id);
    videoFormData.append("category_id", category_id);
    videoFormData.append("subcategory_id", subcategory_id);
    videoFormData.append("pdf_id", values.pdf_id);
    videoFormData.append("title", values.title);
    formData.append("subscription_id", JSON.stringify(values.subscription_id));
    videoFormData.append(
      "subscription_id",
      JSON.stringify(values.subscription_id)
    );
    // videoFormData.append(
    //   "chapter",
    //   JSON.stringify(values.chapter)
    // );
    // const chapterData = JSON.stringify(values.chapter);
    // videoFormData.append("chapter",  JSON.stringify(value.chapter));
    // videoFormData.append("chapter", JSON.stringify(values.chapter));  // Correctly stringify the array of objects

    const selectedTopic = topicsData.find(
      (item) => item._id === values.topic_id
    );
    if (selectedTopic) {
      formData.append("content_id", selectedTopic.topic_name);
      video_data.content_id = selectedTopic.topic_name;
      videoFormData.append("content_id", selectedTopic.topic_name);
    }
    console.log("videoFormData -------- ", videoFormData);
    try {
      if (fileBuffer) {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v2/createContent`,
          formData,
          {
            headers: {
              Authorization: `${token}`,
              "Content-Type": fileBuffer ? "multipart/form-data" : undefined,
            },
          }
        );
      }
      if (values.video_url) {
        const res2 = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v2/createContent`,
          videoFormData,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
      }

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Content has been Added Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      setFileBuffer(null);
      getMyContentData();
      getMyPdfData();
      onCloseModal();
      onCloseVideoModal();
      getMyTopicData();
      // setIsLoadingData(false); // Set loading to false after fetching data
    } catch (error) {
      setIsError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMyTopicData = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Topic/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      var newdata = [];
      res.data.forEach((element) => {
        if (element.content_type && element.content_type.length === 0) {
          element.hasVideo = false;
          element.hasNote = false;
          newdata.push(element);
        } else {
          if (
            element.content_type &&
            element.content_type.includes("PDF") &&
            element.content_type.includes("video")
          ) {
            element.hasVideo = true;
            element.hasNote = true;
            newdata.push(element);
          } else if (
            element.content_type &&
            element.content_type.includes("PDF")
          ) {
            element.hasVideo = false;
            element.hasNote = true;
            newdata.push(element);
          } else if (
            element.content_type &&
            element.content_type.includes("video")
          ) {
            element.hasVideo = true;
            element.hasNote = false;
            newdata.push(element);
          }
        }
      });
      setTopicsData(newdata);
    } catch (error) {
      setIsError(error.response);
    }
    console.log(newdata, "newdata------**");
  };

  const getMySubscriptionData = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Subscription/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      setMySubscriptionData(res.data);
    } catch (error) {
      setIsError(error.response);
    }
  };

  const handleEdit = (data, item) => {
    console.log("editdata--", data);
    setEditData({ ...data }); // Set the data of the selected row
    setEditOpen(true); // Open the modal
  };

  // const handleview = (data, item) => {
  //   console.log(data,"handleview**********");
  //   console.log(item,"handleview**********");

  //   setEditData({ ...data }); // Set the data of the selected row
  //   setEditOpen1(true); // Open the modal
  // };
  // const handleview = (data, content_id) => {
  //   console.log(data, "dataaaaaaaaa**********");
  //   console.log(content_id, "content_id**********");

  //   getMychaterData(content_id);
  //   setEditData({ ...data, content_id }); // Set the data of the selected row including content_id
  //   setEditOpen1(true); // Open the modal
  // };

  // const handleViewCode = async (item, id) => {
  //   setMyChapterData({ ...myChapterData, content_id: id });
  //   setEditOpen1(true);
  // };

  const handleview = (data, content_id) => {
    console.log(data, "dataaaaaaaaa**********");
    console.log(content_id, "content_id**********");

    getMychaterData(content_id);
    setEditData({ ...data, content_id }); // Set the data of the selected row including content_id
    setEditOpen1(true); // Open the modal
  };

  const handleViewCode = async (item, id) => {
    setMyChapterData({ ...myChapterData, content_id: id });
    setEditOpen1(true);
  };
  useEffect(() => {
    getMySubscriptionData();
    getMyTopicData();
    getMyContentData();
    getMyCategoryData();
    getMySubCategoryData();
    // setFilteredTopics(
    //   topicsData
    //     .filter
    //     // (topic) => topic.subcategory_id === editData.subcategory_id
    //     ()
    // );
  }, []);

  const SigninSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    subscription_id: Yup.array()
      .of(Yup.string().required("subscription is required"))
      .min(1, "Select at least one subscription"),
    topic_id: Yup.string().required("Topic ID is required"),
  });

  const EditSchema = Yup.object().shape({
    // content_id: Yup.string().required("Category name is Required"),
    // content_type: Yup.string().required("content_type is Required"),
    category_id: Yup.string().required("Please select Category"),
    subscription_id: Yup.string().required("Please select subscription"),
  });

  const updateContent = async (id, updatedData) => {
    try {
      setLoading(true);
      const formData = new FormData();
      if (fileBuffer) {
        // Append the file to the FormData object with a field name "file"
        formData.append("file", fileBuffer);
      }
      const category_id = topicsData.filter(
        (i) => i._id === updatedData.topic_id
      )[0].category_id;
      const subcategory_id = topicsData.filter(
        (i) => i._id === updatedData.topic_id
      )[0].subcategory_id;
      if (updatedData.video_url) {
        formData.append("video_url", updatedData.video_url);
      }
      if (updatedData.Banner_img) {
        formData.append("Banner_img", updatedData.Banner_img);
      }
      // formData.append("topic_id", updatedData.topic_id);
      formData.append("category_id", category_id);
      formData.append("subcategory_id", subcategory_id);
      formData.append("content_type", updatedData.content_type);
      formData.append("isfeatured", updatedData.isfeatured);
      formData.append("isPublic", updatedData.isPublic);
      formData.append(
        "subscription_id",
        JSON.stringify(updatedData.subscription_id)
      );
      formData.append("topic_id", updatedData.topic_id);
      formData.append("title", updatedData.title);
      if (updatedData.pdf_id) {
        formData.append("pdf_id", updatedData.pdf_id);
      }
      const token = localStorage.getItem("token");

      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/v2/updateContentdata/${updatedData._id}`,
        formData,
        {
          headers: { Authorization: token },
        }
      );

      // console.log(res.data); // Log the response or handle it as required.
      // Optionally, you can refetch the subcategory data after successful update.
      getMySubscriptionData();
      onEditCloseModal(); // Close the modal after successful update.
      Swal.fire({
        position: "center",
        icon: "success",
        title: " Content Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getMyContentData();
      getMyPdfData();
      setFileBuffer(null);
    } catch (error) {
      console.error(error.response); // Handle error responses
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (id) => {
    // Show the confirmation dialog using SweetAlert
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with the delete operation
        try {
          const token = localStorage.getItem("token");
          const res = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/deleteContent/${id}`,
            {
              headers: { Authorization: token },
            }
          );
          console.log(res.data); // Log the response or handle it as required.
          // Optionally, you can refresh the subcategory data after successful deletion.
          // getMySubscriptionData();
          // Show a success message using SweetAlert
          Swal.fire("Deleted!", "Your Content has been deleted.", "success");

          setFileBuffer(null);
          getMyContentData();
          getMyPdfData();
          getMyTopicData();
        } catch (error) {
          console.error(error.response); // Handle error responses
          // Show an error message using SweetAlert
          Swal.fire("Error", "Failed to delete the file.", "error");
        }
      }
    });
  };

  const tabListStyles = {
    display: "flex",
    justifyContent: "center",
    background: "#f5f5f5",
    padding: "10px",
    borderRadius: "5px",
  };

  const tabStyles = {
    fontSize: "18px",
    fontWeight: "bold",
    textTransform: "uppercase",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "5px",
    cursor: "pointer",
    color: "#333",
    background: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "background 0.3s ease-in-out",
  };

  const activeTabStyles = {
    background: "#007bff", // Change this to the desired background color for the active tab
    color: "#fff", // Change this to the desired text color for the active tab
  };

  const handleChange = (event, newValue) => {
    if (newValue === "2") {
      getMyPdfData(newValue);
    } else {
      getMyContentData(newValue);
    }
    setValue(newValue);
  };

  const getMyCategoryData = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Category/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      setMyCategoryData(res.data);
    } catch (error) {
      setIsError(error.response);
    }
  };

  const getMySubCategoryData = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Subcategory/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res.data, "resdata-------");
      setMysubCategoryData(res.data);
      console.log(res.data, "****************");
    } catch (error) {
      setIsError(error.response);
    }
  };

  // console.log("topicsData ------- ", topicsData);

  const handleCategoryChange = (categoryId, subcategoryId) => {
    console.log(categoryId, "CategoryOnchange ");
    var subcat = subcategoryData.filter(
      (subcategory) => subcategory.category_id === categoryId
    );
    console.log("subcat", subcat);
    setFilteredSubcategories(subcat);

    var topdata = topicsData.filter(
      (subcategory) => subcategory.subcategory_id === subcategoryId
    );
    // console.log("topdata",topdata)
    setFilteredTopics(topdata);
    // let data1 ={...editData,subcategory_id:"",topic_id:"",category_id:categoryId}
    // console.log(data1,"data1----------");
    let new_data = editData;
    editData.subcategory_id = "";
    editData.topic_id = "";
    editData.category_id = categoryId;
    setEditData(new_data);
    // setEditData(data1)
  };

  // console.log("categoryId ------- ", categoryId);

  const handleSubcategoryChange = (subcategoryId) => {
    console.log("subcategoryId ------- ", subcategoryId);
    var topdata = topicsData.filter(
      (subcategory) => subcategory.subcategory_id === subcategoryId
    );
    console.log("topdata", topdata);
    setFilteredTopics(topdata);

    let new_data = editData;
    editData.subcategory_id = subcategoryId;
    editData.topic_id = "";
    setEditData(new_data);
  };

  const getPdfByTopic = async (topicId) => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getPdfByTopicId/${topicId}`,
        {
          headers: { Authorization: token },
        }
      );
      setFilteredPdf(res.data);
    } catch (error) {
      setIsError(error.response);
    }
  };

  console.log("filtered pfg  ------------ ", filteredPDF);

  // Custom change handler for time conversion
  const handleTimeChange = (event, setFieldValue, name) => {
    const { value } = event.target;
    const seconds = Number(value) * 60;
    setFieldValue(name, seconds);
  };
  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Content</h5>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="">
            <button className="btn btn-primary " onClick={onOpenModal}>
              Add PDF
              <FontAwesomeIcon icon={faPlus} className="mx-2" />
            </button>
            <button
              className="btn btn-primary"
              style={{ marginLeft: 5 }}
              onClick={onOpenVideoModal}
            >
              Add Video
              <FontAwesomeIcon icon={faPlus} className="mx-2" />
            </button>
          </div>
          <DownloadExcelButton jsonData={jsonData} fileName="Content" />
        </div>
        {isLoading && <Spinner animation="border" />}
        <Modal open={open} onClose={onCloseModal} center>
          <h2>Add PDF</h2>
          <Formik
            initialValues={myData}
            validationSchema={SigninSchema}
            onSubmit={(values) => {
              createContent(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Form>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter title"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.title && touched.title && errors.title}
                    </div>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Category</Form.Label>
                      <Form.Select
                        name="category_id"
                        value={values.category_id}
                        onChange={(e) => {
                          handleCategoryChange(
                            e.target.value,
                            values.subcategory_id
                          );
                          handleChange(e);
                        }}
                      >
                        <option>Select Category</option>
                        {categoryData.map((item, index) => (
                          <option value={item._id} key={item._id}>
                            {item.category_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Subcategory</Form.Label>
                      <Form.Select
                        name="subcategory_id"
                        value={values.subcategory_id}
                        onChange={(e) => {
                          handleSubcategoryChange(e.target.value);
                          handleChange(e);
                        }}
                      >
                        <option>Select Subcategory</option>
                        {filteredSubcategories.map((subcategory, index) => (
                          <option key={subcategory._id} value={subcategory._id}>
                            {subcategory.subcategory_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Topic</Form.Label>
                      <Form.Select
                        name="topic_id"
                        value={values.topic_id}
                        onChange={handleChange}
                      >
                        <option>Select Topic</option>
                        {filteredTopics.map((item, index) => (
                          <option value={item._id}>
                            {item.topic_name}
                            {/* {item.hasVideo ? null : "| ▶️ Video missing"}{" "}
                              {item.hasNote ? null : "| 🗒️ PDF missing"} */}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Subscription Plan</Form.Label>
                      <Select
                        name="subscription_id"
                        value={values.subscription_id.map((id) => ({
                          value: id,
                          label:
                            SubscriptionData.find((item) => item.sid === id)
                              ?.plan_name || "",
                        }))}
                        options={SubscriptionData.map((item) => ({
                          value: item.sid,
                          label: item.plan_name,
                        }))}
                        isMulti
                        onChange={(selectedOptions) => {
                          setFieldValue(
                            "subscription_id",
                            selectedOptions.map((option) => option.value)
                          );
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="emal">
                      <Form.Label>Select Pdf</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="Content url"
                        name="content_url"
                        onChange={(event) => {
                          handleFileChange(event);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row md={12}>
                  <Col>
                    <div class="form-check mb-3">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={values.isfeaturedpdf}
                        id="flexCheckDefault"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("isfeaturedpdf", e.target.checked);
                        }}
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Add this PDF to Feature list
                      </label>
                    </div>
                  </Col>
                  <Col>
                    <div class="form-check mb-3">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={values.isPublic}
                        id="flexCheckPublic"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("isPublic", e.target.checked);
                        }}
                      />
                      <label class="form-check-label" for="flexCheckPublic">
                        isPublic
                      </label>
                    </div>
                  </Col>
                </Row>

                <div className="mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          {isLoading && <div className="loader"></div>}
        </Modal>
        {isLoading && <Spinner animation="border" />}
        <Modal open={Editopen} onClose={onEditCloseModal} center>
          <h2>Edit Content</h2>
          <Formik
            initialValues={editData}
            validationSchema={SigninSchema}
            onSubmit={(values) => {
              if (values.content_type === "PDF") {
                updateContent(editData._id, values);
              } else if (values.content_type === "video") {
                updateContent(editData._id, values);
              } // Pass the ID and updated data to updateContent
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Form>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter title"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.title && touched.title && errors.title}
                    </div>
                  </Col>
                  {/* <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Category</Form.Label>
                      <Form.Select
                        name="category_id"
                        value={values.category_id}
                        onChange={handleChange}
                        // disabled={true}
                      >
                        <option>Select Category</option>
                        {categoryData.map((item, index) => (
                          <option value={item._id} key={item._id}>
                            {item.category_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col> */}
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Category</Form.Label>
                      <Form.Select
                        name="category_id"
                        value={values.category_id}
                        onChange={(e) => {
                          console.log("eeee --- ", e.target.value);
                          handleCategoryChange(e.target.value);
                          handleChange(e);
                        }}
                      >
                        <option>Select Category</option>
                        {categoryData.map((item, index) => (
                          <option value={item._id}>{item.category_name}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {/* <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Subcategory</Form.Label>
                      <Form.Select
                        name="subcategory_id"
                        value={values.subcategory_id}
                        onChange={handleChange}
                        // disabled={flase}
                      >
                        <option>Select Subcategory</option>
                        {subcategoryData.map((subcategory, index) => (
                          <option key={subcategory._id} value={subcategory._id}>
                            {subcategory.subcategory_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col> */}
                  <Col md={12} className="mb-3">
                    <Form.Group id="subcategory">
                      <Form.Label>Select Subcategory</Form.Label>
                      <Form.Select
                        name="subcategory_id"
                        value={values.subcategory_id}
                        onChange={(e) => {
                          console.log("chsnge sub ====");
                          handleSubcategoryChange(e.target.value);
                          handleChange(e);
                        }}
                      >
                        <option>Select Subcategory</option>
                        {filteredSubcategories1.map((subcategory) => (
                          <option key={subcategory._id} value={subcategory._id}>
                            {subcategory.subcategory_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {/* <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Topic</Form.Label>
                      <Form.Select
                        // disabled={true}
                        name="topic_id"
                        value={values.topic_id}
                        onChange={handleChange}
                      >
                        <option>Select Topic</option>
                        {topicsData.map((item, index) => (
                          <option value={item._id}>{item.topic_name}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col> */}
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Topic</Form.Label>
                      <Form.Select
                        name="topic_id"
                        value={values.topic_id}
                        onChange={handleChange}
                      >
                        {console.log(
                          values.topic_id,
                          values.topic_name,
                          "values.topic_idvalues.topic_id"
                        )}
                        <option>Select Topic</option>
                        {filteredTopics1.map((topic) => (
                          <option key={topic._id} value={topic._id}>
                            {topic.topic_name}
                          </option>
                        ))}
                        {console.log(
                          filteredTopics,
                          "filteredTopicsfilteredTopics"
                        )}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Subscription Plan</Form.Label>
                      <Select
                        name="subscription_id"
                        value={values.subscription_id.map((id) => ({
                          value: id,
                          label:
                            SubscriptionData.find((item) => item.sid === id)
                              ?.plan_name || "",
                        }))}
                        options={SubscriptionData.map((item) => ({
                          value: item.sid,
                          label: item.plan_name,
                        }))}
                        isMulti
                        onChange={(selectedOptions) => {
                          setFieldValue(
                            "subscription_id",
                            selectedOptions.map((option) => option.value)
                          );
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row></Row>

                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="lastName">
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        disabled={true}
                        name="content_type"
                        value={values.content_type}
                        onChange={handleChange}
                      >
                        <option>Select Content Type</option>
                        <option value="video">Video</option>
                        <option value="PDF">Pdf </option>
                      </Form.Select>
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.content_type &&
                        touched.content_type &&
                        errors.content_type}
                    </div>
                  </Col>
                </Row>
                <Row>
                  {value === "2" && (
                    <Row>
                      <Col md={12} className="mb-3">
                        <Form.Group id="emal">
                          <Form.Label>Select Pdf</Form.Label>
                          <Form.Control
                            type="file"
                            placeholder="Content url"
                            name="content_url"
                            onChange={(event) => {
                              handleFileChange(event);
                            }}
                          />
                          {values.content_url?.split("-")[1]}
                        </Form.Group>
                        <div className="mb-3 text-danger">
                          {/* {!fileBuffer && "Please select file"} */}
                        </div>
                      </Col>
                    </Row>
                  )}

                  {value === "1" && (
                    <Row>
                      <Col md={12} className="mb-3">
                        <Form.Group id="emal">
                          <Form.Label>Video url</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Video url"
                            name="video_url"
                            value={values.video_url}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                </Row>

                <Row md={12}>
                  <Col>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={values.isfeatured}
                        id="flexCheckDefault"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("isfeatured", e.target.checked);
                        }}
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Add To Featur list
                      </label>
                    </div>
                  </Col>
                  <Col>
                    <div class="form-check mb-3">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={values.isPublic}
                        id="flexCheckPublic"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("isPublic", e.target.checked);
                        }}
                      />
                      <label class="form-check-label" for="flexCheckPublic">
                        isPublic
                      </label>
                    </div>
                  </Col>
                </Row>

                {values.content_type === "video" &&
                  values.isfeatured === true && (
                    <Row>
                      <Col md={12} className="mb-3">
                        <Form.Group id="emal">
                          <Form.Label>Banner Image</Form.Label>
                          <Form.Control
                            type="file"
                            name="Banner_img"
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              if (file) {
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                  const base64Data =
                                    e.target.result.split(",")[1];
                                  setFieldValue("Banner_img", base64Data);
                                };
                                reader.readAsDataURL(file);
                              }
                            }}
                          />
                        </Form.Group>
                      </Col>

                      {values.Banner_img && (
                        <img
                          src={`data:image/png;base64,${values.Banner_img}`}
                          className="question-image"
                        />
                      )}
                    </Row>
                  )}

                <div className="mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          {isLoading && <div className="loader"></div>}
        </Modal>

        {/* <Modal open={Editopen1} onClose={onEditCloseModal1} center styles={{ modal: { width: '90%'} }}>
          <h2> Section</h2>
          <Formik
            initialValues={myChapterData}
            // validationSchema={EditSchema}
            validationSchema={SigninSchemasection}
            onSubmit={(values) => {
              console.log("🚀 ~ Content ~ values%%%%%%%%%%%:", values)
              addChapter({ ...values, content_id: editData.content_id });
              
              // addChapter( values, item.id); // Pass the ID, updated data, and setValues to updateUser
            }}
            
          >
      
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
            
            }) => (
            
              <Form>
                <Row>

            
                <FieldArray name="section">
                {({ push, remove }) => (
                  
                  <>
                    {values?.section?.map((item, index) => (
                      <div key={index}>
                        <Row>
                          <Col md={4} className="mb-3">
                            <BootstrapForm.Group>
                              <BootstrapForm.Label>
                              SectionName{index + 1}
                              </BootstrapForm.Label>
                    
                              <BootstrapForm.Control
                                type="text"
                                placeholder="Enter SectionName"
                                name={`section[${index}].sectionName`}
                                value={item.sectionName}
                                onChange={handleChange}
                              />
                            </BootstrapForm.Group>
                          </Col>
                          <Col md={4} className="mb-3">
                            <BootstrapForm.Group>
                              <BootstrapForm.Label>SectionTime {index + 1}</BootstrapForm.Label>
                              <BootstrapForm.Control
                                type="text"
                                placeholder="Enter SectionTime"
                                name={`section[${index}].sectionTime`}
                                value={item.sectionTime}
                                // onChange={handleChange}
                                 onChange={(event) =>
                                 handleTimeChange(event, setFieldValue, `section[${index}].sectionTime`)
                               }
                              />
                            </BootstrapForm.Group>
                          </Col>
                          <Col md={3} className="mb-3">
                            <BootstrapForm.Group>
                              <BootstrapForm.Label>
                              Description{index + 1}
                              </BootstrapForm.Label>
                              <BootstrapForm.Control
                                type="text"
                                placeholder="Enter Description"
                                name={`section[${index}].description`}
                                value={item.description}
                                onChange={handleChange}
                              />
                            </BootstrapForm.Group>
                          </Col>
            
                          <Col md={1} className="mb-3 align-self-end">
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => remove(index)}
                            >
                              Remove
                            </Button>
                          </Col>


                                        <FieldArray name="chapter">
                {({ push, remove }) => (
                  <>
                    {values?.chapter?.map((item, index) => (
                      <div key={index}>
                        <Row>
                          <Col md={4} className="mb-3">
                            <BootstrapForm.Group>
                              <BootstrapForm.Label>
                                Title {index + 1}
                              </BootstrapForm.Label>
                              <BootstrapForm.Control
                                type="text"
                                placeholder="Enter Title"
                                name={`chapter[${index}].title`}
                                value={item.title}
                                onChange={handleChange}
                              />
                            </BootstrapForm.Group>
                          </Col>
                          <Col md={4} className="mb-3">
                            <BootstrapForm.Group>
                              <BootstrapForm.Label>Time {index + 1}</BootstrapForm.Label>
                              <BootstrapForm.Control
                                type="text"
                                placeholder="Enter Time"
                                name={`chapter[${index}].time`}
                                value={item.time}
                                onChange={(event) =>
                               handleTimeChange(event, setFieldValue, `chapter[${index}].time`)
                              }
                              // onChange={handleChange}
                              />
                            </BootstrapForm.Group>
                          </Col>
                          
                          <Col md={1} className="mb-3 align-self-end">
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => remove(index)}
                            >
                              Remove
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ))}

                    <Row>
                      <Col md={12} className="mb-3">
                        <Button
                          variant="primary"
                          onClick={() =>
                            push({
                              chapterName: "",
                              chapter: "",
                              pageNumber: "",
                            })
                          }
                        >
                          Add Chapter
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </FieldArray>
                        </Row>
                      </div>
                    ))}

                    <Row>
                      <Col md={12} className="mb-3">
                        <Button
                          variant="primary"
                          onClick={() =>
                            push({
                              sectionName: "",
                              sectionTime: "",
                              description: "",
                            })
                          }
                        >
                          Add Section
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </FieldArray>
                 
                </Row>
                <div className="mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Create
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal> */}

        {/* <Modal open={Editopen1} onClose={onEditCloseModal1} center styles={{ modal: { width: '90%' } }}>
  <h2>Section</h2>
  <Formik
    initialValues={myChapterData}
    validationSchema={SigninSchemasection}
    onSubmit={(values) => {
      console.log("🚀 ~ Content ~ values%%%%%%%%%%%:", values)
      addChapter({ ...values, content_id: editData.content_id });
    }}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleSubmit,
      setFieldValue,
    }) => (
      <Form>
        <Row>
          <FieldArray name="section">
            {({ push: pushSection, remove: removeSection }) => (
              <>
                {values.section.map((sectionItem, sectionIndex) => (
                  <div key={sectionIndex}>
                    <Row>
                      <Col md={4} className="mb-3">
                        <BootstrapForm.Group>
                          <BootstrapForm.Label>SectionName {sectionIndex + 1}</BootstrapForm.Label>
                          <BootstrapForm.Control
                            type="text"
                            placeholder="Enter SectionName"
                            name={`section[${sectionIndex}].sectionName`}
                            value={sectionItem.sectionName}
                            onChange={handleChange}
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        <BootstrapForm.Group>
                          <BootstrapForm.Label>SectionTime {sectionIndex + 1}</BootstrapForm.Label>
                          <BootstrapForm.Control
                            type="text"
                            placeholder="Enter SectionTime"
                            name={`section[${sectionIndex}].sectionTime`}
                            value={sectionItem.sectionTime}
                            onChange={(event) =>
                              handleTimeChange(event, setFieldValue, `section[${sectionIndex}].sectionTime`)
                            }
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col md={3} className="mb-3">
                        <BootstrapForm.Group>
                          <BootstrapForm.Label>Description {sectionIndex + 1}</BootstrapForm.Label>
                          <BootstrapForm.Control
                            type="text"
                            placeholder="Enter Description"
                            name={`section[${sectionIndex}].description`}
                            value={sectionItem.description}
                            onChange={handleChange}
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col md={1} className="mb-3 align-self-end">
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => removeSection(sectionIndex)}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>

                    <FieldArray name={`section[${sectionIndex}].chapter`}>
                      {({ push: pushChapter, remove: removeChapter }) => (
                        <>
                          {sectionItem.chapter.map((chapterItem, chapterIndex) => (
                            <div key={chapterIndex}>
                              <Row>
                                <Col md={4} className="mb-3">
                                  <BootstrapForm.Group>
                                    <BootstrapForm.Label>Title {chapterIndex + 1}</BootstrapForm.Label>
                                    <BootstrapForm.Control
                                      type="text"
                                      placeholder="Enter Title"
                                      name={`section[${sectionIndex}].chapter[${chapterIndex}].title`}
                                      value={chapterItem.title}
                                      onChange={handleChange}
                                    />
                                  </BootstrapForm.Group>
                                </Col>
                                <Col md={4} className="mb-3">
                                  <BootstrapForm.Group>
                                    <BootstrapForm.Label>Time {chapterIndex + 1}</BootstrapForm.Label>
                                    <BootstrapForm.Control
                                      type="text"
                                      placeholder="Enter Time"
                                      name={`section[${sectionIndex}].chapter[${chapterIndex}].time`}
                                      value={chapterItem.time}
                                      onChange={(event) =>
                                        handleTimeChange(event, setFieldValue, `section[${sectionIndex}].chapter[${chapterIndex}].time`)
                                      }
                                    />
                                  </BootstrapForm.Group>
                                </Col>
                                <Col md={1} className="mb-3 align-self-end">
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() => removeChapter(chapterIndex)}
                                  >
                                    Remove
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          ))}

                          <Row>
                            <Col md={12} className="mb-3">
                              <Button
                                variant="primary"
                                onClick={() =>
                                  pushChapter({
                                    title: "",
                                    time: ""
                                  })
                                }
                              >
                                Add Chapter
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    </FieldArray>
                  </div>
                ))}

                <Row>
                  <Col md={12} className="mb-3">
                    <Button
                      variant="primary"
                      onClick={() =>
                        pushSection({
                          sectionName: "",
                          sectionTime: "",
                          description: "",
                          chapter: [
                            {
                              title: "",
                              time: ""
                            }
                          ]
                        })
                      }
                    >
                      Add Section
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </FieldArray>
        </Row>  
        <div className="mt-3">
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
          >
            Create
          </Button>
        </div>
      </Form>
    )}
  </Formik>
</Modal> */}
        <Modal
          open={Editopen1}
          onClose={onEditCloseModal1}
          center
          styles={{ modal: { width: "90%" } }}
        >
          <h2>Section</h2>
          <Formik
            initialValues={myChapterData} // Ensure myChapterData contains content_id
            validationSchema={SigninSchemasection}
            onSubmit={(values) => {
              console.log("🚀 ~ Content ~ values%%%%%%%%%%%:", values);
              addChapter(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form>
                <Row>
                  {/* <Col md={12} className="mb-3">
            <BootstrapForm.Group>
              <BootstrapForm.Label>Content ID</BootstrapForm.Label>
              <BootstrapForm.Control
                type="text"
                placeholder="Enter Content ID"
                name="content_id"
                value={values.content_id}
                onChange={handleChange}
                readOnly // Make it readonly if it should not be edited
              />
            </BootstrapForm.Group>
          </Col> */}
                  <FieldArray name="section">
                    {({ push: pushSection, remove: removeSection }) => (
                      <>
                        {values.section.map((sectionItem, sectionIndex) => (
                          <div key={sectionIndex}>
                            <Row className="mb-3">
                              <Col md={4}>
                                <BootstrapForm.Group>
                                  <BootstrapForm.Label>
                                    Section Name {sectionIndex + 1}
                                  </BootstrapForm.Label>
                                  <BootstrapForm.Control
                                    type="text"
                                    placeholder="Enter Section Name"
                                    name={`section[${sectionIndex}].sectionName`}
                                    value={sectionItem.sectionName}
                                    onChange={handleChange}
                                  />
                                </BootstrapForm.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Group id="timePicker">
                                  <Form.Label>Section Time</Form.Label>
                                  <InputGroup>
                                    <InputGroup.Text>
                                      <FontAwesomeIcon icon={faClock} />
                                    </InputGroup.Text>
                                    <TimePicker
                                      disableClock={true}
                                      clearIcon={null}
                                      value={
                                        sectionItem.sectionTime || "00:00:00"
                                      }
                                      onChange={(newTime) => {
                                        setFieldValue(
                                          `section[${sectionIndex}].sectionTime`,
                                          newTime
                                        );
                                      }}
                                      format="HH:mm:ss"
                                      maxDetail="second"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <BootstrapForm.Group>
                                  <BootstrapForm.Label>
                                    Description {sectionIndex + 1}
                                  </BootstrapForm.Label>
                                  <BootstrapForm.Control
                                    type="text"
                                    placeholder="Enter Description"
                                    name={`section[${sectionIndex}].description`}
                                    value={sectionItem.description}
                                    onChange={handleChange}
                                  />
                                </BootstrapForm.Group>
                              </Col>
                              <Col md={1} className="align-self-end">
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => removeSection(sectionIndex)}
                                >
                                  Remove
                                </Button>
                              </Col>
                            </Row>
                            <FieldArray
                              name={`section[${sectionIndex}].chapter`}
                            >
                              {({
                                push: pushChapter,
                                remove: removeChapter,
                              }) => (
                                <>
                                  {sectionItem.chapter.map(
                                    (chapterItem, chapterIndex) => (
                                      <div key={chapterIndex}>
                                        <Row className="mb-3">
                                          <Col md={4}>
                                            <BootstrapForm.Group>
                                              <BootstrapForm.Label>
                                                Title {chapterIndex + 1}
                                              </BootstrapForm.Label>
                                              <BootstrapForm.Control
                                                type="text"
                                                placeholder="Enter Title"
                                                name={`section[${sectionIndex}].chapter[${chapterIndex}].title`}
                                                value={chapterItem.title}
                                                onChange={handleChange}
                                              />
                                            </BootstrapForm.Group>
                                          </Col>
                                          <Col md={6}>
                                            <Form.Group id="timePicker">
                                              <Form.Label>Time</Form.Label>
                                              <InputGroup>
                                                <InputGroup.Text>
                                                  <FontAwesomeIcon
                                                    icon={faClock}
                                                  />
                                                </InputGroup.Text>
                                                <TimePicker
                                                  disableClock={true}
                                                  clearIcon={null}
                                                  value={
                                                    chapterItem.time ||
                                                    "00:00:00"
                                                  }
                                                  onChange={(newTime) => {
                                                    setFieldValue(
                                                      `section[${sectionIndex}].chapter[${chapterIndex}].time`,
                                                      newTime
                                                    );
                                                  }}
                                                  format="HH:mm:ss"
                                                  maxDetail="second"
                                                />
                                              </InputGroup>
                                            </Form.Group>
                                          </Col>
                                          <Col
                                            md={1}
                                            className="align-self-end"
                                          >
                                            <Button
                                              variant="danger"
                                              size="sm"
                                              onClick={() =>
                                                removeChapter(chapterIndex)
                                              }
                                            >
                                              Remove
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    )
                                  )}
                                  <Row>
                                    <Col md={12} className="mb-3">
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          pushChapter({
                                            title: "",
                                            time: "00:00:00",
                                          })
                                        }
                                      >
                                        Add Chapter
                                      </Button>
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </FieldArray>
                          </div>
                        ))}
                        <Row>
                          <Col md={12} className="mb-3">
                            <Button
                              variant="primary"
                              onClick={() =>
                                pushSection({
                                  sectionName: "",
                                  sectionTime: "00:00:00",
                                  description: "",
                                  chapter: [
                                    {
                                      title: "",
                                      time: "00:00:00",
                                    },
                                  ],
                                })
                              }
                            >
                              Add Section
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </FieldArray>
                </Row>
                <div className="mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>

        {/* <Modal open={Editopen1} onClose={onEditCloseModal1} center styles={{ modal: { width: '90%'} }}>
  <h2> Section</h2>
  <Formik
    initialValues={myChapterData}
    validationSchema={SigninSchemasection}
    onSubmit={(values) => {
      addChapter({ ...values, content_id: editData.content_id }); // Pass content_id along with other values
    }}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleSubmit,
      setFieldValue,
    }) => (
      <Form>
        <Row>
          <FieldArray name="section">
            {({ push, remove }) => (
              <>
                {values?.section?.map((item, index) => (
                  <div key={index}>
                    <Row>
                      <Col md={4} className="mb-3">
                        <BootstrapForm.Group>
                          <BootstrapForm.Label>
                            SectionName {index + 1}
                          </BootstrapForm.Label>
                          <BootstrapForm.Control
                            type="text"
                            placeholder="Enter SectionName"
                            name={`section[${index}].sectionName`}
                            value={item.sectionName}
                            onChange={handleChange}
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        <BootstrapForm.Group>
                          <BootstrapForm.Label>
                            SectionTime {index + 1}
                          </BootstrapForm.Label>
                          <BootstrapForm.Control
                            type="text"
                            placeholder="Enter SectionTime"
                            name={`section[${index}].sectionTime`}
                            value={item.sectionTime}
                            onChange={handleChange}
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col md={3} className="mb-3">
                        <BootstrapForm.Group>
                          <BootstrapForm.Label>
                            Description {index + 1}
                          </BootstrapForm.Label>
                          <BootstrapForm.Control
                            type="text"
                            placeholder="Enter Description"
                            name={`section[${index}].description`}
                            value={item.description}
                            onChange={handleChange}
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col md={1} className="mb-3 align-self-end">
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => remove(index)}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))}
                <Row>
                  <Col md={12} className="mb-3">
                    <Button
                      variant="primary"
                      onClick={() =>
                        push({
                          sectionName: "",
                          sectionTime: "",
                          description: "",
                        })
                      }
                    >
                      Add Section
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </FieldArray>
          <FieldArray name="chapter">
            {({ push, remove }) => (
              <>
                {values?.chapter?.map((item, index) => (
                  <div key={index}>
                    <Row>
                      <Col md={4} className="mb-3">
                        <BootstrapForm.Group>
                          <BootstrapForm.Label>
                            Title {index + 1}
                          </BootstrapForm.Label>
                          <BootstrapForm.Control
                            type="text"
                            placeholder="Enter Title"
                            name={`chapter[${index}].title`}
                            value={item.title}
                            onChange={handleChange}
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col md={4} className="mb-3">
                        <BootstrapForm.Group>
                          <BootstrapForm.Label>
                            Time {index + 1}
                          </BootstrapForm.Label>
                          <BootstrapForm.Control
                            type="text"
                            placeholder="Enter Time"
                            name={`chapter[${index}].time`}
                            value={item.time}
                            onChange={handleChange}
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col md={1} className="mb-3 align-self-end">
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => remove(index)}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))}
                <Row>
                  <Col md={12} className="mb-3">
                    <Button
                      variant="primary"
                      onClick={() =>
                        push({
                          chapterName: "",
                          chapter: "",
                          pageNumber: "",
                        })
                      }
                    >
                      Add Chapter
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </FieldArray>
        </Row>
        <div className="mt-3">
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
          >
            Create
          </Button>
        </div>
      </Form>
    )}
  </Formik>
</Modal> */}

        <Modal open={openVideo} onClose={onCloseVideoModal} center>
          <h2>Add Video</h2>
          <Formik
            initialValues={myData}
            validationSchema={SigninSchema}
            onSubmit={(values) => {
              createContent(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Form>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter title"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.title && touched.title && errors.title}
                    </div>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Category</Form.Label>
                      <Form.Select
                        name="category_id"
                        value={values.category_id}
                        onChange={(e) => {
                          handleCategoryChange(e.target.value);
                          handleChange(e);
                        }}
                      >
                        <option>Select Category</option>
                        {categoryData.map((item, index) => (
                          <option value={item._id} key={item._id}>
                            {item.category_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Subcategory</Form.Label>
                      <Form.Select
                        name="subcategory_id"
                        value={values.subcategory_id}
                        onChange={(e) => {
                          handleSubcategoryChange(e.target.value);
                          handleChange(e);
                        }}
                      >
                        <option>Select Subcategory</option>
                        {filteredSubcategories.map((subcategory, index) => (
                          <option key={subcategory._id} value={subcategory._id}>
                            {subcategory.subcategory_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Topic</Form.Label>
                      <Form.Select
                        name="topic_id"
                        value={values.topic_id}
                        onChange={(e) => {
                          getPdfByTopic(e.target.value);
                          handleChange(e);
                        }}
                      >
                        <option>Select Topic</option>
                        {filteredTopics.map((item, index) => (
                          <option value={item._id}>
                            {item.topic_name}
                            {/* {" "}
                              {item.hasVideo ? null : "| ▶️ Video missing"}{" "}
                              {item.hasNote ? null : "| 🗒️ PDF missing"} */}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select PDF</Form.Label>
                      <Form.Select
                        name="pdf_id"
                        value={values.pdf_id}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        <option>Select PDF</option>
                        {filteredPDF.map((item, index) => (
                          <option value={item._id}>
                            {item.title}
                            {/* {" "}
                              {item.hasVideo ? null : "| ▶️ Video missing"}{" "}
                              {item.hasNote ? null : "| 🗒️ PDF missing"} */}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Select Subscription Plan</Form.Label>
                      <Select
                        name="subscription_id"
                        value={values.subscription_id.map((id) => ({
                          value: id,
                          label:
                            SubscriptionData.find((item) => item.sid === id)
                              ?.plan_name || "",
                        }))}
                        options={SubscriptionData.map((item) => ({
                          value: item.sid,
                          label: item.plan_name,
                        }))}
                        isMulti
                        onChange={(selectedOptions) => {
                          setFieldValue(
                            "subscription_id",
                            selectedOptions.map((option) => option.value)
                          );
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {/* {selectedOption === "video" && ( */}
                  <Row>
                    <Col md={12} className="mb-3">
                      <Form.Group id="emal">
                        <Form.Label>Vimeo video URL</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="https://vimeo.com/317784003"
                          name="video_url"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* )} */}
                </Row>

                <Row md={12}>
                  <Col>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={values.isfeatured}
                        id="flexCheckDefault"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("isfeatured", e.target.checked);
                        }}
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Add this video to Feature list
                      </label>
                    </div>
                  </Col>
                  <Col>
                    <div class="form-check mb-3">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={values.isPublic}
                        id="flexCheckPublic"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("isPublic", e.target.checked);
                        }}
                      />
                      <label class="form-check-label" for="flexCheckPublic">
                        isPublic
                      </label>
                    </div>
                  </Col>
                </Row>

                {values.isfeatured === true && (
                  <Row>
                    <Col md={12} className="mb-3">
                      <Form.Group id="emal">
                        <Form.Label>Banner Image</Form.Label>
                        <Form.Control
                          type="file"
                          name="Banner_img"
                          onChange={(event) => {
                            const file = event.currentTarget.files[0];
                            if (file) {
                              const reader = new FileReader();
                              reader.onload = (e) => {
                                const base64Data =
                                  e.target.result.split(",")[1];
                                setFieldValue("Banner_img", base64Data);
                              };
                              reader.readAsDataURL(file);
                            }
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                {/* <FieldArray name="chapter">
  {({ push, remove }) => (
    <>
      {values?.chapter?.map((item, index) => (
        <div key={index}>
          <Row>
            <Col md={4} className="mb-3">
              <BootstrapForm.Group>
                <BootstrapForm.Label>Title {index + 1}</BootstrapForm.Label>
                <BootstrapForm.Control
                  type="text"
                  placeholder="Enter title"
                  name={`chapter[${index}].title`}
                  // value={values.chapter[index].title}
                                      value={item.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </BootstrapForm.Group>
            </Col>
            <Col md={4} className="mb-3">
              <BootstrapForm.Group>
                <BootstrapForm.Label>Time {index + 1}</BootstrapForm.Label>
                <BootstrapForm.Control
                  type="text"
                  placeholder="Enter time"
                  name={`chapter[${index}].time`}
                  // value={values.chapter[index].time}
                  value={item.time}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </BootstrapForm.Group>
            </Col>
            <Col md={1} className="mb-3 align-self-end">
              <Button variant="danger" size="sm" onClick={() => remove(index)}>
                Remove
              </Button>
            </Col>
          </Row>
        </div>
      ))}

      <Row>
        <Col md={12} className="mb-3">
          <Button
            variant="primary"
            onClick={() =>
              push({
                title: "",
                time: "",
              })
            }
          >
            Add Chapter
          </Button>
        </Col>
      </Row>
    </>
  )}
</FieldArray> */}

                {/* <FieldArray name="chapter">
  {({ push, remove }) => (
    <>
      {values?.chapter?.map((item, index) => (
        <div key={index}>
          <Row>
            <Col md={4} className="mb-3">
              <BootstrapForm.Group>
                <BootstrapForm.Label>Title {index + 1}</BootstrapForm.Label>
                <BootstrapForm.Control
                  type="text"
                  placeholder="Enter title"
                  name={`chapter[${index}].title`}
                  value={item.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </BootstrapForm.Group>
            </Col>
            <Col md={4} className="mb-3">
              <BootstrapForm.Group>
                <BootstrapForm.Label>Time {index + 1}</BootstrapForm.Label>
                <BootstrapForm.Control
                  type="text"
                  placeholder="Enter time"
                  name={`chapter[${index}].time`}
                  value={item.time}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </BootstrapForm.Group>
            </Col>
            <Col md={1} className="mb-3 align-self-end">
              <Button variant="danger" size="sm" onClick={() => remove(index)}>
                Remove
              </Button>
            </Col>
          </Row>
        </div>
      ))}

      <Row>
        <Col md={12} className="mb-3">
          <Button
            variant="primary"
            onClick={() =>
              push({
                title: "",
                time: "",
              })
            }
          >
            Add Chapter
          </Button>
        </Col>
      </Row>
    </>
  )}
</FieldArray> */}

                <div className="mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
        {/* <MDBDataTable striped bordered small data={datatable} /> */}
        <TabContext value={value}>
          <TabList style={tabListStyles} onChange={handleChange}>
            <Tab
              style={
                value === "1" ? { ...tabStyles, ...activeTabStyles } : tabStyles
              }
              label="Video"
              value="1"
            />
            <Tab
              style={
                value === "2" ? { ...tabStyles, ...activeTabStyles } : tabStyles
              }
              label="PDF"
              value="2"
            />
          </TabList>
          <TabPanel value="1">
            <div>
              {isLoadingData ? (
                <div className="loader-container">
                  <Image
                    className="loader-element animate__animated animate__jackInTheBox"
                    src={ReactLogo}
                    height={40}
                  />
                </div>
              ) : (
                <MDBDataTable striped bordered small data={datatable} />
              )}
            </div>
          </TabPanel>
          <TabPanel value="2">
            <div>
              {isLoadingData ? (
                <div className="loader-container">
                  <Image
                    className="loader-element animate__animated animate__jackInTheBox"
                    src={ReactLogo}
                    height={40}
                  />
                </div>
              ) : (
                <MDBDataTable striped bordered small data={pdfdatatable} />
              )}
            </div>
          </TabPanel>
        </TabContext>
      </Card.Body>
    </Card>
  );
};
